import React, { Component, useEffect, useState } from "react";
import Footter from "../../../../component/Footter";
import Header from "../../../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import moment from "moment";

function Manage_Documents(params) {
  const [team, setteam] = useState([]);

  const [allEmployee, setAllEmployee] = useState({ data: [] });
  
  const [filterteam, setFilterTeam] = useState({
    file_name: "",
    rc_id: "",
  });
  const [filterCate, setFilterCate] = useState({
    file_name: "",
    rc_id: "",
  });
  const [filCategory, setFilterCategory] = useState([]);
  const GetAllCatgory = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterCategoryAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        // setFilterCategory(response.data.data)
        setteam(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const filterCategory = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterCategoryAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterCate, // Sending filterteam as the request body
    })
      .then(async function (response) {
        setFilterCategory(response.data.data)
        // setteam(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  function ChangeStatus(id, status) {
    let data = {
      rf_id: id,
      rf_is_active: status,
    }
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/companyRegulations/ChangeStatusCategoryTypeById",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        // console.log(response);
        GetAllCatgory();
        // Swal.fire({
        //   icon: "success",
        //   text: "เสร็จสิ้น",
        // }).then(() => {
        //   window.location.href =
        //     "/Human_Resource/incentive_hr_admin/team";
        // });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        document.getElementById(id).checked = status ? false : true;
      });
    // }
    // });
  }

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter(el => {
            return el.emp_status_type_id !==  "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && el.is_active !== false;
          });
          setAllEmployee({ ...allEmployee, data: data });
          allEmployee.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllCatgory();
    GetEmployee();
  }, []);
  console.log('allEmployee.data.length :>> ', allEmployee.data.length);
  function generateDataTable(team, index) {
    const element = {};

    element["no"] = index + 1;
    element["image"] = (
      <img
        id="img"
        alt="..."
        className="img-fluid rounded shadow border-0"
        src={
          team.rc_path_image !== "" &&
            team.rc_path_image !== null &&
            team.rc_path_image !== undefined
            ? Configs.API_URL_IMG + team.rc_path_image
            : userdefault_img.imgs
        }
        style={{
          minWidth: "60px",
          minHeight: "60px",
          width: "60px",
          height: "60px",
          position: "relative",
          display: "block",
          objectFit: "cover",
          "margin-left": "auto",
          "margin-right": "auto",
        }}
      />
    );
    element["file_name"] = team.rf_name;
    element["file_name"] = (
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        {team.rf_name}
      </div>
    );
    element["category"] = team.rc_name || "ไม่พบ";

    element["start_date"] = team.rf_start_date
      ? moment(team.rf_start_date).format('DD/MM/YYYY') 
      : "ไม่พบ";

    element["end_date"] = team.rf_end_date
      ? moment(team.rf_end_date).format('DD/MM/YYYY') 
      : "ไม่พบ";

    element["view"] = `${(team.number_readed || 0)} / ${allEmployee.data.length || 0}`;

    element["status"] = (
      <div class="custom-control custom-switch custom-switch-on-success" style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          class="custom-control-input"
          id={"customSwitch" + (index + 1)}
          key={team.rf_id}
          value={team.rf_is_active}
          onChange={(e) => {
            ChangeStatus(team.rf_id, e.target.checked)
            // getdataToggle(this)
          }}
          defaultChecked={team.rf_is_active}
        ></input>
        <label
          class="custom-control-label"
          for={"customSwitch" + (index + 1)}
        ></label>
      </div>
    );
    element["download"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          <button
            // href={
            //   team.rf_path_file && team.rf_name_file
            //     ? encodeURI(Configs.API_URL_IMG + `${team.rf_path_file}`)
            //     : ""
            // }
            // download={team.rf_name_file ? 'test.pdf'/* encodeURIComponent(team.rf_name_file) */ : ""}
            onClick={() => {
              DownloadFile(encodeURI(Configs.API_URL_IMG + `${team.rf_path_file}`), team.rf_name_file);
            }}
            id="btn_read"
            className="btn btn-block btn-primary"
            style={{ minWidth: "110px", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            Download
          </button>
        </div>
      </div>
    );
    element["mgt"] = (
      <div className="row align-items-center" style={{ flexWrap: "nowrap", display: 'flex', justifyContent: 'center' }}>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            href={"/Human_Resource/company_regulations/Manage_documents/view/" + team.rp_id}
            id="btn_read"
            key={team.rp_id + "view"}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            href={"/Human_Resource/company_regulations/Manage_documents/edit/" + team.rp_id}
            id="btn_edit"
            key={team.rp_id + "edit"}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            key={team.rp_id + "del"}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={deldata.bind(this, team.rp_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataTeam = [];
  for (let index = 0; index < team.length; index++) {
    const data = team[index];
    dataTeam.push(generateDataTable(data, index));
  }

  function deldata(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/delCategoryByID?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                GetAllCatgory();
              }
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
          });
      }
    });
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อไฟล์",
        field: "file_name",
        // sort: "asc",
        width: 100,
      },
      {
        label: "ประเภทบันทึกข้อความ",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Start Date",
        field: "start_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "End Date",
        field: "end_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Views",
        field: "view",
        // sort: "asc",
        width: 50,
      },
      {
        label: "status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Download",
        field: "download",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: dataTeam,
  };

  function clearFilter() {
    setFilterTeam({
      file_name: "",
      rc_id: "",
    })
    filterteam.file_name = "";
    filterteam.rc_id = "";
    // Swal.fire({
    //   title: "กำลังดึงข้อมูล",
    //   text: "Loading",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    GetAllCatgory();
    filterCategory();

  }

  async function DownloadFile(url, fileName) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('File could not be downloaded');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName || 'downloaded_file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the file:', error.message);
    }
  }

  function addpage(params) {
    window.location.href = "/Human_Resource/company_regulations/Manage_documents/add";
  }

  const uniqueCategories = Array.from(
    new Set(filCategory.map(el => el.rc_id))
  ).map(id => {
    return filCategory.find(el => el.rc_id === id);
  });

  useEffect(() => {
    GetAllCatgory();
    filterCategory();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>จัดการเอกสาร{"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">จัดการเอกสาร</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterteam.file_name}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        file_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">File Name</label>{" "}
                </div>
              </div>
              <div
                className="col-md-2 col-sm-12 col-lg-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    value={filterteam.rc_id}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        rc_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected >
                      เลือก
                    </option>
                    {uniqueCategories.map((el) => {
                      return <option value={el.rc_id}>{el.rc_name}</option>;
                    })}
                  </select>
                  <label htmlFor="" style={{ marginTop: "-4px" }}>Category</label>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetAllCatgory();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">จัดการเอกสาร</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Manage_Documents;
