import React, { useState, useEffect } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function Category_Card({ data }) {
  return (
    <>
      <div
        style={{
          width: "60px",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          color: "black",
        }}
      >
        <img
          style={{
            minWidth: "60px",
            maxWidth: "60px",
            minHeight: "60px",
            maxHeight: "60px",
            borderRadius: "10px",
            border: "solid 1px #003583",
            cursor: "pointer",
            backgroundColor: "white",
          }}
          src={data.categoryImagePath || userdefault_img.imgs}
          alt={data.categoryImageName || "..."}
        />

        <label
          style={{
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "normal",
            color: "#8a8a8a",
            margin: "0px",
            wordBreak: "auto-phrase",
            cursor: "pointer",
          }}
        >
          {data.categoryName}
        </label>
      </div>
    </>
  );
}

export default Category_Card;
