import React, { useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Report_Accounting() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState({
    year: currentYear,
  });

  const [selectedMonth, setSelectedMonth] = useState(
    currentMonth - 1 >= 0 ? currentMonth - 1 : 12
  );

  const [reportData, setReportData] = useState({
    start_date: "",
    end_date: "",
    year: "",
    month: "",
  });

  const monthOptions = [
    { value: "01", label: "มกราคม" },
    { value: "02", label: "กุมภาพันธ์" },
    { value: "03", label: "มีนาคม" },
    { value: "04", label: "เมษายน" },
    { value: "05", label: "พฤษภาคม" },
    { value: "06", label: "มิถุนายน" },
    { value: "07", label: "กรกฎาคม" },
    { value: "08", label: "สิงหาคม" },
    { value: "09", label: "กันยายน" },
    { value: "10", label: "ตุลาคม" },
    { value: "11", label: "พฤศจิกายน" },
    { value: "12", label: "ธันวาคม" },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const yearOptions = Array.from({ length: 6 }, (_, index) => ({
    value: currentYear + index,
    label: currentYear + index,
  }));

  const [filterReportAccounting, setFilterReportAccounting] = useState({
    data: [],
  });

  const toggleModal = () => {
    // const currentMonth = new Date().getMonth() + 1;

    setModalOpen(!modalOpen);
    setReportData({
      ...reportData,
      start_date: "",
      end_date: "",
      year: yearOptions[0].value,
      month: monthOptions[selectedMonth - 1].value,
    });
  };

  async function filterReportAccount() {
    const tempData = {
      year: selectedYear.year,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/reportAccounting/getReportAccounting",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setFilterReportAccounting({
          ...filterReportAccount,
          data: response.data.data,
        });
        filterReportAccount.data = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  function clearFilter() {
    setSelectedYear({
      year: currentYear,
    });
    selectedYear.year = currentYear;

    filterReportAccount();
  }

  function GetDivAlign(value, align) {
    return <div style={{ textAlign: align || "left" }}>{value}</div>;
  }

  function generateDataTable(report, index) {
    const element = {};

    element["no"] = GetDivAlign(index + 1, "center");

    element["bill"] = GetDivAlign(report.lra_requisition_no, "left");
    element["bill_search"] = report.lra_requisition_no;

    element["file"] = GetDivAlign(report.lra_file_name, "left");
    element["file_search"] = report.lra_file_name;

    element["date"] = (
      <div>
        {moment(report.lra_start_date).format("DD/MM/YYYY")} -{" "}
        {moment(report.lra_end_date).format("DD/MM/YYYY")}
      </div>
    );
    element["date_search"] = `${moment(report.lra_start_date).format("DD/MM/YYYY")} -${" "}
        ${moment(report.lra_end_date).format("DD/MM/YYYY")}`

    element["create_by"] = GetDivAlign(report.emp_name_th, "left");
    element["create_by_search"] = report.emp_name_th;

    element["mgt"] = (
      <div
        className=""
        style={{
          // flexWrap: "nowrap",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{ textAlign: "center", padding: 0 }}
        >
          <a
            id="btn_read"
            key={report.lra_id + "_view"}
            className="btn btn-xs "
            onClick={() => {
              downloadDataReport(report.lra_file_path, report.lra_file_name);
            }}
          >
            <i class="fas fa-download"></i>
          </a>
        </div>

        <div style={{ textAlign: "center" }}>
          <a
            key={report.lra_id + "_del"}
            id="btn_delete"
            className="btn btn-xs"
            onClick={() => {
              deleteDataReport(report.lra_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>

        {/* <div style={{ textAlign: "center" }}>
          <a
            key={report.lra_id + "_del"}
            id="btn_delete"
            className="btn btn-xs"
            onClick={() => {
              previewPDFReport(
                report.lra_id,
                report.lra_file_path,
                report.lra_file_name,
                report.lra_requisition_no,
                report.lra_month,
                report.lra_year
              );
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div> */}
      </div>
    );

    return element;
  }

  const dataReportAccounting = [];
  for (let index = 0; index < filterReportAccounting.data.length; index++) {
    const report = filterReportAccounting.data[index];
    dataReportAccounting.push(generateDataTable(report, index));
  }

  const data = {
    columns: [
      {
        label: GetDivAlign("No.", "left"),
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ใบเสร็จ", "left"),
        field: "bill",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ไฟล์", "left"),
        field: "file",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("วันที่เริ่มต้น-วันที่สิ้นสุด", "left"),
        field: "date",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("สร้างโดย", "left"),
        field: "create_by",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("MGT", "left"),
        field: "mgt",
        // sort: "asc",
        width: 100,
      },
    ],
    rows: dataReportAccounting,
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  async function saveDataReport() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (reportData.start_date == null || reportData.start_date === "") {
          const temp_err = {
            message: "กรุณากรอกวันที่เริ่มต้น",
          };
          error_list.push(temp_err);
        }
        if (reportData.start_date == null || reportData.start_date === "") {
          const temp_err = {
            message: "กรุณากรอกวันที่สิ้นสุด",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          let err_message = "";

          for (let err = 0; err < error_list.length; err++) {
            err_message += "<br/>" + error_list[err].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          const requisition_no =
            "TW-" +
            moment(reportData.year.toString()).format("YY") +
            "-" +
            reportData.month;
          const thaiYear = reportData.year + 543;
          const file_name =
            "TTT.ใบเบิก.เดือน." +
            reportData.month +
            moment(thaiYear.toString()).format("YY");
          const data = {
            lra_requisition_no: requisition_no,
            lra_file_name: file_name,
            lra_start_date: reportData.start_date,
            lra_end_date: reportData.end_date,
            lra_year: reportData.year,
            lra_month: reportData.month,
            user_id: getUser().fup,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/reportAccounting/createReportAccounting",
            headers: {
              Authorization: getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: data,
          })
            .then(function (response) {
              if (response.data.data) {
                Swal.fire({
                  icon: "success",
                  title: "ทำรายการสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  setModalOpen(!modalOpen);
                  filterReportAccount();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        }
      }
    });
  }

  async function deleteDataReport(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "บันทึก",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/reportAccounting/delLogReportAccounting",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            id: id,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              filterReportAccount();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  async function downloadDataReport(file_path, file_name) {
    const tempData = {
      path_origin: file_path ,
      name_file: file_name + ".xlsx",
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/reportAccounting/GetReportAcconting",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
      responseType: "blob",
    })
      .then(function (response) {
        const data = response.data;
        const pdfDataUrl = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = pdfDataUrl;
        link.download = `${file_name}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Swal.fire({
          icon: "success",
          text: "เสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          filterReportAccount();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // async function previewPDFReport(
  //   id,
  //   file_path,
  //   file_name,
  //   req_no,
  //   month,
  //   year
  // ) {
  //   const tempData = {
  //     log_id: id,
  //     req_no: req_no,
  //     year: year,
  //     month: month,
  //     path_origin: file_path + ".pdf",
  //     file_name: file_name,
  //   };
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_hrMagenatement + "/api/reportAccounting/previewPDF",
  //     headers: {
  //       Authorization: getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: tempData,
  //     // responseType: "blob",
  //   })
  //     .then(function (response) {
  //       console.log("response.data", response.data.data);
  //       let base64String = response.data.data;

  //       console.log('base64String', base64String)

  //       // If the Base64 string contains a header, remove it
  //       const base64Header = "data:application/pdf;base64,";
  //       if (base64String.indexOf(base64Header) === 0) {
  //         base64String = base64String.slice(base64Header.length);
  //       }

  //       // Decode the base64 string
  //       const byteCharacters = atob(base64String);

  //       // Convert the decoded string to a byte array
  //       const byteNumbers = new Array(byteCharacters.length)
  //         .fill()
  //         .map((_, i) => byteCharacters.charCodeAt(i));
  //       const byteArray = new Uint8Array(byteNumbers);

  //       // Create a Blob from the byte array
  //       const blob = new Blob([byteArray], { type: "application/pdf" });

  //       // Create a URL for the Blob and open it in a new tab
  //       const pdfUrl = URL.createObjectURL(blob);
  //       window.open(pdfUrl, "_blank");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  useEffect(() => {
    filterReportAccount();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Report Accounting {"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Report Accounting</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2" style={{ zIndex: 99 }}>
                <div class="form-group">
                  <Select
                    // isMulti
                    // isDisabled={isDisabled}
                    options={yearOptions}
                    value={yearOptions.find(
                      (option) => option.value === selectedYear.year
                    )}
                    onChange={(selectedOption) =>
                      setSelectedYear({
                        year: selectedOption.value,
                      })
                    }
                  />
                  <label>Year</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      filterReportAccount();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={toggleModal}
                  >
                    Report
                  </button>
                </div>
              </div>
            </div>

            <Modal isOpen={modalOpen} toggle={toggleModal} centered>
              <ModalHeader toggle={toggleModal}>
                Report:{" "}
                <span style={{ fontWeight: "bold" }}>
                  สรุปค่าใช้จ่ายทำเบิก/ค่าใช้จ่ายเงินสดย่อย
                </span>
              </ModalHeader>
              <ModalBody>
                <div className="row mb-2">
                  <div
                    className="col-12"
                    /* style={{ "z-index": "99" }} */
                  >
                    <div
                      class={`form-group hr-log-add-datepicker-custom`}
                      /* style={{ zIndex: "98" }} */
                    >
                      <DatePicker
                        // disabled={true}
                        selected={reportData.start_date}
                        required
                        locale="th"
                        id="text_start_work"
                        dateFormat={"dd-MM-yyyy"}
                        onChange={async (date) => {
                          setReportData({
                            ...reportData,
                            start_date: date,
                          });
                        }}
                        // maxDate={new Date(reportData.start_date)}
                        customInput={<CustomInput />}
                      ></DatePicker>
                      <label>Start Date</label>
                    </div>

                    <div
                      class={`form-group hr-log-add-datepicker-custom`}
                      /* style={{ zIndex: "98" }} */
                    >
                      <DatePicker
                        // disabled={true}
                        selected={reportData.end_date}
                        required
                        locale="th"
                        id="text_start_work"
                        dateFormat={"dd-MM-yyyy"}
                        onChange={async (date) => {
                          setReportData({
                            ...reportData,
                            end_date: date,
                          });
                        }}
                        minDate={new Date(reportData.start_date)}
                        customInput={<CustomInput />}
                      ></DatePicker>
                      <label>End Date</label>
                    </div>

                    <div class="form-group">
                      <Select
                        // isMulti
                        // isDisabled={isDisabled}
                        options={yearOptions}
                        defaultValue={yearOptions[0]}
                        onChange={(selectedOption) =>
                          setReportData({
                            ...reportData,
                            year: selectedOption.value,
                          })
                        }
                      />
                      <label>Year</label>
                    </div>

                    <div class="form-group">
                      <Select
                        // isMulti
                        // isDisabled={isDisabled}
                        options={monthOptions}
                        defaultValue={monthOptions[selectedMonth - 1]}
                        onChange={(selectedOption) =>
                          setReportData({
                            ...reportData,
                            month: selectedOption.value,
                          })
                        }
                      />
                      <label>Month</label>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <div className="col-6 col-md-4 col-xl-3">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={saveDataReport}
                  >
                    Load Data
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div
              className="card-body table-responsive"
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                // disableRetreatAfterSorting={disableRetreatAfterSorting}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Report_Accounting;
