import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Configs from "../../../../config";
import { useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";

import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import { LightningBolt, Arrow } from "../Incentive_User_icon_img/All_Icon";

import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import CategoryCard from "../Incentive_User_component/Category_Card";
import ProductServiceCard from "../Incentive_User_component/Product_Service_Card";
import ProductServiceCardImageSlider from "../Incentive_User_component/Product_Service_Card_Image_Slider";
import ProductServiceCardImageSlider2 from "../Incentive_User_component/Product_Service_Card_Image_Slider2";
import ProductServiceCardMultiSlider from "../Incentive_User_component/Product_Service_Card_Multi_Slider";

import CustomerService from "../Incentive_User_component/CustomerService";

function All_Product_Service() {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const toggle = () => setModal(!modal);

  const [newsData, setNewsData] = useState([]);

  const [productInCartAllTimeData, setProductInCartAllTimeData] = useState([]);

  const [categoryData, setCategoryData] = useState([]);

  const [productFlashSaleData, setProductFlashSaleData] = useState([]);
  const [productNewData, setProductNewData] = useState([]);
  const [productHitData, setProductHitData] = useState([]);
  const [productHitData2, setProductHitData2] = useState([]);

  async function GetNews() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/productService/GetNews",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            newsId: item.news_id,
            newsName: item.news_name,
            newsDetail: item.news_detail,
            newsImageName: item.news_img_name,
            newsImagePath:
              item.news_img_path !== "" &&
              item.news_img_path !== null &&
              item.news_img_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.news_img_path
                : userdefault_img.imgs,
          };

          allData.push(data);
        }

        setNewsData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductInCartAllTime() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_incentive +
        "/api/productService/GetProductInCartAllTime",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.product_point,
            productSold: item.product_count_sales,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          if (item.fs_id) {
            data["productValue"] = item.fs_product_point;
            data["discount"] = item.fs_discount;
            data["discountValue"] = item.fs_point;
          }

          allData.push(data);
        }

        setProductInCartAllTimeData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductGroup() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/productService/GetProductCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            categoryId: item.category_id,
            categoryName: item.category_name,
            categoryImageName: item.category_image,
            categoryImagePath:
              item.category_image_path !== "" &&
              item.category_image_path !== null &&
              item.category_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.category_image_path
                : userdefault_img.imgs,
          };

          allData.push(data);
        }

        setCategoryData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductFlashSale() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_incentive + "/api/productService/GetProductFlashSale",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.fs_product_point,
            productSold: item.product_count_sales,
            discount: item.fs_discount,
            discountValue: item.fs_point,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          allData.push(data);
        }

        setProductFlashSaleData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductNew() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/productService/GetProductNew",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.product_point,
            productSold: item.product_count_sales,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          if (item.fs_id) {
            data["productValue"] = item.fs_product_point;
            data["discount"] = item.fs_discount;
            data["discountValue"] = item.fs_point;
          }

          allData.push(data);
        }

        setProductNewData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductHit() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/productService/GetProductHit",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.product_point,
            productSold: item.product_count_sales,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          if (item.fs_id) {
            data["productValue"] = item.fs_product_point;
            data["discount"] = item.fs_discount;
            data["discountValue"] = item.fs_point;
          }

          allData.push(data);
        }
        allData = allData.sort((a, b) => a.productValue - b.productValue);
        setProductHitData(allData);

        setProductHitData2(allData.slice(0, 5));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(async () => {
    await GetNews();
    await GetProductInCartAllTime();
    await GetProductGroup();
    await GetProductFlashSale();
    await GetProductNew();
    await GetProductHit();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>สินค้าและบริการ</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Incentive Point</li>
                  <li className="breadcrumb-item active">สินค้าและบริการ</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <PointCartLine />
          </div>

          <div
            className="row"
            style={{
              margin: "13px 0px",
              padding: "0px",
              justifyContent: "center",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            > */}
            <div
              style={{
                padding: "0px",
                margin: "0px 5px",
                maxWidth: "982px",
                maxHeight: "292px",
                width: "-webkit-fill-available",
                height: "-webkit-fill-available",
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
              }}
            >
              <ProductServiceCardImageSlider
                data={newsData}
                width="980"
                height="290"
                id="News"
                toggle={toggle}
                setModalData={setModalData}
              />
            </div>
            <div
              className="flexDirectionChange"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <div
                style={{
                  padding: "0px",
                  margin: "0px 5px",
                  maxWidth: "140px",
                  maxHeight: "140px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px",
                }}
              >
                <ProductServiceCardImageSlider2
                  data={productHitData2}
                  width="140"
                  height="140"
                  id="ProductHit2"
                  toggle={toggle}
                  textType="สินค้าขายดี"
                  // textType={1}
                />
              </div>
              <div
                style={{
                  padding: "0px",
                  margin: "0px 5px",
                  maxWidth: "140px",
                  maxHeight: "140px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px",
                }}
              >
                <ProductServiceCardImageSlider2
                  data={productInCartAllTimeData}
                  width="140"
                  height="140"
                  id="ProductInCartAllTimeData"
                  toggle={toggle}
                  textType="สินค้าที่อยู่ในรถเข็นมากที่สุด"
                  // textType={2}
                />
              </div>
            </div>
            {/* </div> */}
          </div>

          <div
            className="row"
            style={{ padding: "10px 0px", margin: "0px 10px" }}
          >
            <ProductServiceCardMultiSlider
              id="CategoryCard"
              data={categoryData.map((item) => {
                return (
                  <>
                    <div style={{ margin: "0px 10px" }}>
                      <a
                        href={`/Human_Resource/incentive_user/product_service/category/${item.categoryId}`}
                      >
                        <CategoryCard data={item} />
                      </a>
                    </div>
                  </>
                );
              })}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px 0px 20px 0px",
            }}
          >
            <div style={{ width: "98%", borderBottom: "solid 1px #E0E0E0" }} />
          </div>

          <div
            style={{ display: "flex", padding: "10px", alignItems: "center" }}
          >
            <div
              style={{
                maxWidth: "122px",
                maxHeight: "34px",
                width: "100%",
                height: "100%",
                borderRadius: "50px",
                backgroundColor: "#0697FF",
                textAlign: "center",
              }}
            >
              <label
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "white",
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                F
                <LightningBolt
                  width="10px"
                  height="16px"
                  fill="yellow"
                  style={{ marginBottom: "6px" }}
                />
                ash Sale
              </label>
            </div>

            <div style={{ marginLeft: "15px" }}>
              <Arrow width="36px" height="8px" fill="#0697FF" />
            </div>
          </div>

          <div
            className="aps_slide"
            style={{
              // margin: "10px",
              padding: "10px 0px",
              backgroundColor: "#E4F4FF",
              borderRadius: "10px",
            }}
          >
            <div className="row" style={{ margin: "10px" }}>
              <ProductServiceCardMultiSlider
                id="ProductServiceCard_FlashSale"
                data={productFlashSaleData.map((item) => {
                  return (
                    <>
                      <div
                        className="aps_pro_card"
                        // style={{ margin: "0px 10px" }}
                      >
                        <a
                          href={`/Human_Resource/incentive_user/product_service/product_view_details/${item.productId}`}
                        >
                          <ProductServiceCard data={item} />
                        </a>
                      </div>
                    </>
                  );
                })}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 30px 10px 0px",
              }}
            >
              <a
                href={`/Human_Resource/incentive_user/product_service/category1`}
              >
                <label
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#003583",
                    lineHeight: "normal",
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  ดูเพิ่มเติม
                </label>
              </a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px 0px 20px 0px",
            }}
          >
            <div style={{ width: "98%", borderBottom: "solid 1px #E0E0E0" }} />
          </div>

          <div style={{ display: "flex", padding: "10px" }}>
            <div
              style={{
                maxWidth: "122px",
                maxHeight: "34px",
                width: "100%",
                height: "100%",
                borderRadius: "50px",
                backgroundColor: "#0697FF",
                textAlign: "center",
              }}
            >
              <label
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "white",
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                สินค้าใหม่
              </label>
            </div>

            <div style={{ marginLeft: "15px" }}>
              <Arrow width="36px" height="8px" fill="#0697FF" />
            </div>
          </div>

          <div
            className="aps_slide"
            style={{
              // margin: "10px",
              padding: "10px 0px",
              backgroundColor: "#E4F4FF",
              borderRadius: "10px",
            }}
          >
            <div className="row" style={{ margin: "10px" }}>
              <ProductServiceCardMultiSlider
                id="ProductServiceCard_New"
                data={productNewData.map((item) => {
                  return (
                    <>
                      <div
                        className="aps_pro_card"
                        // style={{ margin: "0px 10px" }}
                      >
                        <a
                          href={`/Human_Resource/incentive_user/product_service/product_view_details/${item.productId}`}
                        >
                          <ProductServiceCard data={item} />
                        </a>
                      </div>
                    </>
                  );
                })}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px 30px 20px 0px",
              }}
            >
              <a
                href={`/Human_Resource/incentive_user/product_service/category2`}
              >
                <label
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#003583",
                    lineHeight: "normal",
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  ดูเพิ่มเติม
                </label>
              </a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px 0px 20px 0px",
            }}
          >
            <div style={{ width: "98%", borderBottom: "solid 1px #E0E0E0" }} />
          </div>

          <div style={{ display: "flex", padding: "10px" }}>
            <div
              style={{
                maxWidth: "122px",
                maxHeight: "34px",
                width: "100%",
                height: "100%",
                borderRadius: "50px",
                backgroundColor: "#0697FF",
                textAlign: "center",
              }}
            >
              <label
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "white",
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                สินค้ายอดฮิต
              </label>
            </div>

            <div style={{ marginLeft: "15px" }}>
              <Arrow width="36px" height="8px" fill="#0697FF" />
            </div>
          </div>

          <div
            className="aps_slide"
            style={{
              // margin: "10px",
              padding: "10px 0px",
              backgroundColor: "#E4F4FF",
              borderRadius: "10px",
            }}
          >
            <div className="row" style={{ margin: "10px" }}>
              <ProductServiceCardMultiSlider
                id="ProductServiceCard_Hit"
                data={productHitData.map((item) => {
                  return (
                    <>
                      <div
                        className="aps_pro_card"
                        // style={{ margin: "0px 10px" }}
                      >
                        <a
                          href={`/Human_Resource/incentive_user/product_service/product_view_details/${item.productId}`}
                        >
                          <ProductServiceCard data={item} />
                        </a>
                      </div>
                    </>
                  );
                })}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px 30px 20px 0px",
              }}
            >
              <a
                href={`/Human_Resource/incentive_user/product_service/category3`}
              >
                <label
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#003583",
                    lineHeight: "normal",
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  ดูเพิ่มเติม
                </label>
              </a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px 0px 20px 0px",
            }}
          />
          {/* <div style={{ width: "98%", borderBottom: "solid 1px #E0E0E0" }} />
          </div> */}

          <CustomerService />
        </section>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader toggle={toggle}>
          <label
            style={{
              fontSize: "40px",
              fontWeight: "700",
              lineHeight: "normal",
              color: "black",
              margin: "0px",
            }}
          >
            {modalData.newsName}
          </label>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt={modalData.newsImageName || "..."}
              src={modalData.newsImagePath || userdefault_img.imgs}
              style={{
                maxWidth: `798px`,
                maxHeight: `236px`,
                width: "100%",
                height: "100%",
                aspectRatio: `798 / 236`,
                borderRadius: "10px",
                margin: "20px 0px 30px 0px",
                backgroundColor: "white",
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
              }}
            />
          </div>
          <div
            style={{ padding: "20px" }}
            dangerouslySetInnerHTML={{ __html: modalData.newsDetail }}
          />
          {/* <label
              style={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "normal",
                color: "black",
                margin: "20px",
              }}
            >
              {modalData.newsDetail}
            </label>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            ปิด
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default All_Product_Service;
