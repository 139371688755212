import React, { useEffect, useRef, useState } from "react";
import Horizontal_Bar_Chart from "../Accounting_Dashboard/Chart/Horizontal_Bar_Chart";
import Bar_Chart from "../Accounting_Dashboard/Chart/Bar_Chart";
import Line_Chart from "../Accounting_Dashboard/Chart/Line_chart";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
const MONTH_DATA = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
function ComponentDashboard2(props) {
  const [costMonthVsMonthAgo, setCostMonthVsMonthAgo] = useState({ data: [] });
  const [mostCostTypeAcc, setMostCostTypeAcc] = useState({ data: [] });
  const [costAllInfo, setCostAllInfo] = useState({ data: [] });
  const [costLineChart, setCostLineChart] = useState({ data: [] });
  const data_chart_cost_type = {
    labels: mostCostTypeAcc.data.map((el) => el.acc_cc_name) || [
      "ค่าสวัสดิการพนักงาน",
      "ค่ารับรองลูกค้า",
      "ค่าวัสดุสิ้นเปลือง",
      "ค่าอุปกรณ์สำนักงาน",
      "ค่าเดินทาง",
      "ค่าเดินทาง2",
    ],
    datasets: [
      {
        data: mostCostTypeAcc.data.map((el) => el.cost_sum) || [
          50000, 30000, 20000, 15000, 11000, 17000,
        ],
        backgroundColor: ["#007BFF"],
        borderColor: ["#007BFF"],
        borderWidth: 0,
        label: "Count",
        borderRadius: 10,
        barThickness: 35,
      },
    ],
  };

  const predefinedColors = [
    "#2DA476",
    "#F1C212",
    "#8AC1EE",
    "#69426D",
    "#D7514D",
  ];

  const getRandomColor = (index) => {
    if (index < predefinedColors.length) {
      return predefinedColors[index];
    }

    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const data_chart_cost_all_info = {
    labels: MONTH_DATA,
    datasets: [],
  };

  const groupedData = costLineChart.data.reduce((acc, el, index) => {
    const month = el.month.slice(5, 7);
    // const colorCode = getRandomColor(index);
    if (!acc[el.acc_cc_name]) {
      acc[el.acc_cc_name] = {
        label: el.acc_cc_name,
        data: new Array(MONTH_DATA.length).fill(0),
        fill: false,
        borderColor: el.color_code,
        backgroundColor: el.color_code,
        tension: 0.1,
        hidden: true,
        pointStyle: "circle",
        pointRadius: 2,
      };
    }
    const monthIndex = MONTH_DATA.indexOf(month);
    if (monthIndex >= 0) {
      acc[el.acc_cc_name].data[monthIndex] += parseFloat(el.cost_sum);
    }

    return acc;
  }, {});

  const sortedData = Object.values(groupedData).sort(
    (a, b) =>
      b.data.reduce((sum, val) => sum + val, 0) -
      a.data.reduce((sum, val) => sum + val, 0)
  );

  sortedData.forEach((item, index) => {
    if (index > 2) {
      item.hidden = true;
    } else {
      item.hidden = false;
    }
  });

  data_chart_cost_all_info.datasets = sortedData;

  const data_chart_month_vs_monthago = {
    labels: ["เดือนที่แล้ว", "เดือนปัจจุบัน"], // The two months being compared
    datasets: costMonthVsMonthAgo.data.map((el) => el),
    // || [
    //   {
    //     label: 'ค่าสวัสดิการพนักงาน',
    //     backgroundColor: 'purple',
    //     data: [20000, 25000], // Data for each month
    //     borderRadius: 10,
    //     barThickness: 35
    //   },
    //   {
    //     label: 'ค่ารับรองลูกค้า',
    //     backgroundColor: 'red',
    //     data: [15000, 18000], // Data for each month
    //     borderRadius: 10,
    //     barThickness: 35
    //   },
    //   {
    //     label: 'ค่าวัสดุสิ้นเปลือง',
    //     backgroundColor: 'green',
    //     data: [12000, 15000], // Data for each month
    //     borderRadius: 10,
    //     barThickness: 35
    //   },
    //   {
    //     label: 'ค่าอุปกรณ์สำนักงาน',
    //     backgroundColor: 'orange',
    //     data: [10000, 12000], // Data for each month
    //     borderRadius: 10,
    //     barThickness: 35
    //   },
    //   {
    //     label: 'ค่าเดินทาง',
    //     backgroundColor: 'blue',
    //     data: [5000, 7000], // Data for each month
    //     borderRadius: 10,
    //     barThickness: 35
    //   },
    // ],
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = mostCostTypeAcc.data.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const totalPages = Math.ceil(mostCostTypeAcc.data.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const useContainerDimensions = (myRef, key) => {
    const [dimensions, setDimensions] = useState({
      ["cardWidth" + (key || "")]: 0,
      ["cardHeight" + (key || "")]: 0,
    });

    useEffect(() => {
      const getDimensions = () => ({
        ["cardWidth" + (key || "")]: myRef.current.offsetWidth,
        ["cardHeight" + (key || "")]: myRef.current.offsetHeight,
      });

      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef]);

    return dimensions;
  };

  const declarationRef = useRef();
  const { cardWidth, cardHeight } = useContainerDimensions(declarationRef);

  async function getMostCostTypeAcc(start_date, end_date) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/getMostCostTypeAcc",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.sort(
            (a, b) => b.cost_sum - a.cost_sum
          );
          setMostCostTypeAcc({
            data: data,
          });
          mostCostTypeAcc.data = data;
        } else {
          setMostCostTypeAcc({
            data: [],
          });
          mostCostTypeAcc.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getLineChart(start_date, end_date) {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/getLineChart",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.sort(
            (a, b) => b.cost_sum - a.cost_sum
          );
          let index = 0;
          for (let item of data) {
            item.color_code = getRandomColor(index);
            index += 1;
          }
          setCostLineChart({
            data: data,
          });
          costLineChart.data = data;
        } else {
          setCostLineChart({
            data: [],
          });
          costLineChart.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getCostAllInfo(start_date, end_date) {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/getCostAllInfo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data; // || response.data.data.sort((a, b) => b.cost_sum - a.cost_sum)
          const data_result = [];
          for (let item of MONTH_DATA) {
            const _find = data.find((el) => el.month == item);
            if (_find) data_result.push(Number(_find.cost_sum || 0));
            else data_result.push(0);
          }
          setCostAllInfo({
            data: data_result,
          });
          costAllInfo.data = data_result;
        } else {
          setCostAllInfo({
            data: [],
          });
          costAllInfo.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getCostMonthVsMonthAgo() {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/getCostMonthVsMonthAgo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.sort((a, b) => a.data[0] - b.data[0]);
          // const data = response.data.data.map(el => {
          //   el.log_list.sort((a, b) => new Date(b.rl_date_filed) - new Date(a.rl_date_filed))
          //   return el;
          // })
          setCostMonthVsMonthAgo({
            data: data,
          });
          costMonthVsMonthAgo.data = data;
        } else {
          setCostMonthVsMonthAgo({
            data: [],
          });
          costMonthVsMonthAgo.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function GetAllDashboard(start_date, end_date) {
    setCostMonthVsMonthAgo({ data: [] });
    costMonthVsMonthAgo.data = [];
    setMostCostTypeAcc({ data: [] });
    mostCostTypeAcc.data = [];
    getMostCostTypeAcc(start_date, end_date);
    getCostMonthVsMonthAgo();
    getCostAllInfo(start_date, end_date);
    getLineChart(start_date, end_date);
  }

  useEffect(() => {
    if (props.functionComponenet && props.setFunctionComponenet) {
      props.setFunctionComponenet({
        ...props.functionComponenet,
        getDataChart2: GetAllDashboard,
      });
      props.functionComponenet.getDataChart2 = GetAllDashboard;
    }
  }, []);

  return (
    <section className="content">
      <div className="row">
        <div className="col-12 col-xl-3 col-md-12">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body p-2">
              <div>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>
                  ประเภทที่มีค่าใช้จ่ายสูงสุด
                </span>
              </div>
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <table
                  className="table-style-dashboard-log"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                    <tr>
                      <th
                        style={{ width: "80px", borderTopLeftRadius: "10px" }}
                      >
                        No.
                      </th>
                      <th>ประเภท</th>
                      <th style={{ borderTopRightRadius: "10px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>ค่าใช้จ่าย</div>
                        </div>
                      </th>
                      {/* <th>MGT</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.length > 0 ? (
                      currentRows.map((row, row_index) => (
                        <React.Fragment key={row.emp_id}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfFirstRow + row_index + 1}
                            </td>
                            <td>{row.acc_cc_name}</td>
                            <td style={{ textAlign: "right" }}>
                              {Number(row.cost_sum || 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: "100px",
                            width: "100%",
                          }}
                        >
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Left Arrow */}
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor: currentPage === 1 ? "not-allowed" : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentPage === 1
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &lt; {/* Left arrow symbol */}
                </button>
                {/* Page Numbers */}
                {totalPages <= 4 ? (
                  // Show all pages when total pages are 4 or less
                  Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      style={{
                        margin: "0 5px",
                        padding: "5px",
                        color:
                          currentPage === index + 1
                            ? "rgba(53, 121, 246, 1)"
                            : "rgba(102, 102, 102, 1)",
                        border: "none",
                        background: "none",
                        backgroundColor: "none",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {/* Show first page and ellipsis if needed */}
                    {currentPage > 3 && (
                      <>
                        <button
                          onClick={() => handlePageChange(1)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentPage === 1
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          1
                        </button>
                        {currentPage > 4 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                      </>
                    )}

                    {/* Dynamic pages */}
                    {Array.from({ length: 3 }, (_, index) => {
                      const pageIndex = Math.max(1, currentPage - 1) + index;
                      if (pageIndex <= totalPages) {
                        return (
                          <button
                            key={pageIndex}
                            onClick={() => handlePageChange(pageIndex)}
                            style={{
                              margin: "0 5px",
                              padding: "5px",
                              color:
                                currentPage === pageIndex
                                  ? "rgba(53, 121, 246, 1)"
                                  : "rgba(102, 102, 102, 1)",
                              border: "none",
                              background: "none",
                              backgroundColor: "none",
                              cursor: "pointer",
                            }}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                      return null;
                    })}

                    {/* Show last page and ellipsis if needed */}
                    {currentPage < totalPages - 2 && (
                      <>
                        {currentPage < totalPages - 3 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                        <button
                          onClick={() => handlePageChange(totalPages)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentPage === totalPages
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {totalPages}
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* Right Arrow */}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentPage === totalPages ? "not-allowed" : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentPage === totalPages
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &gt; {/* Right arrow symbol */}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-4 col-md-12">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body p-2">
              <div>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>
                  ประเภทที่มีค่าใช้จ่ายสูงสุด
                </span>
              </div>
              <div style={{ maxHeight: "321px", overflow: "auto" }}>
                {/* <Horizontal_Bar_Chart key="bar1" data={bar1} barRef={bar1Ref} chartData={chartData} minWidth={'350px'} /> */}
                <Horizontal_Bar_Chart
                  Text={""}
                  height={`${
                    mostCostTypeAcc.data.length * 60 < 320
                      ? 320
                      : mostCostTypeAcc.data.length * 54
                  }px`}
                  DataChart={data_chart_cost_type}
                  func={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5 col-md-12">
          <div ref={declarationRef} className="card" style={{ height: "100%" }}>
            <div className="card-body p-2">
              <div>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>
                  เปรียบเทียบหัวข้อค่าใช้จ่ายรายเดือน {" "}
                </span>
              </div>
              {/* <div style={{ height: '320px'}}>
                <BarChart key="bar1" data={bar1} barRef={bar1Ref} chartData={data_chart_status} />
              </div> */}
              <div
                style={{
                  overflow: "auto",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Line_Chart
                  Text={""}
                  isHideLegend={true}
                  DataChart={data_chart_cost_all_info}
                  func={() => {}}
                  pos={1}
                  // minWidth={"800px"}
                  // width={`${
                  //   data_chart_cost_all_info.labels.length * 100 < cardWidth
                  //     ? "100%"
                  //     : `calc(${
                  //         data_chart_cost_all_info.labels.length * 100
                  //       }px + 100%)`
                  // }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComponentDashboard2;
