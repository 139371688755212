import React from "react";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function Line_Chart(params) {
  ChartJS.register(
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  function numberWithCommas(x) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "-15px",
        paddingTop: params.isHideLegend ? "20px" : "0px",
      }}
    >
      <label
        style={{
          fontSize: "23px",
          lineHeight: "normal",
          margin: "0px",
          display: "block",
          textAlign: "center",
          margin: "0px 0px 15px 0px",
        }}
      >
        {params.Text}
      </label>
      <div
        className="customScroll-1"
        style={{
          height: params.isFullView
            ? "750px"
            : params.height
            ? params.height
            : "300px",
          width: params.width ? params.width : "100%",
          overflowX: "auto",
        }}
      >
        <Line
          data={params.DataChart}
          height={"300px"}
          options={{
            onHover: (event, chartElement) => {
              if (params.func) {
                if (chartElement.length == 1) {
                  event.native.target.style.cursor = "pointer";
                }
              }
            },
            // responsive: true,
            maintainAspectRatio: false,
            indexAxis: "x",
            scales: {
              x: { display: true },
              y: { display: true, beginAtZero: true,grace: "10%", },
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
                align: "center",

                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
              datalabels: {
                display: false,
                formatter: function (value) {
                    let str_show = Number(value || 0)
                      ? Number(value || 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0;
                    return str_show;
                  },
                  anchor: "end",
                  align: "top",
                  font: {
                    size: 12,
                  },
                  listeners: {
                    enter: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([
                          {
                            datasetIndex: context.datasetIndex,
                            index: context.dataIndex,
                          },
                        ]);
                        chart.update();
                      }
                    },
                    leave: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([]);
                        chart.update();
                      }
                    },
                  },
              },
            },
          }}
          plugins={[
            {
              beforeInit(chart) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                  originalFit.bind(chart.legend)();
                  this.height += 20;
                };
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
export default Line_Chart;
