import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import time_log_excel from "../../assets/file/Templat_time_log.xlsx";

function Operational_data(params) {
  let inputRef;

  const [updateTimeAttendance, setupdateTimeAttendance] = useState({
    start_date: "",
    end_date: "",
  });

  const [feature, setfeature] = useState([]);
  let [optionfull, setoptionfull] = useState(null);

  const [externaldata, setexternaldata] = useState({
    company_id: getUser().com,
    file_name: "",
    data: "",
    template: "",
    oem_id: getOem(),
    status_upload: "",
    upload_detail: "",
    data_time: "",
  });

  const [LeaveTypeOption, setLeaveTypeOption] = useState([]);
  const [optionfullfilter, sfetoptionfullfilter] = useState([
    { label: "ทั้งหมด", value: "all" },
    { label: "ทำงานครบ 8 ชม.", value: "full" },
    { label: "ทำงานไม่ครบ 8 ชม.", value: "notfull" },
  ]);

  const today = new Date();

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  // *******************************************************************

  // *******************************************************************

  var start_day = moment(new Date()).startOf("month").format("YYYY-MM-DD");
  var end_day = moment(new Date()).endOf("month").format("YYYY-MM-DD");

  const [filler_operational_data, set_filler_operational_data] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_name: "",
    emp_type_name: "",
    emp_type_id: "",
    time_start: new Date(start_day),
    time_end: new Date(today),
    emp_contract_type_id: "",
    emp_status_id: "",
  });

  const [type_emp, set_type_emp] = useState([]);

  const [contract_type_emp, setcontract_type_emp] = useState([]);

  const [time_attendance, set_time_attendance] = useState([]);
  const [time_attendance_forfilter, set_time_attendance_forfilter] = useState(
    []
  );
  let [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState([]);

  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลการปฎิบัติงาน";
    });
    /*   console.log("feature_data",feature_data); */
    setfeature(feature_data);
    GetEmployeeWorkType();
    GetEmployeeType();
    GetLogTimeAttendance();
    GetTTTemployeeTyer();
    getLeaveType();
  }, []);

  const clearFilter = async () => {
    await set_filler_operational_data({
      ...filler_operational_data,
      emp_name: "",
      emp_type_name: "",
      emp_type_id: "",
      time_start: new Date(start_day),
      time_end: new Date(today),
      emp_contract_type_id: "",
      emp_status_id: "",
    });

    filler_operational_data.emp_name = "";
    filler_operational_data.emp_type_name = "";
    filler_operational_data.emp_type_id = "";
    filler_operational_data.time_start = new Date(start_day);
    filler_operational_data.time_end = new Date(end_day);
    filler_operational_data.emp_contract_type_id = "";
    filler_operational_data.emp_status_id = "";

    setoptionfull("all");
    optionfull = "all";

    GetLogTimeAttendance();
  };

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData(arrList);
        getEmployeeWorkTypeData = arrList;

        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployeeType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    /* console.log("get_com_oem", get_com_oem); */
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /* console.log(response.data,"dddd"); */
        set_type_emp(response.data.data);
      })
      .catch(function (error) {});
  };

  const GetTTTemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    // console.log("get_com_oem", get_com_oem);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeContractType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // console.log(response.data, "dddd");
        setcontract_type_emp(response.data.data);
      })
      .catch(function (error) {});
  };

  const upload_file = async (e) => {
    const file = e.target.files[0];
    const dateRange = { datetime: [] };
    const dateRange_diff = moment(filler_operational_data.time_end).diff(
      filler_operational_data.time_start,
      "days"
    );

    for (let index = 0; index <= dateRange_diff; index++) {
      dateRange.datetime.push(
        moment(filler_operational_data.time_start)
          .add(index, "day")
          .format("YYYY-MM-DDT04:00:00+07:00")
      );
    }
    // console.log(JSON.stringify(dateRange));

    var data = new FormData();
    //Adding files to the formdata
    data.append("company_id", getUser().com);
    data.append("datetime", JSON.stringify(dateRange));
    data.append("file", e.target.files[0]);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    /*  const temp_to_base ={
      file:data,
      date_time:dateRange,
      oem_id:getOem(),
      company_id:getUser().com,
    } */
    axios({
      // Endpoint to send files
      method: "POST",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/importExcelTimeAttendance",
      headers: {
        authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // Attaching the form data
      data: data,
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.data[0] === "failured") {
          Swal.fire("Error", "อัพเดทข้อมูลไม่สำเร็จ", "error").then(
            (result) => {
              GetLogTimeAttendance();
              window.location.reload();
            }
          );
        } else {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetLogTimeAttendance();
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      }); // Catch errors if any */
  };

  // render() {
  //   return (
  //     <div>
  //       <h1>Select your files</h1>
  //       <input
  //         type="file"
  //         multiple="multiple"  //To select multiple files
  //         onChange={(e) => this.handleFile(e)}
  //       />
  //       <button onClick={(e) => this.handleUpload(e)}
  //       >Send Files</button>
  //     </div>
  //   );
  // }

  const GetLogTimeAttendance = async () => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
      setoptionfull("all");
      optionfull = "all";
    }

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterTimeAttendance",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_operational_data,
    })
      .then(function (response) {
        if (getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
          let empAll = [];

          // const sum_ = response.data.data.reduce((acc, curr) => acc + Number(curr.normal_hours), 0)
          // console.log('sum_ :>> ', sum_);

          for (let item of getEmployeeWorkTypeData) {
            let empEqualType = response.data.data.filter((empItem) => {
              return empItem.employee_contract_type_id === item.id;
            });

            let AllData = [];
            const groupData = Object.values(
              empEqualType.reduce((acc, current) => {
                acc[current.date] = acc[current.date] ?? [];
                acc[current.date].push(current);
                return acc;
              }, {})
            );

            for (let item1 of groupData) {
              item1.sort((a, b) => {
                if (a.emp_no === null) {
                  return 1;
                }

                if (b.emp_no === null) {
                  return -1;
                }

                if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
                  return 0;
                }

                return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
              });

              AllData.push(...item1);
            }

            empAll.push(...AllData);
          }

          set_time_attendance(empAll);
          set_time_attendance_forfilter(empAll);
        } else {
          set_time_attendance(response.data.data);
          set_time_attendance_forfilter(response.data.data);
        }
        if (
          optionfull !== "all" &&
          optionfull !== null &&
          getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb"
        ) {
          setfilter_complete();
        }

        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  };

  const numberWithOutcolon = (x) => {
    return x.toString().replace(/./g, ":");
  };

  const rowsData = [];
  const rowsData1 = [];
  const rowsData2 = [];
  for (var index = 0; index < time_attendance.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    if (time_attendance[index].emp_no === null) {
      rowItem["emp_code"] = "-";
    } else {
      rowItem["emp_code"] = time_attendance[index].emp_no;
    }

    rowItem["name"] = time_attendance[index].emp_name_th;

    if (time_attendance[index].period_time_name === null) {
      rowItem["preroid"] = "-";
    } else {
      rowItem["preroid"] = time_attendance[index].period_time_name;
    }

    if (time_attendance[index].contract_type_name === null) {
      rowItem["contract_type"] = "-";
    } else {
      rowItem["contract_type"] = time_attendance[index].contract_type_name;
    }

    if (time_attendance[index].date === null) {
      rowItem["date"] = "-";
    } else {
      rowItem["date"] = moment(time_attendance[index].date).format("DD/MM/YY");
    }

    if (time_attendance[index].check_in === null) {
      rowItem["check_in"] = " - ";
    } else {
      rowItem["check_in"] =
        moment(time_attendance[index].check_in).format("HH:mm") + "น.";
    }

    if (time_attendance[index].check_out === null) {
      rowItem["check_out"] = "-";
    } else {
      rowItem["check_out"] =
        moment(time_attendance[index].check_out).format("HH:mm") + "น.";
    }

    if (time_attendance[index].check_in_pad === null) {
      rowItem["check_in_pad"] = " - ";
    } else {
      rowItem["check_in_pad"] =
        moment(time_attendance[index].check_in_pad).format("HH:mm") + "น.";
    }

    if (time_attendance[index].check_out_pad === null) {
      rowItem["check_out_pad"] = "-";
    } else {
      rowItem["check_out_pad"] =
        moment(time_attendance[index].check_out_pad).format("HH:mm") + "น.";
    }

    if (time_attendance[index].normal_hours === null) {
      rowItem["normal"] = "-";
    } else {
      /*  let temp_hours =  Math.floor( time_attendance[index].normal_hours);
      let temp_mins =time_attendance[index].normal_hours === 0 ?"" : ( Number( time_attendance[index].normal_hours.split('.')[1]) * 60) /10;  String( time_attendance[index].normal_hours).replace(/\./g, ":") */

      /*  console.log(time_attendance[index].normal_hours)
      console.log(String( time_attendance[index].normal_hours).replace(/\./g, ":")); */

      if (getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
        rowItem["normal"] =
          time_attendance[index].normal_hours > 0
            ? Math.floor(time_attendance[index].normal_hours / 60) < 10
              ? `0${Math.floor(time_attendance[index].normal_hours / 60)}:${
                  time_attendance[index].normal_hours % 60 < 10
                    ? `0${time_attendance[index].normal_hours % 60}`
                    : time_attendance[index].normal_hours % 60
                }`
              : `${Math.floor(time_attendance[index].normal_hours / 60)}:${
                  time_attendance[index].normal_hours % 60 < 10
                    ? `0${time_attendance[index].normal_hours % 60}`
                    : time_attendance[index].normal_hours % 60
                }`
            : "00:00"; /* time_attendance[index].normal_hours time_attendance[index].normal_hours; */
      } else {
        rowItem["normal"] = time_attendance[index].normal_hours;
      }
    }

    if (time_attendance[index].ot_hours === null) {
      rowItem["ot"] = "-";
    } else {
      rowItem["ot"] = time_attendance[index].ot_hours;
    }

    if (time_attendance[index].ot_hours === null) {
      rowItem["ot"] = "-";
    } else {
      rowItem["ot"] = time_attendance[index].ot_hours;
    }

    if (time_attendance[index].extra_ot_hours === null) {
      rowItem["extra_ot"] = "-";
    } else {
      rowItem["extra_ot"] = time_attendance[index].extra_ot_hours;
    }

    if (time_attendance[index].other_hours === null) {
      rowItem["other"] = "-";
    } else {
      rowItem["other"] = time_attendance[index].other_hours;
    }

    if (time_attendance[index].rate_time === null) {
      rowItem["rate"] = "-";
    } else {
      if (getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
        rowItem["rate"] =
          time_attendance[index].rate_time > 0
            ? Math.floor(time_attendance[index].rate_time / 60) < 10
              ? `0${Math.floor(time_attendance[index].rate_time / 60)}:${
                  time_attendance[index].rate_time % 60 < 10
                    ? `0${time_attendance[index].rate_time % 60}`
                    : time_attendance[index].rate_time % 60
                }`
              : `${Math.floor(time_attendance[index].rate_time / 60)}:${
                  time_attendance[index].rate_time % 60 < 10
                    ? `0${time_attendance[index].rate_time % 60}`
                    : time_attendance[index].rate_time % 60
                }`
            : "-";
      } else {
        rowItem["rate"] = time_attendance[index].rate_time;
      }
    }

    if (time_attendance[index].early_leave === null) {
      rowItem["early_leare"] = "-";
    } else {
      rowItem["early_leare"] = time_attendance[index].early_leave;
    }

    if (time_attendance[index].sunday_work === null) {
      rowItem["sunday"] = "-";
    } else {
      rowItem["sunday"] = time_attendance[index].sunday_work;
    }

    if (time_attendance[index].normal_hours === null) {
      rowItem["full_time_work"] = "-";
    } else {
      let full_time_work =
        time_attendance[index].normal_hours > 0
          ? Math.floor(time_attendance[index].normal_hours / 60) >= 8
            ? "ปกติ"
            : "ไม่ครบ"
          : "ไม่ครบ";

      if (
        getUser().com == "5146dd92-19f6-4dab-ad49-735a114fcfdb" &&
        time_attendance[index].employee_status_leave_detail
      ) {
        const leave = time_attendance[index].employee_status_leave_detail.find(
          (el) => el.leave_half_day == 1 || el.leave_half_day == 2
        );
        if (
          leave &&
          time_attendance[index].normal_hours > 0 &&
          full_time_work == "ไม่ครบ"
        ) {
          full_time_work =
            time_attendance[index].normal_hours / 60 >=
            (leave.leave_half_day == 2 ? 3.5 : 4.5)
              ? "ปกติ"
              : "ไม่ครบ";
        }
      }
      rowItem["full_time_work"] = full_time_work;
    }

    if (time_attendance[index].status_name === null) {
      rowItem["status"] = "-";
    } else {
      rowItem["status"] = time_attendance[index].status_name;
    }

    if (time_attendance[index].employee_status_leave_name === null) {
      rowItem["status_system"] = "-";
    } else {
      if (time_attendance[index].employee_status_leave_detail) {
        let status_temp_system =
          time_attendance[index].employee_status_leave_name;

        /*  switch (time_attendance[index].employee_status_leave_detail.leave_type) {
              case 0:
                status_temp_system += "-ลาทั้งวัน"
                break;
              case 1:
                status_temp_system += "-ลาแบบกำหนดเวลา"
                break;
            
              default:
                break;
            } */

        if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
          if (
            time_attendance[index].employee_status_leave_detail
              .employee_status_id === "ee11eac2-23f7-4844-aba3-152ebe82c63a"
          ) {
            if (
              time_attendance[index].employee_status_leave_detail.start_time ===
                null ||
              time_attendance[index].employee_status_leave_detail.end_time ===
                null
            ) {
              status_temp_system += `-(ไม่ระบุเวลา)`;
            } else {
              status_temp_system += `-(${
                time_attendance[index].employee_status_leave_detail.start_time +
                "-" +
                time_attendance[index].employee_status_leave_detail.end_time
              })`;
            }
          } else {
            switch (
              time_attendance[index].employee_status_leave_detail.leave_half_day
            ) {
              case 0:
                status_temp_system += "-ลาทั้งวัน";
                break;
              case 1:
                status_temp_system += "-ลาครี่งเช้า";
                break;
              case 2:
                status_temp_system += "-ลาครี่งบ่าย";
                break;
              default:
                break;
            }
          }
        } else {
          status_temp_system = time_attendance[
            index
          ].employee_status_leave_detail
            .map((el) => el.status_name)
            .join(`\n`);
        }

        rowItem["status_system"] = (
          <div style={{ whiteSpace: "pre" }}>{status_temp_system}</div>
        );
      } else {
        rowItem["status_system"] =
          time_attendance[index].employee_status_leave_name;
      }
    }
    // console.log('time_attendance[index].employee_status_leave_detail', time_attendance[index].employee_status_leave_detail)
    if (time_attendance[index].employee_status_leave_detail) {
      let status_temp_system = "";

      if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
        if (
          time_attendance[index].employee_status_leave_detail.start_date ===
          time_attendance[index].employee_status_leave_detail.ent_date
        ) {
          status_temp_system = `${moment(
            time_attendance[index].employee_status_leave_detail.start_date
          ).format("DD/MM/YY")} : `;
        } else {
          status_temp_system = `${moment(
            time_attendance[index].employee_status_leave_detail.start_date
          ).format("DD/MM/YY")} - ${moment(
            time_attendance[index].employee_status_leave_detail.ent_date
          ).format("DD/MM/YY")} : `;
        }
        if (
          time_attendance[index].employee_status_leave_id ===
          "ee11eac2-23f7-4844-aba3-152ebe82c63a"
        ) {
          console.log(
            "in if",
            time_attendance[index].employee_status_leave_detail.start_time,
            time_attendance[index].employee_status_leave_detail.end_time
          );

          if (
            time_attendance[index].employee_status_leave_detail.start_time ===
              "08:30" &&
            time_attendance[index].employee_status_leave_detail.end_time ===
              "17:30" /*  || time_attendance[index].employee_status_leave_detail.leave_type == '1' */
          ) {
            // console.log('ภายในเวลา 08:30 - 17:30', time_attendance[index].employee_status_leave_detail.leave_half_day)
            status_temp_system += "ทั้งวัน";
            // } else if( time_attendance[index].employee_status_leave_detail.end_time > "17:30"){
            //   console.log('มากกว่า 17:30', time_attendance[index].employee_status_leave_detail.leave_half_day)
            //   status_temp_system += `${time_attendance[index].employee_status_leave_detail.start_time} - ${time_attendance[index].employee_status_leave_detail.end_time} น.`;
          } else {
            status_temp_system += `${time_attendance[index].employee_status_leave_detail.start_time} - ${time_attendance[index].employee_status_leave_detail.end_time} น.`;
          }
        } else {
          console.log(
            "in else",
            time_attendance[index].employee_status_leave_detail.start_time,
            time_attendance[index].employee_status_leave_detail.end_time
          );
          // console.log('time_attendance[index].employee_status_leave_detail.leave_half_day', time_attendance[index])
          if (
            time_attendance[index].employee_status_leave_detail.leave_type ==
              "1" &&
            !time_attendance[index].employee_status_leave_detail.start_time &&
            !time_attendance[index].employee_status_leave_detail.end_time
          ) {
            status_temp_system += "ทั้งวัน";
          } else {
            switch (
              time_attendance[index].employee_status_leave_detail.leave_half_day
            ) {
              case 0:
                status_temp_system += `${time_attendance[index].employee_status_leave_detail.start_time} - ${time_attendance[index].employee_status_leave_detail.end_time} น.`;
                break;
              case 1:
                status_temp_system += "08:30 - 12:00 น.";
                break;
              case 2:
                status_temp_system += "13:00 - 17:30 น.";
                break;
              default:
                break;
            }
          }
        }
      } else {
        for (let item of time_attendance[index].employee_status_leave_detail) {
          if (status_temp_system) status_temp_system += "\n";

          if (item.start_date === item.ent_date) {
            status_temp_system += `${moment(item.start_date).format(
              "DD/MM/YY"
            )} : `;
          } else {
            status_temp_system += `${moment(item.start_date).format(
              "DD/MM/YY"
            )} - ${moment(item.ent_date).format("DD/MM/YY")} : `;
          }
          if (
            item.employee_status_id === "ee11eac2-23f7-4844-aba3-152ebe82c63a"
          ) {
            console.log("in if", item.start_time, item.end_time);

            if (
              (item.start_time === "08:30" && item.end_time === "17:30") ||
              (!item.start_time && !item.end_time)
            ) {
              // console.log('ภายในเวลา 08:30 - 17:30', item.leave_half_day)
              status_temp_system += "ทั้งวัน";
              // } else if( item.end_time > "17:30"){
              //   console.log('มากกว่า 17:30', item.leave_half_day)
              //   status_temp_system += `${item.start_time} - ${item.end_time} น.`;
            } else {
              status_temp_system += `${item.start_time} - ${item.end_time} น.`;
            }
          } else {
            console.log("in else", item.start_time, item.end_time);
            // console.log('item.leave_half_day', time_attendance[index])
            if (item.leave_type == "1" && !item.start_time && !item.end_time) {
              status_temp_system += "ทั้งวัน";
            } else {
              switch (item.leave_half_day) {
                case 0:
                  status_temp_system += `${item.start_time} - ${item.end_time} น.`;
                  break;
                case 1:
                  status_temp_system += "08:30 - 12:00 น.";
                  break;
                case 2:
                  status_temp_system += "13:00 - 17:30 น.";
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
      rowItem["detail_leave"] = (
        <div style={{ whiteSpace: "pre" }}>{status_temp_system}</div>
      );
    } else {
      rowItem["detail_leave"] = "-";
    }

    rowsData.push(rowItem);
    rowsData2.push(rowItem);
    if (
      (time_attendance[index].rate_time !== null &&
        time_attendance[index].rate_time > 0) ||
      time_attendance[index].employee_status_leave_detail
    ) {
      rowsData1.push(rowItem);
    }
  }

  const data = {
    columns: [
      {
        label: "No", // ttt, shi
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน", // ttt, shi
        field: "emp_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ", // ttt, shi
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปแบบการทำงาน", // shi
        field: "preroid",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทพนักงาน", // ttt,
        field: "contract_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date ", // ttt, shi
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check In", // ttt, shi
        field: "check_in",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check Out", // ttt, shi
        field: "check_out",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาที่ทำงาน (HH:MM)", // ttt, shi
        field: "normal",
        sort: "asc",
        width: 50,
      },
      {
        label: "OT (h.)", // shi
        field: "ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Extra OT (h.)", // shi
        field: "extra_ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Other (h.)", // shi
        field: "other",
        sort: "asc",
        width: 50,
      },
      {
        label: "มาสาย (HH:MM)", // ttt, shi
        field: "rate",
        sort: "asc",
        width: 50,
      },
      {
        label: "ออกก่อนเวลา (m.)", // shi
        field: "early_leare",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sunday (work)", // shi
        field: "sunday",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทำงานครบ 8 ชม.", // ttt,
        field: "full_time_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "status", // ttt, shi
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "status system", // ttt, shi
        field: "status_system",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียดวันลา", // ttt,
        field: "detail_leave",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const data1 = {
    columns: [
      {
        label: "No", // ttt, shi
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน", // ttt, shi
        field: "emp_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ", // ttt, shi
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปแบบการทำงาน", // shi
        field: "preroid",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทพนักงาน", // ttt,
        field: "contract_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date ", // ttt, shi
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check In", // ttt, shi
        field: "check_in",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check Out", // ttt, shi
        field: "check_out",
        sort: "asc",
        width: 50,
      },

      {
        label: "เวลาที่ทำงาน (HH:MM)", // ttt, shi
        field: "normal",
        sort: "asc",
        width: 50,
      },
      {
        label: "OT (h.)", // shi
        field: "ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Extra OT (h.)", // shi
        field: "extra_ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Other (h.)", // shi
        field: "other",
        sort: "asc",
        width: 50,
      },
      {
        label: "มาสาย (HH:MM)", // ttt, shi
        field: "rate",
        sort: "asc",
        width: 50,
      },
      {
        label: "ออกก่อนเวลา (m.)", // shi
        field: "early_leare",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sunday (work)", // shi
        field: "sunday",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทำงานครบ 8 ชม.", // ttt,
        field: "full_time_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "status", // ttt, shi
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "status system", // ttt, shi
        field: "status_system",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียดวันลา", // ttt,
        field: "detail_leave",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData1,
  };
  const data2 = {
    columns: [
      {
        label: "No", // ttt, shi
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน", // ttt, shi
        field: "emp_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ", // ttt, shi
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปแบบการทำงาน", // shi
        field: "preroid",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทพนักงาน", // ttt,
        field: "contract_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date ", // ttt, shi
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check In", // ttt, shi
        field: "check_in",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check Out", // ttt, shi
        field: "check_out",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาที่ทำงาน (HH:MM)", // ttt, shi
        field: "normal",
        sort: "asc",
        width: 50,
      },
      {
        label: "OT (h.)", // shi
        field: "ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Extra OT (h.)", // shi
        field: "extra_ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Other (h.)", // shi
        field: "other",
        sort: "asc",
        width: 50,
      },
      {
        label: "มาสาย (HH:MM)", // ttt, shi
        field: "rate",
        sort: "asc",
        width: 50,
      },
      {
        label: "ออกก่อนเวลา (m.)", // shi
        field: "early_leare",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sunday (work)", // shi
        field: "sunday",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทำงานครบ 8 ชม.", // ttt,
        field: "full_time_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "status", // ttt, shi
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "status system", // ttt, shi
        field: "status_system",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียดวันลา", // ttt,
        field: "detail_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check In Pad", // ttt, shi
        field: "check_in_pad",
        sort: "asc",
        width: 50,
      },
      {
        label: "Check Out Pad", // ttt, shi
        field: "check_out_pad",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData2,
  };
  if (getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
    const index1 = data.columns.findIndex((e) => {
      return e.field === "preroid";
    });

    const index2 = data.columns.findIndex((e) => {
      return e.field === "ot";
    });

    const index3 = data.columns.findIndex((e) => {
      return e.field === "extra_ot";
    });

    const index4 = data.columns.findIndex((e) => {
      return e.field === "other";
    });

    const index5 = data.columns.findIndex((e) => {
      return e.field === "early_leare";
    });

    const index6 = data.columns.findIndex((e) => {
      return e.field === "sunday";
    });

    data.columns.splice(index1, 1);
    data.columns.splice(index2 - 1, 1);
    data.columns.splice(index3 - 2, 1);
    data.columns.splice(index4 - 3, 1);
    data.columns.splice(index5 - 4, 1);
    data.columns.splice(index6 - 5, 1);

    data1.columns.splice(index1, 1);
    data1.columns.splice(index2 - 1, 1);
    data1.columns.splice(index3 - 2, 1);
    data1.columns.splice(index4 - 3, 1);
    data1.columns.splice(index5 - 4, 1);
    data1.columns.splice(index6 - 5, 1);

    const index7 = data.columns.findIndex((e) => {
      return e.field === "status";
    });

    const index8 = data.columns.findIndex((e) => {
      return e.field === "status_system";
    });

    data.columns[index7].label = "สถานะทำงาน";
    data.columns[index8].label = "สถานะวันลา";

    data1.columns[index7].label = "สถานะทำงาน";
    data1.columns[index8].label = "สถานะวันลา";
  } else {
    const index2 = data.columns.findIndex((e) => {
      return e.field === "contract_type";
    });

    const index3 = data.columns.findIndex((e) => {
      return e.field === "full_time_work";
    });

    const index4 = data.columns.findIndex((e) => {
      return e.field === "detail_leave";
    });

    data.columns.splice(index2, 1);
    data.columns.splice(index3 - 1, 1);
    data.columns.splice(index4 - 2, 1);

    const index5 = data.columns.findIndex((e) => {
      return e.field === "normal";
    });

    const index6 = data.columns.findIndex((e) => {
      return e.field === "rate";
    });

    data.columns[index5].label = "เวลาที่ทำงาน (h.)";
    data.columns[index6].label = "มาสาย (m.)";
  }

  function UpdateTimeAttendance() {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (
      updateTimeAttendance.end_date == "" ||
      updateTimeAttendance.start_date == ""
    ) {
      Swal.fire("Warning", "กรุณาเลือกวันที่ให้ครบถ้วน", "warning");
    } else {
      axios({
        method: "get",
        url:
          Configs.API_URL_hrMagenatement +
          "/config?company_id=" +
          getUser().com +
          "&oem_id=" +
          getOem(),
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          var day_total = moment(updateTimeAttendance.end_date).diff(
            moment(updateTimeAttendance.start_date),
            "days"
          );

          day_total += 1;

          var date_arr = [];

          for (var index = 0; index < day_total; index++) {
            var date_in_date = moment(updateTimeAttendance.start_date)
              .add(4, "hours")
              .add(index, "days")
              .format();
            date_arr.push(date_in_date);
          }

          // console.log(day_total, date_arr);

          // var count_pad = 1;

          for (let pad of response.data.data) {
            var data = JSON.stringify({
              datetime: date_arr,
            });
            /* let timerInterval;
            Swal.fire({
              title: "Update In Process",
              html:
                "กำลังดึงของมูลจาก pad " +
                pad.pad_name +
                " จะเสร็จในอีก <b></b> วินาที.",
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
            
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            }); */

            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/pad/time-attendance/pull?pad_id=" +
                pad.id +
                "&company_id=" +
                getUser().com +
                "&oem_id=" +
                getOem(),
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: data,
            })
              .then(function (response2) {
                // console.log("success ", pad.pad_name);

                Swal.fire({
                  icon: "success",
                  title: "Save " + pad.pad_name,
                  showConfirmButton: false,
                  timer: 1500,
                })
                  .then((result) => {
                    GetLogTimeAttendance();
                  })
                  .finally(() => {
                    Swal.close();
                  });
              })
              .catch(function (error) {
                Swal.fire({
                  icon: "error",
                  title:
                    "ดึงข้อมูลไม่สำเร็จกรุณาเช็ค Pad conections " +
                    pad.pad_name,
                  showConfirmButton: false,
                  timer: 1500,
                });
                // .then((result) => {
                //   GetLogTimeAttendance();
                // });
                // Swal.fire(
                //   "Error",
                //   "ดึงข้อมูลไม่สำเร็จกรุณาเช็ค Pad conections",
                //   "error"
                // );
              });
            // count_pad++;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const getLeaveType = async () => {
    //console.log(filler_employee);
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getLeaveTypeAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        /*   console.log(response.data, "ds"); */

        const options = [];
        var tmp = {
          value: "",
          label: "ทั้งหมด",
        };
        options.push(tmp);
        response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.status_name;
          options.push(element);
        });
        setLeaveTypeOption(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function setfilter_complete() {
    /*  console.log(optionfull); */
    const com_id = getUser().com;
    let temp_timeAtt = time_attendance_forfilter;
    if (optionfull === "full") {
      const data = temp_timeAtt.filter((el) => {

        let hours = 8;
        if (com_id == '5146dd92-19f6-4dab-ad49-735a114fcfdb' && el.employee_status_leave_detail) {
          const leave = el.employee_status_leave_detail.find(
            (el) => el.leave_half_day == 1 || el.leave_half_day == 2
          );
          if (leave) {
            hours = (leave.leave_half_day == 2 ? 3.5 : 4.5);
          }
        }
        return /* Math.floor */(el.normal_hours / 60) >= hours;
      });
      set_time_attendance(data);
    } else if (optionfull === "notfull") {
      const data = temp_timeAtt.filter((el) => {

        let hours = 8;
        if (com_id == '5146dd92-19f6-4dab-ad49-735a114fcfdb' && el.employee_status_leave_detail) {
          const leave = el.employee_status_leave_detail.find(
            (el) => el.leave_half_day == 1 || el.leave_half_day == 2
          );
          if (leave) {
            hours = (leave.leave_half_day == 2 ? 3.5 : 4.5);
          }
        }
        return /* Math.floor */(el.normal_hours / 60) < hours && el.normal_hours !== null;
      });
      set_time_attendance(data);
    } else {
      GetLogTimeAttendance();
    }
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ข้อมูลการปฎิบัติงาน{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">
                    ข้อมูลการปฎิบัติงาน
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2 col-sm-3 col-lg-3">
                <div className="form-group ">
                  <input
                    type="text"
                    id="input-emp-name"
                    className="form-control"
                    required="false"
                    value={filler_operational_data.emp_name}
                    onChange={(e) => {
                      set_filler_operational_data({
                        ...filler_operational_data,
                        emp_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>

              {feature.map((e) => {
                if (e.feature === "normal_emp_type") {
                  return (
                    <div
                      className="col-md-2 col-sm-3 col-lg-3"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="input-emp-type"
                          value={filler_operational_data.emp_type_id}
                          onChange={(e) => {
                            set_filler_operational_data({
                              ...filler_operational_data,
                              emp_type_id: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Select Employee Type
                          </option>
                          {type_emp.map((el) => {
                            return (
                              <option value={el.period_time_id}>
                                {el.period_time}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">ประเภทพนักงาน</label>
                      </div>
                    </div>
                  );
                }
              })}

              {feature.map((e) => {
                if (e.feature === "filter_status") {
                  return (
                    <div
                      className="col-md-2 col-sm-3 col-lg-3"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="input-emp-type"
                          value={filler_operational_data.emp_status_id}
                          onChange={(e) => {
                            set_filler_operational_data({
                              ...filler_operational_data,
                              emp_status_id: e.target.value,
                            });
                          }}
                        >
                          {/*  <option value="" disabled selected>
                            Select Employee Status
                          </option> */}
                          {LeaveTypeOption.map((el) => {
                            return <option value={el.value}>{el.label}</option>;
                          })}
                        </select>
                        <label htmlFor="">{e.feature_name}</label>
                      </div>
                    </div>
                  );
                }
              })}

              {feature.map((e) => {
                if (e.feature === "ttt_emp_type") {
                  return (
                    <div
                      className="col-md-2 col-sm-3 col-lg-3"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="input-ttt-emp-type"
                          value={filler_operational_data.emp_contract_type_id}
                          onChange={(e) => {
                            set_filler_operational_data({
                              ...filler_operational_data,
                              emp_contract_type_id: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Select Employee Type
                          </option>
                          {contract_type_emp.map((el) => {
                            return (
                              <option value={el.id}>
                                {el.contract_type_name}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">{e.feature_name}</label>
                      </div>
                    </div>
                  );
                }
              })}

              {feature.map((e) => {
                if (e.feature === "normal_complete") {
                  return (
                    <div
                      className="col-md-2 col-sm-3 col-lg-3"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="input-ttt-emp-type"
                          defaultValue={"all"}
                          value={optionfull}
                          onChange={(e) => {
                            setoptionfull(e.target.value);
                            optionfull = e.target.value;

                            if (
                              getUser().com !==
                              "5146dd92-19f6-4dab-ad49-735a114fcfdb"
                            ) {
                              setfilter_complete();
                            }
                          }}
                        >
                          <option value="" disabled>
                            เลือกรูปแบบการทำงาน
                          </option>
                          {/*  <option value="all" selected>
                          ทั้งหมด
                          </option>
                          <option value="full">
                          ทำงานครบ 8 ชม.
                          </option>
                          <option value="notfull">
                          ทำงานไม่ครบ 8 ชม.
                          </option> */}

                          {optionfullfilter.map((el) => {
                            return <option value={el.value}>{el.label}</option>;
                          })}
                        </select>
                        <label htmlFor="">ทำงานครบ 8 ชม.</label>
                      </div>
                    </div>
                    // <div className="p-1 m-2 ml-5">
                    //   <input
                    //     className="form-check-input"
                    //     type="checkbox"
                    //     id="isFull"
                    //     //checked={isFull}
                    //     onChange={(e) => {
                    //       setfilter_complete(e);
                    //       // setFiller_material({
                    //       //   ...filler_material,
                    //       //   in: e.target.checked,
                    //       // });
                    //       // filler_material.in = e.target.checked;
                    //       // GetMaterial();
                    //     }}
                    //   />
                    //   <label>{e.feature_name}</label>
                    // </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              {/* &nbsp;&nbsp;          
              <p>วันที่เริ่ม</p>  */}
              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group ">
                  <DatePicker
                    id="input-emp-time_start"
                    customInput={<CustomInput />}
                    selected={filler_operational_data.time_start}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    // onChange={async (date) => SetDate_start_fg(date)}
                    onChange={async (date) => {
                      set_filler_operational_data({
                        ...filler_operational_data,
                        time_start: date,
                      });
                    }}
                    selectsStart
                    startDate={filler_operational_data.time_start}
                    maxDate={today}
                    
                    //  endDate={filler_operational_data.time_end}
                  />
                  <label> วันที่เริ่มต้น </label>
                </div>
              </div>
              {/* <p>วันที่สิ้นสุด</p>  */}
              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "97" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_operational_data.time_end}
                    id="input-emp-time_end"
                    className="form-control"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    customInput={<CustomInput />}
                    
                    //onChange={async (date) => SetDate_end_fg(date)}
                    onChange={async (date) => {
                      set_filler_operational_data({
                        ...filler_operational_data,
                        time_end: date,
                      });
                    }}
                    // selectsRange={true}
                    
                    startDate={filler_operational_data.time_start} 
                    endDate={filler_operational_data.time_end} 
                    minDate={filler_operational_data.time_start} 
                    maxDate={today} 
                    
                    
                  />
                  <label> วันที่สิ้นสุด </label>
                </div>
              </div>
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="operation-search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetLogTimeAttendance();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    id="operation-clear"
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls"
                    filename={
                      "ข้อมูลการปฎิบัติงาน" +
                      moment(new Date()).format("DD-MM-yy")
                    }
                    sheet="tablexls"
                    buttonText="Export to Excel"
                  />
                </div>
              </div>

              {getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb" ? (
                <div className="col-md-2 col-sm-2 col-lg-2">
                  <div className="form-group ">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-block btn-primary"
                      table="table-to-xls1"
                      filename={
                        "ข้อมูลการปฎิบัติงาน (ลา, มาสาย)" +
                        moment(new Date()).format("DD-MM-yy")
                      }
                      sheet="tablexls"
                      buttonText="Export to Excel (ลา, มาสาย)"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="col-sm-2 mt-2">
                <div className="input-group">
                  <div className="custom-file">
                    <>
                      <input
                        type="file"
                        hidden={true}
                        id="exampleInputFile"
                        className="custom-file-input"
                        onChange={upload_file}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        ref={(refParam) => (inputRef = refParam)}
                      />
                      <button
                        className="btn btn-block btn-primary"
                        onClick={() => inputRef.click()}
                      >
                        Upload Time Pad Log
                      </button>
                    </>
                  </div>
                </div>
              </div>

              {feature.map((e) => {
                if (e.feature === "UpdateTimeAttenDance") {
                  return (
                    <>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="operation-search"
                            className="btn btn-block btn-primary"
                            data-toggle="modal"
                            data-target="#modal-updateTimeAttendance"
                            /*     onClick={() => {
                              GetLogTimeAttendance();
                            }} */
                          >
                            Update Time Attendance
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <a
                            href={time_log_excel}
                            download="upload_Time_Attendance.xlsx"
                            style={{ fontSize: "" }}
                          >
                            {" "}
                            <button className="btn btn-block btn-info">
                              download template.xls
                            </button>
                          </a>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                id="ss"
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
              <div style={{ display: "none" }}>
                <MDBDataTable
                  sortable={false}
                  id="table-to-xls"
                  className="table table-head-fixed"
                  entries={4096}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data2}
                />
              </div>
              <div style={{ display: "none" }}>
                <MDBDataTable
                  sortable={false}
                  id="table-to-xls1"
                  className="table table-head-fixed"
                  entries={4096}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data1}
                />
              </div>
            </div>

            <div className="modal fade" id="modal-updateTimeAttendance">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Update Time Attendance</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3"></div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div
                            className="col-sm-12 col-md-12 col-lg-12"
                            style={{ "z-index": "99" }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                value={updateTimeAttendance.start_date}
                                selected={updateTimeAttendance.start_date}
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => {
                                  setupdateTimeAttendance({
                                    ...updateTimeAttendance,
                                    start_date: date,
                                  });
                                }}
                                selectsStart
                                /*   startDate={updateTimeAttendance.start_date} */
                                maxDate={today}
                                customInput={<CustomInput />}
                              />
                            </div>
                            <label htmlFor="">วันที่</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3"></div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div
                            className="col-sm-12 col-md-12 col-lg-12"
                            style={{ "z-index": "98" }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                value={updateTimeAttendance.end_date}
                                selected={updateTimeAttendance.end_date}
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => {
                                  setupdateTimeAttendance({
                                    ...updateTimeAttendance,
                                    end_date: date,
                                  });
                                }}
                                selectsStart
                                minDate={updateTimeAttendance.start_date}
                                customInput={<CustomInput />}
                                maxDate={today}
                              />
                            </div>
                            <label htmlFor="">ถึงวันที่</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer justify-content-between">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        type="button"
                        onClick={UpdateTimeAttendance}
                        data-dismiss="modal"
                        className="btn btn-success"
                      >
                        Update
                      </button>

                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Operational_data;
