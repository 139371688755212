import React, { useState, useEffect } from "react";
import Headers from "../../component/Header";

import {
  setCurrentPath,
  removeUserSession,
  removeOem,
} from "../../Utils/Common";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";
import Footter from "../../component/Footter";
import Sidebar from "../../component/Sidebar2";
import Swal from "sweetalert2";
import {
  Langingview,
  ProdLineMGT,
  ProcessMgt,
  MachineSetup,
  dashboard,
  Product,
  Product_add,
  Materail_add,
  Materail,
  Supplier,
  Supplier_add,
  Project,
  Project_add,
  FGInOut,
  WIPInOut,
  RawMatInOut,
  Warehouse_history,
  Mat_Approve,
  Product_Approve,
  OrderProduct,
  Uploadorderforecast,
  MateForPO,
  DeleveryRecord,
  MaterailCon,
  LogMonitory,
  ModeTranning,
  CuttingInOut,
  Product_Grouping,
  Production_Line,
  Factory_parameter,
  Factory_parameter_section_add,
  Factory_parameter_process_add,
  Factory_parameter_line_add,
  Factory_parameter_machine_add,
  QCM,
  OrderSummaryReport_add,
  OrderSummaryReport,
  QIAI,
  Factory_work_hours,
  Logistic,
  Logistic_add,
  End_user,
  End_user_add,
  LogisticInOut,
  End_userInOut,
  Customer,
  Customer_add,
  Delivery_order_paper,
  Invoice,
  Loop,
  Loop_add,
  Warehouse_type,
  Warehouse_type_add,
  Load_status,
  Load_status_add,
  External_Data,
  Spare_part,
  Spare_part_add,
  Warehouse,
  Warehouse_add_warehouse,
  Warehouse_add_rack,
  Warehouse_add_rack_floor,
  Spare_Part_IN_OUT,
  Order_Raw_Material,
  CHOUPInOut,
  Handheld,
  Machine_FG,
  Profile,
  Mat_type,
  Mat_type_add,
  Mat_spec,
  Mat_spec_add,
  Mat_size,
  Mat_size_add,
  Model_category,
  Model_category_add,
  Report_pdf,
  Record_oil,
  Record_oil_add,
  Promotion,
  Promotion_add,
  Tow_truck,
  Tow_truck_add,
  WIP_SSTInOut,
  WIP_SSTInOut_add,
  LogisticInOut_add,
  Driver,
  Mat_stc,
  Mat_stc_add,
  Data_express,
  wait_delivery,
  Wait_delivery_add,
  Delivery_Time_Record,
  Fuel_Consumtion,
  MachineInOut,
  Factory_work_hoursWithOem,
  Driver_person,
  RawMatSTPInOut,
  DefluxWipInOut,
  FG_STPInOut,
  Wire_Ready_toUse,
  Wire_Ready_toUse_add,
  MatInOutCTS,
  MatInOutCTS_mgt,
  External_Data_CTS,
  Report_PDF_CTS,
  SN_management,
  Employee_Info,
  Employee_add,
  Operational_data,
  System_Configuraion_add,
  System_Parameter,
  Pad_Connection,
  Pad_Connection_add,
  Work_day_summary,
  Company_time_attendance,
  Warehouse_History_CTS,
  Production_order_CTS,
  Production_order_CTS_add,
  Leave_information,
  Leave_information_add,
  Work_shift_information,
  PlanningCTS,
  FactoryChoup,
  FactoryChoup_add,
  Time_working_person,
  WipProdInOutCTS,
  WipProdInOutCTS_mgt,
  WipShupOutInCTS,
  WipShupOutInCTS_mgt,
  HandheldCTS,
  WipPackInOUtCTS,
  WipPackInOUtCTS_mgt,
  Dashboard_HR,
  FGInOutCTS,
  FGInOutCTS_mgt,
  Materail_Consumtion_CTS,
  Leave_information_admin,
  Leave_information_admin_mgt,
  Leave_information_mgt,
  TestLogic,
  Pad_Employee,
  Info,
  Assign_Work,
  Assign_Work_Add,
  Task_Work,
  Manage_team,
  Manage_team_add,
  Leave_information_approve,
  External_dev_train,
  Employee_train,
  Manage_company,
  Manag_Position,
  WINSpeed_PO,
  WinSpeedHandHeld,
  WinSpeedHistory,
  Travel_expenses,
  Travel_expenses_AEV,
  Tooling,
  ToolingInOut,
  Tooling_inquiry,
  Dashboard_HR_TTT,
  Tooling_po_receive,
  NewEmployee,
  Generate_po,
  Info_HR,
  Info_public_view,
  Leave_information_remaining,
  Leave_information_remaining_mgt,
  ManageProductCategoryAdd,
  ManageProductCategoryTable,
  AwardPointTable,
  Award_Point_Add,
  ManageProductAdd,
  ManageSupplierTable,
  ManageSupplierAdd,
  GivePointAdd,
  Manage_grouptable,
  Manage_groupAdd,
  Company_Budget,
  All_Product_Service,
  ThankPointTable,
  ThankPointAdd,
  NewsAdd,
  NewsTable,
  Product_View_Details,
  Product_Service_Category,
  IncentiveUserAwardpoint,
  IncentiveUserAwardpoint_View,
  IncentiveUserThankpoint,
  Confirm_Product,
  PointHistoryEmp,
  ReportSystem,
  ReportSystemAddmin,
  RedeemPointHistoryEmp,
  SummaryPoint,
  OrderInquiry,
  OrderInquiry_Add,
  OrderInquiry_EV,
  Tracking_Status,
  Generate_PO,
  Generate_PO_EV,
  Tracking_Status_TTT,
  Table_Supplier_Cuteplus,
  Table_Customer_CutePlus,
  Table_Product_Cuteplus,
  Table_Certificate_license,
  Table_Distribution_Channel,
  AEV_Distribution,
  AEV_Supplier_Cuteplus,
  Tooling_add,
  Tooling_inquiry_add,
  Tooling_po,
  ManageProductTable,
  PointHistory,
  ManageGroupProductTable,
  ManageGroupProductRead,
  Pay_product_detail_buyer,
  Cart_Product_Sum,
  Perchase_order,
  Table_Product_Category,
  Category_AEV,
  AEV_Customer_Cuteplus,
  AEV_Certificate,
  AEV_License,
  AEV_Product,
  Product_Kitting_Table,
  AEV_Product_Kitting,
  Sales_Order,
  Table_Sales_Order,
  AEV_Sales_Order,
  Tooling_supplier,
  Tooling_supplier_add,
  Tooling_brand,
  Tooling_brand_add,
  Table_Logistic_Car,
  AEV_Logistic_Car,
  Quotation_Table,
  Quotation_AEV,
  Table_Quotation_Admin,
  Table_Route_Cuteplus,
  AEV_Route_Cuteplus,
  Pick_Product_Table,
  Pick_Product_AEV,
  Pick_Product_Admin_Table,
  FG_In_Out,
  Wait_delivery_cuteplus,
  Wait_delivery_EV,
  Table_Knowledge_Management,
  AEV_Knowledge_Management,
  Invoice_Table,
  Purchase_order_Table,
  Purchase_order_AEV,
  Table_Purchase_Order_Admin,
  Logistic_Car_Table,
  Product_CN_Table,
  Receive_PO_Table,
  Logistic_Planning_Table,
  Invoice_EV,
  Product_CN_EV,
  Logistic_Car_AEV,
  Handheld_Cuteplus,
  Price_List_Table,
  Price_List_AEV,
  Permission_Ticket,
  SLA_Level,
  Test_Entry,
  All_Project,
  File_Upload,
  Dashboard_Ticket,
  Warehouse3D,
  Dashboard_Power_Monitor,
  All_Ticket,
  Ticket_Detail,
  History,
  Connect,
  viewMonitor,
  editMonitor,
  addMonitor,
  NonPermission,
  WarehouseMaster,
  WarehouseAVE,
  Warehouse3D_Main,
  MasterUnit,
  FG_IO_Location_Table,
  FG_IO_Location_AEV,
  FG_UNAI_Location_Table,
  FG_UNAI_Location_AEV,
  Config_AIOI_Table,
  Config_AIOI_AEV,
  SystemConfig,
  ImportDeliveryOrder,
  Prepare_Product_Table,
  ConfigUnai,
  Config_UNAI_AEV,
  PPUNAI_Table,
  ImportDelivery_Unai,
  Report_Ticket,
  Customer_Management,
  Manage_documents,
  Manage_documents_add,
  ManageMessageType,
  ManageMessageTypeAdd,
  SaveMessage,
  HrLog,
  Booking_History,
  Booking_AEV,
  Reserve,
  DashboardLog,
  HrLogAdd,
  Project_Table,
  Project_AEV,
  Cost_Center_Table,
  Cost_Center_AEV,
  Expense_Topic_Table,
  Expense_Topic_AEV,
  Withholding_Tax_Account_AEV,
  Withholding_Tax_Account_Table,
  Expenses_Approve_Table,
  Organization_Expenses_Table,
  Organization_Expenses_AEV,
  Emp_Expenses_Table,
  Emp_Expenses_AEV,
  Accounting_Dashboard,
  Get_Global_Config,
  Ocr_Feature,
  Favorite_Table,
  CountingFeature,
  Sign,
  Employee_Help,
  Dashboard_Booking,
  Task_Work_Animate,
  Fine_Tuning,
  Counting_FineTuning,
  Report_Accountig,
} from "../../view2";

const Homepage = () => {
  const location = useLocation();
  /*   var test = getCurrentPath(); */
  useEffect(() => {
    sessionStorage.setItem("timeline", 0);
    setCurrentPath(location.pathname);
    //let status = 0;

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keydown",
    ];

    // var fiveMinutes = 60 * 15;

    // var myInterval = setInterval(myTimer, 1000);
    // //countdown(fiveMinutes);
    // var timer = fiveMinutes,
    //   minutes,
    //   seconds;
    // function myTimer() {
    //   minutes = parseInt(timer / 60, 10);
    //   seconds = parseInt(timer % 60, 10);

    //   minutes = minutes < 10 ? "0" + minutes : minutes;
    //   seconds = seconds < 10 ? "0" + seconds : seconds;
    //   // console.log(timer)
    //   if (timer === 60) {
    //     //console.log("timer")
    //     Swal.fire({
    //       position: "top",
    //       allowOutsideClick: false,
    //       icon: "info",
    //       title: "คุณไม่ได้ทำรายการใดๆ เป็นเวลา 15 นาที",
    //       text: "หากไม่ทำรายการใดๆต่อ \n จะออกจากระบบอัตโนมัติภายใน 1 นาที",
    //       showConfirmButton: true,
    //     }).then((result) => {});
    //   }
    //   if (--timer < 0) {
    //     removeUserSession();
    //     removeOem();
    //     sessionStorage.removeItem("setupTime");
    //     sessionStorage.removeItem("feature");
    //     window.sessionStorage.removeItem("Noti");
    //     sessionStorage.removeItem("com_config");
    //     window.location.href = "/login";
    //   } else {
    //     //console.log( minutes + ":" + seconds)

    //     sessionStorage.setItem("setupTime", minutes + ":" + seconds);
    //   }
    // }
    // for (var e = 0; e < events.length; e++) {
    //   //console.log(events);
    //   document.addEventListener(
    //     events[e],
    //     (event) => {
    //       clearInterval(myInterval);
    //       timer = 60 * 15;
    //       myInterval = setInterval(myTimer, 1000);
    //     },
    //     false
    //   );
    // }

    let myTimeout = setTimeout(myTimer, 60 * 15 * 1000);

    function myTimer() {
      clearTimeout(myTimeout);
      myTimeout = setTimeout(myTimerOut, 60 * 1 * 1000);
      Swal.fire({
        position: "top",
        allowOutsideClick: false,
        icon: "info",
        title: "คุณไม่ได้ทำรายการใดๆ เป็นเวลา 15 นาที",
        text: "หากไม่ทำรายการใดๆต่อ \n จะออกจากระบบอัตโนมัติภายใน 1 นาที",
        showConfirmButton: true,
      });
    }

    function myTimerOut() {
      clearTimeout(myTimeout);
      removeUserSession();
      removeOem();
      sessionStorage.removeItem("setupTime");
      sessionStorage.removeItem("feature");
      window.sessionStorage.removeItem("Noti");
      window.sessionStorage.removeItem("Noti_ticket");
      sessionStorage.removeItem("com_config");
      window.location.href = "/login";
    }

    for (var e = 0; e < events.length; e++) {
      //console.log(events);
      document.addEventListener(
        events[e],
        (event) => {
          clearTimeout(myTimeout);
          myTimeout = setTimeout(myTimer, 60 * 15 * 1000);
        },
        false
      );
    }
  }, []);

  useEffect(() => {
    const sidebar = document.querySelector(".main-sidebar");
    const contentWrapper = document.querySelector(".content-wrapper");

    if (!sidebar || !contentWrapper) return;

    const resizeObserver = new ResizeObserver(() => {
      const sidebarHeight = sidebar.offsetHeight - 87;
      if (sidebarHeight > contentWrapper.offsetHeight) {
        contentWrapper.style.minHeight = `${sidebarHeight}px`;
      }
    });

    // Start observing the sidebar's height changes
    resizeObserver.observe(sidebar);

    // Cleanup the observer on component unmount
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/task_work/animate`}
        component={() => <Task_Work_Animate />}
      />

      <div className="wrapper">
        <Headers />
        <Sidebar />

        <Switch>
          {/* <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/> */}

          <Redirect
            exact
            from={`${process.env.PUBLIC_URL}/`}
            to={`${process.env.PUBLIC_URL}/Welcome`}
          />

          {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Langingview} /> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Welcome`}
            component={Langingview}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dashbord`}
            component={dashboard}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Quanlity_Control/Log_Monitor`}
            component={LogMonitory}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Quanlity_Control/Model_Traning`}
            component={ModeTranning}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Quanlity_Control/AI`}
            component={QIAI}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Quanlity_Control/Manual`}
            component={QCM}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Materail_Consumtion`}
            component={MaterailCon}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Delivery_Record`}
            component={DeleveryRecord}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Product`}
            component={Product}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Product/add/:target`}
            component={() => <Product_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Product/edit/:id/:target/:assy`}
            component={() => <Product_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Product/read/:id/:target/:assy`}
            component={() => <Product_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Raw_Materail`}
            component={Materail}
          />
          {/*  <PrivateRoute exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Production_Line_Management`} component={ProdLineMGT}/> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Product_Grouping`}
            component={Product_Grouping}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Production_Line`}
            component={Production_Line}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Raw_Materail/add`}
            component={() => <Materail_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Raw_Materail/read/:id`}
            component={() => <Materail_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Raw_Materail/edit/:id`}
            component={() => <Materail_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Supplier`}
            component={Supplier}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Supplier/add`}
            component={() => <Supplier_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Supplier/read/:id`}
            component={() => <Supplier_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Supplier/edit/:id`}
            component={() => <Supplier_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Project`}
            component={Project}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Project/add`}
            component={() => <Project_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Project/read/:id`}
            component={() => <Project_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Project/edit/:id`}
            component={() => <Project_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Logistic`}
            component={Logistic}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Logistic/add`}
            component={() => <Logistic_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Logistic/read/:id`}
            component={() => <Logistic_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Logistic/edit/:id`}
            component={() => <Logistic_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter`}
            component={Factory_parameter}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/:target_u`}
            component={Factory_parameter}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/add_section`}
            component={() => <Factory_parameter_section_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/section/read/:id`}
            component={() => <Factory_parameter_section_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/section/edit/:id`}
            component={() => <Factory_parameter_section_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/add_process`}
            component={() => <Factory_parameter_process_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/process/read/:id`}
            component={() => <Factory_parameter_process_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/process/edit/:id`}
            component={() => <Factory_parameter_process_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/add_line`}
            component={() => <Factory_parameter_line_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/line/read/:id`}
            component={() => <Factory_parameter_line_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/line/edit/:id`}
            component={() => <Factory_parameter_line_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/add_machine`}
            component={() => <Factory_parameter_machine_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/machine/read/:id`}
            component={() => <Factory_parameter_machine_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/machine/edit/:id`}
            component={() => <Factory_parameter_machine_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Work_Hours`}
            component={Factory_work_hours}
          />
          {/* <PrivateRoute exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Logistic`} component={Logistic}/> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/End_user`}
            component={End_user}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/End_user/add`}
            component={() => <End_user_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/End_user/read/:id`}
            component={() => <End_user_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/End_user/edit/:id`}
            component={() => <End_user_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Customer`}
            component={Customer}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Customer/add`}
            component={() => <Customer_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Customer/read/:id`}
            component={() => <Customer_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Customer/edit/:id`}
            component={() => <Customer_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Logistic_IN_OUT`}
            component={LogisticInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Logistic_IN_OUT/edit/:id`}
            component={() => <LogisticInOut_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/End_user_IN_OUT`}
            component={End_userInOut}
          />

          {/* <PrivateRoute exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/edit/:id`}  component={()=><Factory_parameter_add mode="edit"/>}/>
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Parameter/read/:id`}  component={()=><Factory_parameter_add mode="read"/>}/> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/F_G_In_Out`}
            component={FGInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WIP_IN_OUT`}
            component={WIPInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Raw_Mat_In_Out`}
            component={RawMatInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/CuttingInOut`}
            component={CuttingInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Warehouse_History`}
            component={Warehouse_history}
          />
          {/*  <Route exact path={`${process.env.PUBLIC_URL}/Material_Requirement_Planning/Approve_Raw_Material_for_PO`} component={MateForPO}/> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Material_Requirement_Planning/Approve_Raw_Material_for_PO`}
            component={MateForPO}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Material_Requirement_Planning/Approve_Material_item`}
            component={Mat_Approve}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Approve_Production_Item`}
            component={Product_Approve}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Order_Production`}
            component={OrderProduct}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Upload_Order_Forecast`}
            component={Uploadorderforecast}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Order_Summary_Report`}
            component={OrderSummaryReport}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Order_Summary_Report/add`}
            component={() => <OrderSummaryReport_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Order_Summary_Report/read/:id`}
            component={() => <OrderSummaryReport_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Production_Planing/Order_Summary_Report/edit/:id`}
            component={() => <OrderSummaryReport_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Delivery_Order_Paper`}
            component={Delivery_order_paper}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Invoice`}
            component={Invoice}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Loop`}
            component={Loop}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Loop/add`}
            component={() => <Loop_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Loop/read/:id`}
            component={() => <Loop_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Loop/edit/:id`}
            component={() => <Loop_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse_Type`}
            component={Warehouse_type}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse_Type/add`}
            component={() => <Warehouse_type_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse_Type/read/:id`}
            component={() => <Warehouse_type_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse_Type/edit/:id`}
            component={() => <Warehouse_type_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Load_Status`}
            component={Load_status}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Load_Status/add`}
            component={() => <Load_status_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Load_Status/read/:id`}
            component={() => <Load_status_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Load_Status/edit/:id`}
            component={() => <Load_status_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/External_Data`}
            component={External_Data}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Spare_Part`}
            component={Spare_part}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Spare_Part/add`}
            component={() => <Spare_part_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Spare_Part/read/:id`}
            component={() => <Spare_part_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Spare_Part/edit/:id`}
            component={() => <Spare_part_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse`}
            component={Warehouse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/status/:target_u`}
            component={Warehouse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/add`}
            component={() => <Warehouse_add_warehouse mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/read/:id`}
            component={() => <Warehouse_add_warehouse mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/edit/:id`}
            component={() => <Warehouse_add_warehouse mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/add_rack`}
            component={() => <Warehouse_add_rack mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/read_rack/:id`}
            component={() => <Warehouse_add_rack mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/edit_rack/:id`}
            component={() => <Warehouse_add_rack mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/add_rack_floor`}
            component={() => <Warehouse_add_rack_floor mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/rack_floor/read/:id&:parent_id&:old_parent_id`}
            component={() => <Warehouse_add_rack_floor mode="view" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Warehouse/rack_floor/edit/:id&:parent_id&:old_parent_id`}
            component={() => <Warehouse_add_rack_floor mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Spare_Part_IN_OUT`}
            component={Spare_Part_IN_OUT}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Material_Requirement_Planning/Order_Raw_Material`}
            component={Order_Raw_Material}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/CHOUP_INT_OUT`}
            component={CHOUPInOut}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Handheld`}
            component={Handheld}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Machine_And_FG`}
            component={Machine_FG}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Profile`}
            component={Profile}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_type`}
            component={Mat_type}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_type/add`}
            component={() => <Mat_type_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_type/read/:id`}
            component={() => <Mat_type_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_type/edit/:id`}
            component={() => <Mat_type_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_spec`}
            component={Mat_spec}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_spec/add`}
            component={() => <Mat_spec_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_spec/read/:id`}
            component={() => <Mat_spec_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_spec/edit/:id`}
            component={() => <Mat_spec_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_size`}
            component={Mat_size}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_size/add`}
            component={() => <Mat_size_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_size/read/:id`}
            component={() => <Mat_size_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Mat_size/edit/:id`}
            component={() => <Mat_size_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Model_category`}
            component={Model_category}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Model_category/add`}
            component={() => <Model_category_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Model_category/read/:id`}
            component={() => <Model_category_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Model_category/edit/:id`}
            component={() => <Model_category_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Report_pdf`}
            component={Report_pdf}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Record_oil`}
            component={Record_oil}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Record_oil/add`}
            component={() => <Record_oil_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Record_oil/read/:id`}
            component={() => <Record_oil_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Record_oil/edit/:id`}
            component={() => <Record_oil_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Promotion`}
            component={Promotion}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Promotion/add`}
            component={() => <Promotion_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Promotion/read/:id`}
            component={() => <Promotion_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Promotion/edit/:id`}
            component={() => <Promotion_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tow_truck`}
            component={Tow_truck}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tow_truck/add`}
            component={() => <Tow_truck_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tow_truck/read/:id`}
            component={() => <Tow_truck_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tow_truck/edit/:id`}
            component={() => <Tow_truck_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Driver`}
            component={Driver}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Driver_person`}
            component={Driver_person}
          />
          {/*   <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Driver/add`} component={()=><Driver_add mode="add"/>}/>
      <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Driver/read/:id`} component={()=><Driver_add mode="read"/>}/>
      <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Driver/edit/:id`} component={()=><Driver_add mode="edit"/>}/> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WIP_SSTInOut`}
            component={WIP_SSTInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WIP_SSTInOut/add`}
            component={() => <WIP_SSTInOut_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WIP_SSTInOut/read/:id`}
            component={() => <WIP_SSTInOut_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WIP_SSTInOut/edit/:id`}
            component={() => <WIP_SSTInOut_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Mat_stc`}
            component={Mat_stc}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Mat_stc/add`}
            component={() => <Mat_stc_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Mat_stc/read/:id/:date`}
            component={() => <Mat_stc_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Mat_stc/edit/:id/:date`}
            component={() => <Mat_stc_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Data_express`}
            component={Data_express}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery`}
            component={wait_delivery}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery/add`}
            component={() => <Wait_delivery_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery/read/:id`}
            component={() => <Wait_delivery_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery/edit/:id`}
            component={() => <Wait_delivery_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Delivery_Time_Record`}
            component={Delivery_Time_Record}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Fuel_Consumtion`}
            component={Fuel_Consumtion}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/MachineInOut`}
            component={MachineInOut}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Work_Hours_With_OEM`}
            component={Factory_work_hoursWithOem}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/RawMatSTP_InOut`}
            component={RawMatSTPInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Deflux_and_WIP_IN_OUT`}
            component={DefluxWipInOut}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/FG_STP_IN_OUT`}
            component={FG_STPInOut}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse`}
            component={Wire_Ready_toUse}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/add`}
            component={() => <Wire_Ready_toUse_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/read/:id`}
            component={() => <Wire_Ready_toUse_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/edit/:id`}
            component={() => <Wire_Ready_toUse_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Raw_Mat_In_Out_CTS`}
            component={MatInOutCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Raw_Mat_In_Out_CTS/mgt/:material_id`}
            component={() => <MatInOutCTS_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/External_Data_CTS`}
            component={External_Data_CTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Report_PDF_CTS`}
            component={Report_PDF_CTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/SN_management`}
            component={SN_management}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/Dashboard_HR`}
            component={Dashboard_HR}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee`}
            component={Employee_Info}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/add`}
            component={() => <Employee_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/read/:id`}
            component={() => <Employee_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/edit/:id`}
            component={() => <Employee_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/reade/:id`}
            component={() => <Info_HR mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/edite/:id`}
            component={() => <Info_HR mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/ข้อมูลการปฎิบัติงาน`}
            component={Operational_data}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/add`}
            component={() => <System_Configuraion_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/read/:id`}
            component={() => <System_Configuraion_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/edit/:id`}
            component={() => <System_Configuraion_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/System_Parameter`}
            component={System_Parameter}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/pad_employee`}
            component={Pad_Employee}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Pad_Connection`}
            component={Pad_Connection}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Pad_Connection/add`}
            component={() => <Pad_Connection_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Pad_Connection/read/:id`}
            component={() => <Pad_Connection_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Pad_Connection/edit/:id`}
            component={() => <Pad_Connection_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data`}
            component={Company_time_attendance}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/สรุปวันทำงาน`}
            component={Work_day_summary}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/Warehouse_History_CTS`}
            component={Warehouse_History_CTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/ใบสั่งผลิต`}
            component={Production_order_CTS}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/add`}
            component={() => <Production_order_CTS_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/read/:id`}
            component={() => <Production_order_CTS_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/edit/:id`}
            component={() => <Production_order_CTS_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information`}
            component={Leave_information}
          />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/add`} component={()=><Leave_information_add mode="add"/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/read/:id`} component={()=><Leave_information_add mode="read"/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/edit/:id`} component={()=><Leave_information_add mode="edit"/>}/> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Planing`}
            component={PlanningCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Choup`}
            component={FactoryChoup}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Choup/add`}
            component={() => <FactoryChoup_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Choup/read/:id`}
            component={() => <FactoryChoup_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Factory_Choup/edit/:id`}
            component={() => <FactoryChoup_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/workingkaday/time_working_group`}
            component={Time_working_person}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wip_prod_in_out`}
            component={WipProdInOutCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wip_prod_in_out/mgt/:product_id`}
            component={() => <WipProdInOutCTS_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wip_shup_out_in`}
            component={WipShupOutInCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WipShupOutInCTS/mgt/:product_id`}
            component={() => <WipShupOutInCTS_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/HandheldCTS`}
            component={HandheldCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Pack_In_OUt`}
            component={WipPackInOUtCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Pack_In_OUt/mgt/:product_id`}
            component={() => <WipPackInOUtCTS_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/F_G_In_Out_CTS`}
            component={FGInOutCTS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/F_G_In_Out_CTS/mgt/:product_id`}
            component={() => <FGInOutCTS_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information_admin`}
            component={Leave_information_admin}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information_admin/mgt/:emp_id`}
            component={() => <Leave_information_admin_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/ข้อมูลวันลาคงเหลือ`}
            component={Leave_information_remaining}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/ข้อมูลวันลาคงเหลือ/mgt/:emp_id`}
            component={() => <Leave_information_remaining_mgt mode="mgt" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/add`}
            component={() => <Leave_information_mgt mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/read/:leave_id`}
            component={() => <Leave_information_mgt mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/edit/:leave_id`}
            component={() => <Leave_information_mgt mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/TEST_LOGIC`}
            component={TestLogic}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Quanlity_Control`}
            component={Langingview}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History`}
            component={Langingview}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management`}
            component={Langingview}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Material_Requirement_Planning`}
            component={Langingview}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource`}
            component={Langingview}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement`}
            component={Langingview}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/settingpermissionsla`}
            component={Langingview}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai_feature`}
            component={Langingview}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/employee_train/add`}
            component={() => <Employee_train mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/employee_train/edit/:id`}
            component={() => <Employee_train mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/employee_train/read/:id`}
            component={() => <Employee_train mode="read" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history`}
            component={() => <Info mode="read" />}
          />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history/edit/:id`} component={() => <Info mode="edit" />} /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history/read/:id`}
            component={() => <Info mode="read" />}
          />

          {/* <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history/adde/:id`} component={() => <Info mode="add" url="/Human_Resource/ข้อมูลพนักงาน/Employee" />} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history/edite/:id`} component={() => <Info mode="edit" url="/Human_Resource/ข้อมูลพนักงาน/Employee" />} /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/employee_history/reade/:id`}
            component={() => (
              <Info mode="read" url="/Human_Resource/ข้อมูลพนักงาน/Employee" />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work`}
            component={Assign_Work}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/add/:user_id?`}
            component={() => (
              <Assign_Work_Add
                mode="add"
                url="/Human_Resource/to_do_list/assign_work"
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/edit/:task_id`}
            component={() => (
              <Assign_Work_Add
                mode="edit"
                url="/Human_Resource/to_do_list/assign_work"
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/read/:task_id`}
            component={() => (
              <Assign_Work_Add
                mode="read"
                url="/Human_Resource/to_do_list/assign_work"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/addt/:week/:year/:user_id?`}
            component={() => (
              <Assign_Work_Add
                mode="addt"
                url="/Human_Resource/to_do_list/task_work"
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/editt/:task_id/:week/:year`}
            component={() => (
              <Assign_Work_Add
                mode="editt"
                url="/Human_Resource/to_do_list/task_work"
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/readt/:task_id/:week/:year`}
            component={() => (
              <Assign_Work_Add
                mode="readt"
                url="/Human_Resource/to_do_list/task_work"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/assign_work/readt_animate/:task_id/:week/:year`}
            component={() => (
              <Assign_Work_Add
                mode="readt"
                url="/Human_Resource/to_do_list/task_work/animate"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/task_work`}
            component={Task_Work}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/task_work/:week/:year`}
            component={Task_Work}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/employee_help/:week/:year`}
            component={Employee_Help}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_team`}
            component={Manage_team}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_team/read/:id`}
            component={() => <Manage_team_add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_team/add`}
            component={() => <Manage_team_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_team/edit/:id`}
            component={() => <Manage_team_add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information_approve`}
            component={Leave_information_approve}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/HRD/External_dev_train`}
            component={External_dev_train}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_company`}
            component={Manage_company}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/organization/manage_position`}
            component={Manag_Position}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WINSpeed_PO`}
            component={WINSpeed_PO}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WinSpeedHandHeld`}
            component={WinSpeedHandHeld}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/WinSpeedHistory`}
            component={WinSpeedHistory}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ค่าเที่ยว`}
            component={Travel_expenses}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ค่าเที่ยว/:start_date/:end_date`}
            component={Travel_expenses}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ค่าเที่ยว/view/:id/:start_date/:end_date`}
            component={() => <Travel_expenses_AEV mode="view" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ค่าเที่ยว/edit/:id/:start_date/:end_date`}
            component={() => <Travel_expenses_AEV mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tooling`}
            component={Tooling}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_IN_OUT`}
            component={ToolingInOut}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tooling/add`}
            component={() => <Tooling_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tooling/edit/:id`}
            component={() => <Tooling_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Tooling/read/:id`}
            component={() => <Tooling_add mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_Inquiry`}
            component={() => <Tooling_inquiry />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_Inquiry/add`}
            component={() => <Tooling_inquiry_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_Inquiry/edit/:id`}
            component={() => <Tooling_inquiry_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_Inquiry/read/:id`}
            component={() => <Tooling_inquiry_add mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/Dashboard_HR_TTT`}
            component={Dashboard_HR_TTT}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_PO`}
            component={() => <Tooling_po />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_PO/generatePO/add`}
            component={() => <Generate_po mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_PO/generatePO/edit/:id`}
            component={() => <Generate_po mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_PO/generatePO/read/:id`}
            component={() => <Generate_po mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Tooling_Receive`}
            component={() => <Tooling_po_receive />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/NewEmployee`}
            component={NewEmployee}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/ข้อมูลพนักงาน/Employee/NewEmployee/view/:id`}
            component={() => <Info_public_view mode="read" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_category_roduct`}
            component={() => <ManageProductCategoryTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_category_product_add`}
            component={() => <ManageProductCategoryAdd mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_category_product/read/:id`}
            component={() => <ManageProductCategoryAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_category_product/edit/:id`}
            component={() => <ManageProductCategoryAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/award_point`}
            component={() => <AwardPointTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/award_point_add`}
            component={() => <Award_Point_Add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/award_point/read/:id`}
            component={() => <Award_Point_Add mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/award_point/edit/:id`}
            component={() => <Award_Point_Add mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_product_system`}
            component={() => <ManageProductTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_product_system/add`}
            component={() => <ManageProductAdd mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_product_system/read/:id`}
            component={() => <ManageProductAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/manage_product_system/edit/:id`}
            component={() => <ManageProductAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Manage_Supplier`}
            component={() => <ManageSupplierTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Manage_Supplier/add`}
            component={() => <ManageSupplierAdd mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Manage_Supplier/read/:id`}
            component={() => <ManageSupplierAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Manage_Supplier/edit/:id`}
            component={() => <ManageSupplierAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/Give_Point`}
            component={() => <GivePointAdd />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/manage_group`}
            component={Manage_grouptable}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/Manage_groupAdd`}
            component={() => <Manage_groupAdd mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/Manage_groupAdd/read/:id`}
            component={() => <Manage_groupAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/Manage_groupAdd/edit/:id`}
            component={() => <Manage_groupAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/company_budget`}
            component={() => <Company_Budget />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service`}
            component={() => <All_Product_Service />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/point_history`}
            component={() => <PointHistory />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/thank_point`}
            component={() => <ThankPointTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/thank_point/add`}
            component={() => <ThankPointAdd mode="add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/thank_point/read/:id`}
            component={() => <ThankPointAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/thank_point/edit/:id`}
            component={() => <ThankPointAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/news`}
            component={() => <NewsTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/news_add`}
            component={() => <NewsAdd mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/news_add/read/:id`}
            component={() => <NewsAdd mode="read" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/news_add/edit/:id`}
            component={() => <NewsAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/manage_group_product`}
            component={() => <ManageGroupProductTable />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/manage_group_product_read/:id`}
            component={() => <ManageGroupProductRead />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/product_view_details/:id`}
            component={() => <Product_View_Details />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/category/:id`}
            component={() => <Product_Service_Category mode="category" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/category1`}
            component={() => <Product_Service_Category mode="flashsale" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/category2`}
            component={() => <Product_Service_Category mode="new" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/category3`}
            component={() => <Product_Service_Category mode="hit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/award_point`}
            component={() => <IncentiveUserAwardpoint />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/award_point/view/:id`}
            component={() => <IncentiveUserAwardpoint_View />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/thank_point`}
            component={() => <IncentiveUserThankpoint />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/pay_details`}
            component={Pay_product_detail_buyer}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/confirm_product`}
            component={Confirm_Product}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/cart_product`}
            component={() => <Cart_Product_Sum />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/product_service/cart_product/:id`}
            component={() => <Cart_Product_Sum />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/point_history`}
            component={PointHistoryEmp}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/report_system`}
            component={() => <ReportSystem />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/summary_report`}
            component={() => <ReportSystemAddmin />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/redeem_point_history`}
            component={() => <RedeemPointHistoryEmp />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_hr_admin/summary_point`}
            component={() => <SummaryPoint />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Order_Inquiry`}
            component={() => <OrderInquiry />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Order_Inquiry/order_inquiry_add`}
            component={() => <OrderInquiry_Add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Order_Inquiry/order_inquiry_view/:id`}
            component={() => <OrderInquiry_EV mode="view" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/Order_Inquiry/order_inquiry_edit/:id`}
            component={() => <OrderInquiry_EV mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/perchase_order`}
            component={() => <Perchase_order />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/incentive_user/tracking_status`}
            component={() => <Tracking_Status />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/perchase_order/generate_po`}
            component={() => <Generate_PO mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/perchase_order/generate_po/edit/:id`}
            component={() => <Generate_PO_EV mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/perchase_order/generate_po/read/:id`}
            component={() => <Generate_PO_EV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/intensive_TTT/tracking_status`}
            component={() => <Tracking_Status_TTT />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus`}
            component={() => <Table_Supplier_Cuteplus />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/add`}
            component={() => <AEV_Supplier_Cuteplus mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/edit/:id`}
            component={() => <AEV_Supplier_Cuteplus mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/view/:id`}
            component={() => <AEV_Supplier_Cuteplus mode="view" />}
          />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/Supplier_Cuteplus`} component={() => <Table_Supplier_Cuteplus />} /> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus`}
            component={() => <Table_Customer_CutePlus />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus`}
            component={() => <Table_Product_Cuteplus />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category`}
            component={() => <Table_Product_Category modePage="lv1" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv2`}
            component={() => <Table_Product_Category modePage="lv2" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv3`}
            component={() => <Table_Product_Category modePage="lv3" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv4`}
            component={() => <Table_Product_Category modePage="lv4" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/add`}
            component={() => <Category_AEV mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/edit/:id`}
            component={() => <Category_AEV mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/view/:id`}
            component={() => <Category_AEV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/add`}
            component={() => <AEV_Customer_Cuteplus mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/edit/:id`}
            component={() => <AEV_Customer_Cuteplus mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/view/:id`}
            component={() => <AEV_Customer_Cuteplus mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/add`}
            component={() => <AEV_Certificate mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/edit/:id`}
            component={() => <AEV_Certificate mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/view/:id`}
            component={() => <AEV_Certificate mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/add`}
            component={() => <AEV_License mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/edit/:id`}
            component={() => <AEV_License mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/view/:id`}
            component={() => <AEV_License mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license`}
            component={() => <Table_Certificate_license />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license`}
            component={() => <Table_Certificate_license from="license" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/add`}
            component={() => <AEV_Product mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/edit/:id`}
            component={() => <AEV_Product mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/view/:id`}
            component={() => <AEV_Product mode="view" />}
          />

          {/* <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store`} component={() => <Table_Distribution_Channel/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/add`} component={() => <AEV_Distribution mode="add"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/edit/:id`} component={() => <AEV_Distribution mode="edit"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/view/:id`} component={() => <AEV_Distribution mode="view"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store/channel`} component={() => <Table_Distribution_Channel from="channel"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/channel/add`} component={() => <AEV_Distribution from="channel" mode="add"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/channel/edit/:id`} component={() => <AEV_Distribution from="channel" mode="edit"/>} />
        <Route exact path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/distribution_channel/channel/view/:id`} component={() => <AEV_Distribution from="channel" mode="view"/>} /> */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus`}
            component={() => <Product_Kitting_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus/add`}
            component={() => <AEV_Product_Kitting mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_kitting/edit/:id`}
            component={() => <AEV_Product_Kitting mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/product_kitting/view/:id`}
            component={() => <AEV_Product_Kitting mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Sales_Order_SO_Cuteplus`}
            component={() => <Table_Sales_Order />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Sales_Order_SO_Cuteplus/add`}
            component={() => <AEV_Sales_Order mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Sales_Order_SO_Cuteplus/edit/:id`}
            component={() => <AEV_Sales_Order mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Sales_Order_SO_Cuteplus/view/:id`}
            component={() => <AEV_Sales_Order mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_supplier`}
            component={() => <Tooling_supplier />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_supplier/add`}
            component={() => <Tooling_supplier_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_supplier/edit/:id`}
            component={() => <Tooling_supplier_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_supplier/read/:id`}
            component={() => <Tooling_supplier_add mode="read" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_brand`}
            component={() => <Tooling_brand />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_brand/add`}
            component={() => <Tooling_brand_add mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_brand/edit/:id`}
            component={() => <Tooling_brand_add mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data/tooling_brand/view/:id`}
            component={() => <Tooling_brand_add mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus`}
            component={() => <Table_Logistic_Car />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/add`}
            component={() => <AEV_Logistic_Car mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/edit/:id`}
            component={() => <AEV_Logistic_Car mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/view/:id`}
            component={() => <AEV_Logistic_Car mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Sales_Cuteplut`}
            component={() => <Quotation_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_sale`}
            component={() => <Pick_Product_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_sale/add`}
            component={() => <Pick_Product_AEV mode="add" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_sale/edit/:id`}
            component={() => <Pick_Product_AEV mode="edit" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_sale/view/:id`}
            component={() => <Pick_Product_AEV mode="view" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_sale/ViewHistory/:id`}
            component={() => <Pick_Product_AEV mode="view" isHistory={true} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/global_config`}
            component={() => <Get_Global_Config />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/global_config/2`}
            component={() => <Get_Global_Config tabOn={"2"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai_feature/ocr`}
            component={() => <Ocr_Feature />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/Add`}
            component={() => <Quotation_AEV mode="add" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/Edit/:id`}
            component={() => <Quotation_AEV mode="edit" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/View/:id`}
            component={() => <Quotation_AEV mode="view" isHistory={false} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/ViewHistory/:id`}
            component={() => <Quotation_AEV mode="view" isHistory={true} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Quotation_Admin_Cuteplus`}
            component={() => <Table_Quotation_Admin />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Route`}
            component={Table_Route_Cuteplus}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/add`}
            component={() => <AEV_Route_Cuteplus mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/edit/:id`}
            component={() => <AEV_Route_Cuteplus mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/view/:id`}
            component={() => <AEV_Route_Cuteplus mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/เบิกสินค้า_admin`}
            component={() => <Pick_Product_Admin_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/FG_IN_OUT_Cuteplus`}
            component={() => <FG_In_Out />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery_cuteplus`}
            component={() => <Wait_delivery_cuteplus />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery_cuteplus/Edit/:id`}
            component={() => <Wait_delivery_EV mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/wait_delivery_cuteplus/View/:id`}
            component={() => <Wait_delivery_EV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Knowledge_management_Cuteplus`}
            component={() => <Table_Knowledge_Management />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Knowledge_management_Cuteplus/add`}
            component={() => <AEV_Knowledge_Management mode="add" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Knowledge_management_Cuteplus/Edit/:id`}
            component={() => <AEV_Knowledge_Management mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Knowledge_management_Cuteplus/View/:id`}
            component={() => <AEV_Knowledge_Management mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Invoice_Cuteplus`}
            component={() => <Invoice_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Invoice_Cuteplus/Edit/:id`}
            component={() => <Invoice_EV mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Invoice_Cuteplus/View/:id`}
            component={() => <Invoice_EV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/purchase_order_cuteplus`}
            component={() => <Purchase_order_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/purchase_order_cuteplus/Add`}
            component={() => (
              <Purchase_order_AEV mode="add" isHistory={false} />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/purchase_order_cuteplus/Edit/:id`}
            component={() => (
              <Purchase_order_AEV mode="edit" isHistory={false} />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/purchase_order_cuteplus/View/:id`}
            component={() => (
              <Purchase_order_AEV mode="view" isHistory={false} />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/purchase_order_cuteplus/ViewHistory/:id`}
            component={() => (
              <Purchase_order_AEV mode="view" isHistory={true} />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Purchase_Order_Admin`}
            component={() => <Table_Purchase_Order_Admin />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus`}
            component={() => <Logistic_Car_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus/Edit/:id`}
            component={() => <Logistic_Car_AEV mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Product_CN`}
            component={() => <Product_CN_Table />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Product_CN/Edit/:id`}
            component={() => <Product_CN_EV mode="edit" />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Product_CN/View/:id`}
            component={() => <Product_CN_EV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Purchase_Receive_Cuteplus`}
            component={() => <Receive_PO_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Logistic_Planning_Cuteplus`}
            component={() => <Logistic_Planning_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Warehouse_Management/Handheld_Cuteplus`}
            component={() => <Handheld_Cuteplus />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Price_list`}
            component={() => <Price_List_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Price_list/Add`}
            component={() => <Price_List_AEV mode="add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Price_list/Edit/:id`}
            component={() => <Price_List_AEV mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Sale_cuteplut/Price_list/View/:id`}
            component={() => <Price_List_AEV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/settingpermissionsla/permission`}
            component={() => <Permission_Ticket />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/settingpermissionsla/slalevel`}
            component={() => <SLA_Level />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/allticket`}
            // component={() => <Test_Entry />}
            component={(props) => (
              <All_Ticket project_id={null} is_all={true} />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/allproject`}
            component={() => <All_Project />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/report`}
            component={() => <Report_Ticket />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/fileuploade`}
            component={() => <File_Upload />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dashboardticket`}
            component={() => <Dashboard_Ticket />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/Warehouse3D`}
            component={Warehouse3D}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/Warehouse3D_Unai`}
            component={() => <Warehouse3D_Main modelType={"unai"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/Warehouse3D_AIOI`}
            component={() => <Warehouse3D_Main modelType={"aioi"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/Dashboard_Power_Monitor`}
            component={Dashboard_Power_Monitor}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/PowerMonitorConnection`}
            component={Connect}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/PowerMonitorConnection/addMonitor`}
            component={addMonitor}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/PowerMonitorConnection/viewMonitor`}
            component={viewMonitor}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/PowerMonitorConnection/editMonitor`}
            component={editMonitor}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Warehouse`}
            component={WarehouseMaster}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Warehouse/Add`}
            component={() => <WarehouseAVE mode={"Add"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Warehouse/Edit/:id`}
            component={() => <WarehouseAVE mode={"Edit"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Warehouse/View/:id`}
            component={() => <WarehouseAVE mode={"View"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/TicketAll/:project_id`}
            component={(props) => (
              <All_Ticket project_id={props.match.params.project_id} />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/allticket`}
            component={() => <Test_Entry />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/Ticket/Edit/:id`}
            component={() => <Ticket_Detail mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/Ticket/View/:id`}
            render={(routeProps) => (
              <Ticket_Detail mode="view" id={routeProps.match.params.id} />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Statistic_n_History/HistoryPowerMonitor`}
            component={History}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/NonPermission`}
            component={() => <NonPermission />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Master_Unit`}
            component={() => <MasterUnit />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/FG_InOut_With_Location_aioi`}
            component={() => <FG_IO_Location_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/FG_InOut_With_Location_aioi/In_Out`}
            component={() => <FG_IO_Location_AEV />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/FG_InOut_With_Location_Unai`}
            component={() => <FG_UNAI_Location_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/FG_InOut_With_Location_Unai/In_Out`}
            component={() => <FG_UNAI_Location_AEV />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_AIOI_Table`}
            component={() => <Config_AIOI_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_AIOI_Add`}
            component={() => <Config_AIOI_AEV mode={"Add"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_AIOI_Edit/:id`}
            component={() => <Config_AIOI_AEV mode={"Edit"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_AIOI_View/:id`}
            component={() => <Config_AIOI_AEV mode={"View"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/SystemConfiguration`}
            component={() => <SystemConfig />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/ImportDeliveryOrder`}
            component={() => <ImportDeliveryOrder />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/PrepareProduct_aioi`}
            component={() => <Prepare_Product_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_Unai`}
            component={() => <ConfigUnai />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_Unai_Add`}
            component={() => <Config_UNAI_AEV mode={"Add"} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_Unai_View/:id`}
            component={() => <Config_UNAI_AEV mode={"View"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/Config_Unai_Edit/:id`}
            component={() => <Config_UNAI_AEV mode={"Edit"} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IoT_Master_Data/ImportDelivery_Unai`}
            component={() => <ImportDelivery_Unai />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IOT_Connect/PrepareProduct_unai`}
            component={() => <PPUNAI_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projectmanagement/CustomerManagement`}
            component={() => <Customer_Management />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_documents`}
            component={() => <Manage_documents />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_documents/add`}
            component={() => <Manage_documents_add mode={"add"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_documents/edit/:id`}
            component={() => <Manage_documents_add mode={"edit"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_documents/view/:id`}
            component={() => <Manage_documents_add mode={"view"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_message_types`}
            component={() => <ManageMessageType />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_message_types/add`}
            component={() => <ManageMessageTypeAdd mode={"add"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_message_types/edit/:id`}
            component={() => <ManageMessageTypeAdd mode={"edit"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Manage_message_types/view/:id`}
            component={() => <ManageMessageTypeAdd mode={"view"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Save_message`}
            component={() => <SaveMessage />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/hr_Log`}
            component={() => <HrLog />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/hr_Log/add`}
            component={() => <HrLogAdd mode="add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/hr_Log/edit/:id`}
            component={() => <HrLogAdd mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/hr_Log/view/:id`}
            component={() => <HrLogAdd mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_history`}
            component={() => <Booking_History mode="normal" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_history_hr`}
            component={() => <Booking_History mode="hr" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hall_Add`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hall"
                mode="add"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Meet_Add`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel_meet"
                mode="add"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Add`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel"
                mode="add"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hall_Reserve/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hall"
                mode="view"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Meet_Reserve/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel_meet"
                mode="view"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Reserve/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel"
                mode="view"
                previousURL="/Human_Resource/booking/reserve"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hall/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hall"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Meet/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel_meet"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hall_Hr/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="hr"
                type="hall"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history_hr"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Meet_Hr/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="hr"
                type="hotel_meet"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history_hr"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Hr/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="hr"
                type="hotel"
                mode="view"
                previousURL="/Human_Resource/booking/booking_history_hr"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hall_Edit/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hall"
                mode="edit"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Meet_Edit/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel_meet"
                mode="edit"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_AEV_Hotel_Edit/:bookId`}
            component={() => (
              <Booking_AEV
                fromMode="normal"
                type="hotel"
                mode="edit"
                previousURL="/Human_Resource/booking/booking_history"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/reserve`}
            component={() => <Reserve />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/company_regulations/Dashbord_Log`}
            component={() => <DashboardLog />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/project_acc`}
            component={() => <Project_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/project_acc/add`}
            component={() => <Project_AEV mode="Add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/project_acc/edit/:projectId`}
            component={() => <Project_AEV mode="Edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/project_acc/view/:projectId`}
            component={() => <Project_AEV mode="View" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/cost_center`}
            component={() => <Cost_Center_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/cost_center/add`}
            component={() => <Cost_Center_AEV mode="Add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/cost_center/edit/:costCenterId`}
            component={() => <Cost_Center_AEV mode="Edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/cost_center/view/:costCenterId`}
            component={() => <Cost_Center_AEV mode="View" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expense_topic`}
            component={() => <Expense_Topic_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expense_topic/add`}
            component={() => <Expense_Topic_AEV mode="Add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expense_topic/edit/:expenseTopicId`}
            component={() => <Expense_Topic_AEV mode="Edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expense_topic/view/:expenseTopicId`}
            component={() => <Expense_Topic_AEV mode="View" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/withholding`}
            component={() => <Withholding_Tax_Account_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/withholding_tax_account/Add`}
            component={() => <Withholding_Tax_Account_AEV mode={"Add"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/withholding_tax_account/Edit/:id`}
            component={() => <Withholding_Tax_Account_AEV mode={"Edit"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/withholding_tax_account/View/:id`}
            component={() => <Withholding_Tax_Account_AEV mode={"View"} />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expenses_approve`}
            component={() => <Expenses_Approve_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/organization_expenses/add`}
            component={() => <Organization_Expenses_AEV mode="add" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/organization_expenses/view/:id`}
            component={() => <Organization_Expenses_AEV mode="view" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/expenses_approve/view/:id`}
            component={() => (
              <Organization_Expenses_AEV mode="view" isAdmin="true" />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/organization_expenses/edit/:id`}
            component={() => <Organization_Expenses_AEV mode="edit" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/organization_expenses`}
            component={() => <Organization_Expenses_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/organization_expenses_fe`}
            component={() => <Organization_Expenses_Table tabOn="1" />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/emp_expenses`}
            component={() => <Emp_Expenses_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/emp_expenses/add`}
            component={() => (
              <Emp_Expenses_AEV
                mode="Add"
                previousURL="/accounting/emp_expenses"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/emp_expenses/edit/:id`}
            component={() => (
              <Emp_Expenses_AEV
                mode="Edit"
                previousURL="/accounting/emp_expenses"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/emp_expenses/view/:id`}
            component={() => (
              <Emp_Expenses_AEV
                mode="View"
                previousURL="/accounting/emp_expenses"
                checkEmp="emp"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/emp_expenses_approve/view/:id`}
            component={() => (
              <Emp_Expenses_AEV
                mode="View"
                previousURL="/accounting/organization_expenses_fe"
                checkEmp="org"
              />
            )}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/dashboard`}
            component={() => <Accounting_Dashboard />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai_feature/counting`}
            component={() => <CountingFeature />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai_feature/Fine_Tuning`}
            component={() => <Fine_Tuning />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ai_feature/Counting_FineTuning`}
            component={() => <Counting_FineTuning />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/sign_config`}
            component={() => <Sign />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/favorite`}
            component={() => <Favorite_Table />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/booking/booking_dashboard`}
            component={() => <Dashboard_Booking />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Human_Resource/to_do_list/task_work/animate`}
            component={() => <Task_Work_Animate />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/accounting/report_accounting`}
            component={() => <Report_Accountig />}
          />
        </Switch>

        <Footter />
      </div>
    </Switch>
  );
};

export default Homepage;
