import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";

import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";

import Ocean_Background from "../../../../assets/img/Task_Work_Animate/Ocean_Background.png";
import Logo_PMRP from "../../../../assets/img/Task_Work_Animate/Logo_PMRP.png";

import html2canvas from 'html2canvas';

import { ReactComponent as Icon_Task } from "../../../../assets/img/Task_Work_Animate/Icon_Task.svg";
import { ReactComponent as Icon_Share } from "../../../../assets/img/Task_Work_Animate/Icon_Share.svg";
import { ReactComponent as Icon_Close } from "../../../../assets/img/Task_Work_Animate/Icon_Close.svg";

import { ReactComponent as Icon_Pass_1 } from "../../../../assets/img/Task_Work_Animate/Icon_Pass_1.svg";
import { ReactComponent as Icon_Pass_2 } from "../../../../assets/img/Task_Work_Animate/Icon_Pass_2.svg";
import { ReactComponent as Icon_Fail } from "../../../../assets/img/Task_Work_Animate/Icon_Fail.svg";
import { ReactComponent as Icon_Shark } from "../../../../assets/img/Task_Work_Animate/Icon_Shark.svg";

import Wave_Type_1 from "./Wave_Type_1";

import TTT_Person from "../../../../assets/img/booking/Person_Default.png";

import Boat_1 from "../../../../assets/img/Task_Work_Animate/Boat_1.png";
// import Boat_1_1 from "../../../../assets/img/Task_Work_Animate/Boat_1_1.png";
// import Boat_1_2 from "../../../../assets/img/Task_Work_Animate/Boat_1_2.png";
// import Boat_1_3 from "../../../../assets/img/Task_Work_Animate/Boat_1_3.png";

// import Boat_2 from "../../../../assets/img/Task_Work_Animate/Boat_2.png";
// import Boat_2_1 from "../../../../assets/img/Task_Work_Animate/Boat_2_1.png";
// import Boat_2_2 from "../../../../assets/img/Task_Work_Animate/Boat_2_2.png";
// import Boat_2_3 from "../../../../assets/img/Task_Work_Animate/Boat_2_3.png";

// import Boat_3 from "../../../../assets/img/Task_Work_Animate/Boat_3.png";
// import Boat_3_1 from "../../../../assets/img/Task_Work_Animate/Boat_3_1.png";
// import Boat_3_2 from "../../../../assets/img/Task_Work_Animate/Boat_3_2.png";
// import Boat_3_3 from "../../../../assets/img/Task_Work_Animate/Boat_3_3.png";

// import Boat_4 from "../../../../assets/img/Task_Work_Animate/Boat_4.png";
// import Boat_4_1 from "../../../../assets/img/Task_Work_Animate/Boat_4_1.png";
// import Boat_4_2 from "../../../../assets/img/Task_Work_Animate/Boat_4_2.png";
// import Boat_4_3 from "../../../../assets/img/Task_Work_Animate/Boat_4_3.png";

import Shark_1 from "../../../../assets/img/Task_Work_Animate/Shark_1.png";
import Shark_1_1 from "../../../../assets/img/Task_Work_Animate/Shark_1_1.png";
import Shark_1_2 from "../../../../assets/img/Task_Work_Animate/Shark_1_2.png";
import Shark_1_3 from "../../../../assets/img/Task_Work_Animate/Shark_1_3.png";

import Shark_2 from "../../../../assets/img/Task_Work_Animate/Shark_2.png";
import Shark_2_1 from "../../../../assets/img/Task_Work_Animate/Shark_2_1.png";
import Shark_2_2 from "../../../../assets/img/Task_Work_Animate/Shark_2_2.png";
import Shark_2_3 from "../../../../assets/img/Task_Work_Animate/Shark_2_3.png";

import Shark_3 from "../../../../assets/img/Task_Work_Animate/Shark_3.png";
import Shark_3_1 from "../../../../assets/img/Task_Work_Animate/Shark_3_1.png";
import Shark_3_2 from "../../../../assets/img/Task_Work_Animate/Shark_3_2.png";
import Shark_3_3 from "../../../../assets/img/Task_Work_Animate/Shark_3_3.png";

import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import trident from "../../../../assets/img/Task_Work_Animate/trident.png";
import gun from "../../../../assets/img/Task_Work_Animate/gun.png";
import sword from "../../../../assets/img/Task_Work_Animate/sword.png";
// import "../../../../assets/css/animate-to-do.css";
import { useParams } from "react-router";

import greenComplete from "../../../../assets/img/Task_Work_Animate/Green_Complete.png";
import yellowComplete from "../../../../assets/img/Task_Work_Animate/Yellow_Complete.png";
import redComplete from "../../../../assets/img/Task_Work_Animate/Red_Complete.png";

function Task_Work_Animate() {
  const [dateTimeData, setDateTimeData] = useState({
    startDate: 0,
    startMonth: 0,
    startYear: 0,
    endDate: 0,
    endMonth: 0,
    endYear: 0,
    dayOfWeek: 0,
  });

  const [headerNumberData, setHeaderNumberData] = useState({
    green_count: 0,
    yellow_count: 0,
    red_count: 0,
    shark_count: 0,
    score_count: 0,
  });

  const [evaluationData, setEvaluationData] = useState({
    current_evaluation: "",
    last_week: "",
  });

  const [taskData, setTaskData] = useState({
    finish: [],
    not_finish: [],
  });

  const { week, year } = useParams();

  const [boatData, setBoatData] = useState({
    boat_stage: 1, // 1, 2, 3, 4
    boat_status: "normal", // normal, attacked_1, attacked_2, attacked_3
  });

  const [sharkDataGroup, setSharkDataGroup] = useState({
    group_1: [],
    group_2: [],
  });

  const [saveCurrentDate, setSaveCurrentDate] = useState({
    startDate: null,
    endDate: null,
    week: 0,
    year: 0,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [idsendTask, setIdsendTask] = useState(null);

  const [employeeData, setEmployeeData] = useState({
    data: {}
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const isToggleModal = (taskId) => {
    if (idsendTask === taskId) {
      setIdsendTask(null);
    } else {
      setIdsendTask(taskId);
    }
  };

  // ----- Prevent Zoom ----- //
  function usePreventZoom(scrollCheck = true, keyboardCheck = true) {
    useEffect(() => {
      const handleKeydown = (e) => {
        if (
          keyboardCheck &&
          e.ctrlKey &&
          (e.keyCode == "61" ||
            e.keyCode == "107" ||
            e.keyCode == "173" ||
            e.keyCode == "109" ||
            e.keyCode == "187" ||
            e.keyCode == "189")
        ) {
          e.preventDefault();
        }
      };

      const handleWheel = (e) => {
        if (scrollCheck && e.ctrlKey) {
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeydown);
      document.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        document.removeEventListener("keydown", handleKeydown);
        document.removeEventListener("wheel", handleWheel);
      };
    }, [scrollCheck, keyboardCheck]);
  }

  usePreventZoom();
  // ----- Prevent Zoom ----- //

  async function filterPersonalEvaluation() {
    const tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/todolist/filterPersonalEvaluation",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        setHeaderNumberData({
          ...headerNumberData,
          green_count: response.data.data.great_pass_count,
          yellow_count: response.data.data.pass_count,
          red_count: response.data.data.fail_count,
          score_count: response.data.data.score,
        });

        headerNumberData.green_count = response.data.data.great_pass_count;
        headerNumberData.yellow_count = response.data.data.pass_count;
        headerNumberData.red_count = response.data.data.fail_count;
        headerNumberData.score_count = response.data.data.score;

        setEvaluationData({
          ...evaluationData,
          current_evaluation: response.data.data.current_evaluation,
          last_week: response.data.data.last_week,
        });

        evaluationData.current_evaluation =
          response.data.data.current_evaluation;
        evaluationData.last_week = response.data.data.last_week;
      })
      .catch(function (error) {
        // console.log(error);
        // Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const SentWork = async (model) => {
    let pushData = {
      id: model.task_id,
      assign_by_status_id: model.assign_by_status_id,
      type: model.task_type,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/updateSentWork",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        isToggleModal(model.task_id);
        await filterTaskForShark();
        // await FilterTask();
        // await FilterEvaluate();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const printScreen = () => {
    window.print();
  };

  const captureScreenshot = () => {
    // Select the entire document body or a specific element
    const element = document.body; // You can change this to any element you want to capture

    html2canvas(element).then((canvas) => {
      // Convert the canvas to an image
      const img = canvas.toDataURL("image/png");
      
      // Create a download link for the image
      const link = document.createElement('a');
      link.href = img;
      link.download = 'screenshot.png';
      link.click(); // Automatically trigger the download
    });
  };

  async function filterTaskForShark() {
    const tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/todolist/filterTaskForShark",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        setTaskData({
          finish: response.data.data.finish,
          not_finish: response.data.data.not_finish,
        });
        taskData.finish = response.data.data.finish;
        taskData.not_finish = response.data.data.not_finish;

        setHeaderNumberData({
          ...headerNumberData,
          shark_count:
            response.data.data.finish.length +
            response.data.data.not_finish.length,
        });
        headerNumberData.shark_count =
          response.data.data.finish.length +
          response.data.data.not_finish.length;

        const temp_group_1 = [];
        const temp_group_2 = [];

        for (const item of response.data.data.finish) {
          const temp_shark_stage = moment(
            `${item.finish_year}-${item.finish_week}`,
            "YYYY-WW"
          ).diff(moment(`${item.year}-${item.week}`, "YYYY-WW"), "week");

          let temp_shark_image = null;

          if (temp_shark_stage + 1 == 3) {
            if (item.finish_type == 3) {
              temp_shark_image = Shark_3_3;
            } else if (item.finish_type == 2) {
              temp_shark_image = Shark_3_2;
            } else {
              temp_shark_image = Shark_3_1;
            }
          } else if (temp_shark_stage + 1 == 2) {
            if (item.finish_type == 3) {
              temp_shark_image = Shark_2_3;
            } else if (item.finish_type == 2) {
              temp_shark_image = Shark_2_2;
            } else {
              temp_shark_image = Shark_2_1;
            }
          } else {
            if (item.finish_type == 3) {
              temp_shark_image = Shark_1_3;
            } else if (item.finish_type == 2) {
              temp_shark_image = Shark_1_2;
            } else {
              temp_shark_image = Shark_1_1;
            }
          }

          const sharkData = {
            shark_id: item.id,
            shark_title: item.task,
            shark_status: "dead", // alive, dead
            shark_stage:
              temp_shark_stage > 0 ? (temp_shark_stage + 1 >= 3 ? 3 : 2) : 1, // 1, 2, 3
            shark_injured: item.finish_type, // 1 = gun, 2 = knife, 3 = harpoon
            shark_image: temp_shark_image,
            shark_open_title: false,
          };

          if (temp_group_1.length == temp_group_2.length) {
            temp_group_1.push(sharkData);
          } else {
            temp_group_2.push(sharkData);
          }
        }

        for (const item of response.data.data.not_finish) {
          const temp_shark_stage = moment(
            `${moment(new Date()).isoWeekYear()}-${moment(new Date())
              .isoWeekday(1)
              .week()}`,
            "YYYY-WW"
          ).diff(moment(`${item.year}-${item.week}`, "YYYY-WW"), "week");

          let temp_shark_image = null;

          if (temp_shark_stage + 1 == 3) {
            temp_shark_image = Shark_3;
          } else if (temp_shark_stage + 1 == 2) {
            temp_shark_image = Shark_2;
          } else {
            temp_shark_image = Shark_1;
          }

          const sharkData = {
            shark_id: item.id,
            shark_title: item.task,
            shark_status: "alive", // alive, dead
            shark_stage:
              temp_shark_stage > 0 ? (temp_shark_stage + 1 >= 3 ? 3 : 2) : 1, // 1, 2, 3
            shark_injured: item.finish_type, // 1 = gun, 2 = knife, 3 = harpoon
            shark_image: temp_shark_image,
            shark_open_title: false,
          };

          if (temp_group_1.length == temp_group_2.length) {
            temp_group_1.push(sharkData);
          } else {
            temp_group_2.push(sharkData);
          }
        }

        setSharkDataGroup({
          group_1: temp_group_1.sort(function (a, b) {
            return Math.random() - 0.5;
          }),
          group_2: temp_group_2.sort(function (a, b) {
            return Math.random() - 0.5;
          }),
        });
      })
      .catch(function (error) {
        // console.log(error);
        // Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const getEmployeeByEmpId = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      employee_id: getUser().fup,
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeByEmpId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          // const data = response.data.data.filter(el => {
          //   return el.emp_status_type_id !==  "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && el.is_active !== false;
          // }).sort((a, b) => (`${a.emp_no}`.localeCompare(b.emp_no)));
          if (response.data.data.length > 0) {
            setEmployeeData({ ...employeeData, data: response.data.data[0] });
            employeeData.data = response.data.data[0];
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(async () => {
    const currentDate = new Date();
    setDateTimeData({
      startDate: moment().startOf("week").add(1, "days").format("DD"),
      startMonth: moment().startOf("week").add(1, "days").format("MM"),
      startYear: moment().startOf("week").add(1, "days").format("YY"),
      endDate: moment().endOf("week").subtract(1, "days").format("DD"),
      endMonth: moment().endOf("week").subtract(1, "days").format("MM"),
      endYear: moment().endOf("week").subtract(1, "days").format("YY"),
      dayOfWeek: moment().day(),
    });

    setSaveCurrentDate({
      ...saveCurrentDate,
      startDate: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .startOf("week")
        .add(1, "days"),
      endDate: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .endOf("week")
        .subtract(1, "days"),
      week: moment(currentDate).isoWeekday(1).week(),
      year: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .year(),
    });
    await getEmployeeByEmpId();
    await filterPersonalEvaluation();
    await filterTaskForShark();
  }, []);

  function DistanceStatus({ data }) {
    return (
      <div style={{ display: "flex", textAlign: "left" }}>
        <div
          style={{
            background: `${
              data.assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                ? "#AEAEAE"
                : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#FF415C"
                : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#3579F6"
                : "#E9B500"
            }`,
            borderRadius: "28px",
            width: "25px",
            height: "43px",
            flexShrink: "0",
          }}
        />

        <div style={{ paddingLeft: "10px" }}>
          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_by_status_id ===
                "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "16px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {data.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
              ? "SUCCESSED TASK"
              : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "LATE TASK"
              : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "FUTURE TASK"
              : "CURRENT TASK"}
          </span>

          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_by_status_id ===
                "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {moment(data.due_date).locale("en").format("MMMM DD, YYYY")}
          </span>
        </div>
      </div>
    );
  }

  const [alertShown, setAlertShown] = useState(false);

  const showAlert = () => {
    let checkStatus = "";
    let checkBoat = "";
    if (
      evaluationData.current_evaluation.week_status_id ===
      "7a232969-76c8-4133-bc0a-62079d01d10b"
    ) {
      checkStatus = "over";
    } else if (
      evaluationData.current_evaluation.week_status_id ===
      "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
    ) {
      checkStatus = "pass";
    } else {
      checkStatus = "fail";
    }

    if (
      evaluationData.current_evaluation.boat > evaluationData.last_week.boat
    ) {
      checkBoat = "up";
    } else if (
      evaluationData.current_evaluation.boat === evaluationData.last_week.boat
    ) {
      checkBoat = "constant";
    } else if (
      evaluationData.current_evaluation.boat < evaluationData.last_week.boat
    ) {
      checkBoat = "down";
    }

    Swal.fire({
      html: `
        <div class="custom-container">
          <div class="custom-text-container">
            <strong class="animate-title">${
              checkStatus === "fail" ? "You die" : "Mission Complete"
            }</strong>
            <p class="animate-text">${
              checkStatus === "fail"
                ? "สาเหตุการตาย : ถูกฉลามกัด"
                : `ยินดีด้วย! คุณคือ 1 ในผู้รอดชีวิตในสัปดาห์ที่ ${evaluationData.current_evaluation.week}`
            }</p>
          </div>
          <img src="${
            checkStatus === "over"
              ? greenComplete
              : checkStatus === "pass"
              ? yellowComplete
              : redComplete
          }" alt="Custom image" class="animate-image" />
          <p class="animate-text-num">
            ${headerNumberData.score_count}/60
          </p>
          ${
            checkBoat === "up"
              ? `<p class="animate-text-level">เรือของคุณถูกอัพเกรด LV ${evaluationData.last_week.boat} > LV ${evaluationData.current_evaluation.boat}</p>`
              : checkBoat === "down"
              ? `<p class="animate-text-level">เรือของคุณถูกลดเกรด LV ${evaluationData.last_week.boat} > LV ${evaluationData.current_evaluation.boat}</p>`
              : ``
          }
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        popup: `animate-popup-${
          checkStatus === "over"
            ? "green"
            : checkStatus === "pass"
            ? "yellow"
            : "red"
        }`,
      },
    });
  };

  useEffect(() => {
    if (evaluationData.current_evaluation.week_status_id && !alertShown) {
      showAlert();
      setAlertShown(true);
    }
  }, [evaluationData]);

  return (
    <div
      className="main-background"
      style={{
        "--mainBackground": `url(${Ocean_Background})`,
      }}
    >
      {/* ----- Part Header ----- */}
      <div
        className="row"
        style={{
          margin: "0px",
          justifyContent: "space-between",
        }}
      >
        <div className="col-auto" style={{ padding: 0 }}>
          <div className="row" style={{ margin: "5px 10px 0px 10px" }}>
            <img
              src={Logo_PMRP}
              alt="Logo_PMRP"
              draggable={false}
              style={{ height: "65px", margin: "5px", userSelect: "none" }}
            />

            <div className="base-div-1" style={{ flex: "1", fontSize: "30px" }}>
              <div className="vertical-line-div" />
              PMRP TO DO LIST
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div
              className="base-div-1"
              style={{
                width: "100%",
                minHeight: "86px",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div style={{ fontSize: "22px" }}>
                {dateTimeData.startDate}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.startMonth}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.startYear} - {dateTimeData.endDate}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.endMonth}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.endYear}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 1
                      ? { backgroundColor: "#ECBF00" }
                      : {}
                  }
                >
                  จ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 2
                      ? { backgroundColor: "#FF00B7" }
                      : {}
                  }
                >
                  อ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 3
                      ? { backgroundColor: "#00BF63" }
                      : {}
                  }
                >
                  พ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 4
                      ? { backgroundColor: "#FF914D" }
                      : {}
                  }
                >
                  พฤ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 5
                      ? { backgroundColor: "#38B6FF" }
                      : {}
                  }
                >
                  ศ.
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div className="base-div-2">
              <Icon_Pass_1 style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.green_count)}
            </div>

            <div className="base-div-2">
              <Icon_Pass_2 style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.yellow_count)}
            </div>

            <div className="base-div-2">
              <Icon_Fail style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.red_count)}
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div className="base-div-2" style={{ fontSize: "32px" }}>
              <Icon_Shark style={{ marginRight: "10px" }} /> X{" "}
              {/* {new Intl.NumberFormat().format(headerNumberData.shark_count)} */}
              {new Intl.NumberFormat().format(headerNumberData.shark_count)}
            </div>

            <div className="base-div-2" style={{ flex: "1", fontSize: "32px" }}>
              Score :
              <span
                style={{
                  marginLeft: "5px",
                  color: `${
                    headerNumberData.score_count >= 0 ? "#00FF58" : "#FF171B"
                  }`,
                }}
              >
                {new Intl.NumberFormat().format(headerNumberData.score_count)}
              </span>
            </div>
          </div>
        </div>

        <div className="col-auto" style={{ padding: 0 }}>
          <div className="row" style={{ margin: "5px 10px" }}>
            <button
              type="button"
              className="base-button"
              onClick={() => {
                toggleModal();
                isToggleModal();
              }}
            >
              <Icon_Task style={{ marginRight: "10px" }} /> Task Detail (
              <span style={{ color: "#3579F6" }}>
                {/* {new Intl.NumberFormat().format(taskData.length)} */}
                {new Intl.NumberFormat().format(taskData.not_finish.length)}
              </span>
              )
            </button>

            <button type="button" className="base-button"
              onClick={() => captureScreenshot()}
            >
              <Icon_Share />
            </button>

            <button
              type="button"
              className="base-button"
              onClick={() => {
                window.location.href = "/Human_Resource/to_do_list/task_work";
              }}
            >
              <Icon_Close />
            </button>
          </div>
          {modalOpen && (
            <div className="animate-modal" style={{ overflow: "hidden" }}>
              <div className="animate-modal-content">
                <div className="row" style={{ margin: 0 }}>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      fontSize: "30px",
                      fontWeight: "bold",
                      left: "10px",
                    }}
                  >
                    Task Detail
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      right: "10px",
                    }}
                  >
                    <span
                      onClick={toggleModal}
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      -
                    </span>
                  </div>
                </div>
                <div className="table-style-dashboard-log-animate">
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      position: "relative",
                      // tableLayout: "fixed",
                      // maxHeight: "300px",
                      // overflowY: "auto",
                      // display: "block",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#D5E0F5",
                        position: "sticky",
                        top: 0,
                        zIndex: 99,
                      }}
                    >
                      <tr>
                        <th style={{ width: "62px" }}>No.</th>
                        <th
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "300px",
                          }}
                        >
                          Task
                        </th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ width: "100%" }}>Action</div>
                          </div>
                        </th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ width: "100%" }}>Task Due</div>
                          </div>
                        </th>
                        <th>Management</th>
                      </tr>{" "}
                    </thead>
                    <tbody>
                      {taskData.not_finish.map((task, index) => (
                        <tr
                          className="table-row-hover"
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "rgb(238 243 255)" : "",
                          }}
                        >
                          <td>
                            <div style={{ textAlign: "center" }}>
                              {index + 1}
                            </div>
                          </td>
                          <td
                            style={{
                              width: "300px",
                              wordWrap: "break-word",
                              wordBreak: "break-word",
                            }}
                          >
                            {task.task}
                          </td>
                          <td>
                            {[
                              "a4147c32-a749-4b91-b9eb-e11bf6d5a219",
                              "53140a82-3fdb-44d8-a726-77492c63c800",
                              "dd65b3a6-9946-491f-8fd9-688113f52daf",
                            ].includes(task.assign_to_status_id) && (
                              <>
                                <div style={{ position: "relative" }}>
                                  <button
                                    type="button"
                                    className={`btn btn-block ${
                                      task.assign_to_status_id ===
                                        "a4147c32-a749-4b91-b9eb-e11bf6d5a219" &&
                                      task.assign_by_status_id !=
                                        "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
                                        ? "btn-primary"
                                        : task.assign_to_status_id ===
                                          "53140a82-3fdb-44d8-a726-77492c63c800"
                                        ? "btn-warning"
                                        : task.assign_by_status_id ===
                                            "7a2a0ab3-cc81-4b17-9129-c95347337dd0" &&
                                          task.assign_to_status_id !=
                                            "dd65b3a6-9946-491f-8fd9-688113f52daf"
                                        ? "btn-secondary"
                                        : "btn-danger"
                                    }`}
                                    onClick={() =>
                                      task.assign_by_status_id !==
                                      "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
                                        ? isToggleModal(task.id)
                                        : null
                                    }
                                    disabled={
                                      task.assign_by_status_id ===
                                      "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
                                    }
                                    style={{ minWidth: "100px" }}
                                  >
                                    {task.assign_to_status_id ===
                                    "a4147c32-a749-4b91-b9eb-e11bf6d5a219"
                                      ? "ส่งงาน"
                                      : "ส่งงานอีกครั้ง"}
                                  </button>
                                  {idsendTask === task.id && (
                                    <div
                                      className="custom-modal"
                                      style={{
                                        display: "flex",
                                        position: "absolute",
                                        backgroundColor: "#252525",
                                        top: `${index === taskData.not_finish.length - 1 ? "-60px" : "43px"}`,
                                        right: "0",
                                        zIndex: `${index === taskData.not_finish.length - 1 ? 9999 : 1}`,
                                        width: "auto",
                                        right: "auto",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <img
                                        src={trident}
                                        alt="trident"
                                        draggable={false}
                                        onClick={() =>
                                          SentWork({
                                            task_id: task.id,
                                            assign_by_status_id:
                                              "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                                            task_type: "3",
                                          })
                                        }
                                        style={{
                                          height: "45px",
                                          margin: "5px",
                                          userSelect: "none",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <img
                                        src={gun}
                                        alt="gun"
                                        draggable={false}
                                        onClick={() =>
                                          SentWork({
                                            task_id: task.id,
                                            assign_by_status_id:
                                              "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                                            task_type: "1",
                                          })
                                        }
                                        style={{
                                          height: "45px",
                                          margin: "5px",
                                          userSelect: "none",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <img
                                        src={sword}
                                        alt="sword"
                                        draggable={false}
                                        onClick={() =>
                                          SentWork({
                                            task_id: task.id,
                                            assign_by_status_id:
                                              "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                                            task_type: "2",
                                          })
                                        }
                                        style={{
                                          height: "45px",
                                          margin: "5px",
                                          userSelect: "none",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            <DistanceStatus data={task} />
                          </td>
                          <td>
                            <div style={{ textAlign: "center" }}>
                              <a
                                href={`/Human_Resource/to_do_list/assign_work/readt_animate/${task.id}/${saveCurrentDate.week}/${saveCurrentDate.year}`}
                                id="btn_read"
                                key={task.id}
                                className="btn btn-xs "
                              >
                                <i class="fas fa-eye"></i>
                                {"   "}
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <button onClick={toggleModal} className="close-button">
                Close
              </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ----- Part Header ----- */}

      {/* ----- Part Ocean ----- */}
      <Wave_Type_1
        gradient_id="wave-gradient-1"
        gradient_color_1="#05697A"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.8"
        use_y="2"
        use_class="waves-parallax-style-1"
      />

      <Wave_Type_1
        gradient_id="wave-gradient-2"
        gradient_color_1="#0793AB"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.7"
        use_y="4"
        use_class="waves-parallax-style-2"
      />

      {/* <div className="setting-shark">
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div
        className="setting-shark-1-type-1"
        style={{
          "--layer-width": "min(6vw, 6vh)",
          "--layer-height": "min(4vw, 4vh)",
        }}
      >
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div
        className="setting-shark-1-type-2"
        style={{
          "--layer-width": "min(6vw, 6vh)",
          "--layer-height": "min(4vw, 4vh)",
          "--delay-shark": "2s",
        }}
      >
        <img src={Shark_1} alt="" />
      </div> */}

      {sharkDataGroup.group_2.map((item, index) => {
        if (index % 2 == 0) {
          return (
            <div
              id={`shark_group_2_${index}`}
              className={`setting-shark-${item.shark_stage} setting-shark-type-1`}
              style={{
                "--layer-width": "min(6vw, 6vh)",
                "--layer-height": "min(4vw, 4vh)",
                "--delay-shark": `${(index + 1) * 3}s`,
                "--time-shark": `${item.shark_status == "dead" ? "36s" : "0s"}`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_2)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_2: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_2_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_2_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_2_${index}`}
                className="text-tooltip text-tooltip-type-1"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#FF415C" }}
                />
                {item.shark_title}
              </div>

              <img src={item.shark_image} alt="" draggable={false} />
            </div>
          );
        } else {
          return (
            <div
              id={`shark_group_2_${index}`}
              className={`setting-shark-${item.shark_stage} setting-shark-type-2`}
              style={{
                "--layer-width": "min(6vw, 6vh)",
                "--layer-height": "min(4vw, 4vh)",
                "--delay-shark": `${(index + 1) * 3}s`,
                "--time-shark": `${item.shark_status == "dead" ? "36s" : "0s"}`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_2)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_2: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_2_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_2_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_2_${index}`}
                className="text-tooltip text-tooltip-type-2"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#E9B500" }}
                />
                {item.shark_title}
              </div>

              <img src={item.shark_image} alt="" draggable={false} />
            </div>
          );
        }
      })}

      <Wave_Type_1
        gradient_id="wave-gradient-3"
        gradient_color_1="#09BDDC"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.6"
        use_y="6"
        use_class="waves-parallax-style-3"
      />

      <div className="setting-boat-1">
        <div
          className="person-img"
          style={{
            // width: '100px',
            // height: '100px', 
            backgroundColor: 'orange',
            borderRadius: '50%',
            top: '5%',
            left: '21.5%',
            position: 'absolute',
            display: 'flex',
            overflow: 'hidden'
          }}
        >
          <img src={employeeData.data?.file_path 
            ? Configs.API_URL_IMG + employeeData.data.file_path
            : TTT_Person} style={{ width: '100%', height: '100%', margin: 'auto', objectFit: 'cover' }} alt="" draggable={false} />
        </div>
        <img src={Boat_1} alt="" draggable={false} />
      </div>

      <Wave_Type_1
        gradient_id="wave-gradient-4"
        gradient_color_1="#23D6F6"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.5"
        use_y="8"
        use_class="waves-parallax-style-4"
      />

      {/* <div className="setting-shark">
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div className="setting-shark-1-type-1" style={{ "--delay-shark": "4s" }}>
        <div className="text-tooltip-1">
          <div
            className="status-tooltip"
            style={{ backgroundColor: "#FF415C" }}
          />
          TEST
        </div>
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div className="setting-shark-1-type-2" style={{ "--delay-shark": "6s" }}>
        <div className="text-tooltip-2">
          <div
            className="status-tooltip"
            style={{ backgroundColor: "#E9B500" }}
          />
          TEST
        </div>
        <img src={Shark_1} alt="" />
      </div> */}

      {sharkDataGroup.group_1.map((item, index) => {
        if (index % 2 == 0) {
          return (
            <div
              id={`shark_group_1_${index}`}
              className={`setting-shark-${item.shark_stage} setting-shark-type-1`}
              style={{
                "--delay-shark": `${(index + 1) * 2}s`,
                "--time-shark": `${item.shark_status == "dead" ? "36s" : "0s"}`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_1)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_1: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_1_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_1_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_1_${index}`}
                className="text-tooltip text-tooltip-type-1"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#FF415C" }}
                />
                {item.shark_title}
              </div>

              <img src={item.shark_image} alt="" draggable={false} />
            </div>
          );
        } else {
          return (
            <div
              id={`shark_group_1_${index}`}
              className={`setting-shark-${item.shark_stage} setting-shark-type-2`}
              style={{
                "--delay-shark": `${(index + 1) * 2}s`,
                "--time-shark": `${item.shark_status == "dead" ? "36s" : "0s"}`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_1)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_1: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_1_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_1_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_1_${index}`}
                className="text-tooltip text-tooltip-type-2"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#E9B500" }}
                />
                {item.shark_title}
              </div>

              <img src={item.shark_image} alt="" draggable={false} />
            </div>
          );
        }
      })}

      {/* <div>
        <Modal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          centered
          style={{
            background: "none",
            border: "none",
            boxShadow: "none",
            width: "739px",
          }}
          contentClassName="custom-modal-background"
        >
          <ModalBody
            style={{
              // backgroundColor: "#1DC25680",
              color: "white",
              width: "739px",
              // height: "787px",
            }}
          >
          </ModalBody>
        </Modal>
      </div> */}

      <Wave_Type_1
        gradient_id="wave-gradient-5"
        gradient_color_1="#54DFF8"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.4"
        use_y="10"
        use_class="waves-parallax-style-5"
      />

      <Wave_Type_1
        gradient_id="wave-gradient-6"
        gradient_color_1="#85E8FA"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.3"
        use_y="12"
        use_class="waves-parallax-style-6"
      />
      {/* ----- Part Ocean ----- */}
    </div>
  );
}

export default Task_Work_Animate;
