import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

import BarLine from "./Dashboard_Component/Bar_Line";
import CardCount from "./Dashboard_Component/Card_Count";
import CardTable from "./Dashboard_Component/Card_Table";
import PieChart from "./Dashboard_Component/Pie_Chart";
import HorizontalBarChart from "./Dashboard_Component/Horizontal_Bar_Chart";
import StackHorizontalBarChart from "./Dashboard_Component/Stack_Horizontal_Bar_Chart";
import Stack100HorizontalBarChart from "./Dashboard_Component/Stack100_Horizontal_Bar_Chart";
import VerticalBarChart from "./Dashboard_Component/Vertical_Bar_Chart";

import { getOem, getToken, getUser } from "../../Utils/Common";
import axios from "axios";
import Configs from "../../config";

import "../../assets/css/Dashboard.css";
import "../../assets/css/CustomScroll.css";
import Swal from "sweetalert2";

function Dashboard_hr_ttt() {
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  function isOverlapping(startDate1, endDate1, startDate2, endDate2) {
    return (
      moment(startDate1).isSameOrBefore(endDate2) &&
      moment(startDate2).isSameOrBefore(endDate1)
    );
  }
  const today = new Date();
  const [dateRange, setDateRange] = useState([null, null]);
  let [startDate, endDate] = dateRange;
  let [dateForReport, setDateForReport] = useState({
    start_date: null,
    end_date: null,
  });

  const [selectOptionEmp, setSelectOptionEmp] = useState([]);
  let [selectedEmpListMain, setSelectedEmpListMain] = useState([]);
  let [selectedEmpListToDo, setSelectedEmpListToDo] = useState([]);
  let [selectOptionEmpTypeList, setSelectOptionEmpTypeList] = useState([]);
  let [selectedEmpTypeList, setSelectedEmpTypeList] = useState([]);

  const [dataEmp, setDataEmp] = useState({
    RawData: [],
    empRowTable_3: [],
    empRowTable_4_5: [],
  });

  const [dataToDo, setDataToDo] = useState({
    TotalToDo: 0,
    PassToDo: 0,
    FailToDo: 0,
    PercentPassToDo: 0,
    PercentFailToDo: 0,
    FixToDo: 0,
    RawData: [],
  });

  const [dataLeave, setDataLeave] = useState({
    RawData: [],
  });

  const [dataTimeAtt, setDataTimeAtt] = useState({
    RawData: [],
  });

  let [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState([]);

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [],
          arrList2 = [];

        for (let item of response.data.data) {
          let temp2 = {
            value: item.id,
            label: item.contract_type_name,
          };

          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
          if (item.id == "9a729476-553f-48c4-9ffe-090c02ae66ee") continue;
          arrList2.push(temp2);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData(arrList);
        getEmployeeWorkTypeData = arrList;
        setSelectOptionEmpTypeList(arrList2);
        selectOptionEmpTypeList = arrList2;
        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function FilterEmployee() {
    let get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: null,
      emp_name_th: null,
      emp_type_id: null,
      resign_status: true,
    };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async (response) => {
        // response.data.data.sort((a, b) => {
        //   if (a.emp_no === null) {
        //     return 1;
        //   }

        //   if (b.emp_no === null) {
        //     return -1;
        //   }

        //   if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
        //     return 0;
        //   }

        //   return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
        // });

        let empAll = [];

        for (let item of getEmployeeWorkTypeData) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        setDataEmp({
          ...dataEmp,
          RawData: empAll,
        });
        dataEmp.RawData = empAll;

        const options = [];
        empAll.forEach((item) => {
          var element = {};
          element["value"] = item.emp_id;
          element["label"] = item.emp_no;
          options.push(element);
        });
        setSelectOptionEmp(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function FilterTaskDashboard(mode) {
    let startWeek = 0;
    let startYear = 0;
    let endWeek = 0;
    let endYear = 0;

    let startStartDate = new Date(startDate.getFullYear(), 0, 1);
    let startDays = Math.floor(
      (startDate - startStartDate) / (24 * 60 * 60 * 1000)
    );
    let startWeekNumber = Math.ceil(startDays / 7);
    startWeek = startWeekNumber;
    startYear = startDate.getFullYear();

    let endStartDate =
      endDate === null || endDate === ""
        ? new Date(startDate.getFullYear(), 0, 1)
        : new Date(endDate.getFullYear(), 0, 1);
    let endDays =
      endDate === null || endDate === ""
        ? Math.floor((startDate - startStartDate) / (24 * 60 * 60 * 1000))
        : Math.floor((endDate - endStartDate) / (24 * 60 * 60 * 1000));
    let endWeekNumber = Math.ceil(endDays / 7);
    endWeek = endWeekNumber;
    endYear =
      endDate === null || endDate === ""
        ? startDate.getFullYear()
        : endDate.getFullYear();

    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      assign_by_id:
        selectedEmpListToDo.length === 0
          ? [null]
          : selectedEmpListToDo.map((item) => {
              return item.value;
            }),
      assign_to_id:
        selectedEmpListMain.length === 0
          ? [null]
          : selectedEmpListMain.map((item) => {
              return item.value;
            }),
      startWeek: startWeek,
      startYear: startYear,
      endWeek: endWeek,
      endYear: endYear,
      emp_type_id:
        selectedEmpTypeList.length === 0
          ? [null]
          : selectedEmpTypeList.map((item) => {
              return item.value;
            }),
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterTaskDashboard",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async (response) => {
        setDataToDo({
          ...dataToDo,
          TotalToDo: response.data.data.length,
          PassToDo: response.data.data.filter((item) => {
            return (
              item.assign_to_status_id ===
              "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
            );
          }).length,
          FailToDo: response.data.data.filter((item) => {
            return (
              item.assign_to_status_id !==
              "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
            );
          }).length,
          PercentPassToDo:
            (response.data.data.filter((item) => {
              return (
                item.assign_to_status_id ===
                "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
              );
            }).length *
              100) /
              response.data.data.length || 0,
          PercentFailToDo:
            (response.data.data.filter((item) => {
              return (
                item.assign_to_status_id !==
                "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
              );
            }).length *
              100) /
              response.data.data.length || 0,
          FixToDo: response.data.data.filter((item) => {
            return item.task_count !== 0;
          }).length,
          RawData: response.data.data,
        });
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function FilterLeaveDashboard(mode) {
    let get_filler_leave = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_id:
        selectedEmpListMain.length === 0
          ? [null]
          : selectedEmpListMain.map((item) => {
              return item.value;
            }),
      startDate: startDate,
      endDate: endDate === null || endDate === "" ? startDate : endDate,
      emp_type_id:
        selectedEmpTypeList.length === 0
          ? [null]
          : selectedEmpTypeList.map((item) => {
              return item.value;
            }),
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterLeaveDashboard",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_leave,
    })
      .then(async (response) => {
        setDataLeave({
          ...dataLeave,
          business_leave_count: response.data.data.filter((item) => {
            return (
              item.emp_status_id === "33634864-0565-4f8b-9565-415e4ac4ace8"
            );
          }).length,
          sick_leave_count: response.data.data.filter((item) => {
            // if (item.emp_status_id === "7ab619b9-98f9-41ac-a2c9-09e5217d7c64") {
            //   console.log('response', response.data.data);
            // }
            return (
              item.emp_status_id === "7ab619b9-98f9-41ac-a2c9-09e5217d7c64"
            );
          }).length,
          take_annual_leave_count: response.data.data.filter((item) => {
            return (
              item.emp_status_id === "51062121-eb22-433b-adc1-58f260102b97"
            );
          }).length,
          RawData: response.data.data,
        });
        dataLeave.RawData = response.data.data;

        if (mode === "search" && selectedEmpListMain.length !== 0) {
          const empList = [];
          for (let index = 0; index < dataEmp.RawData.length; index += 1) {
            if (
              selectedEmpListMain.find((item) => {
                return item.value === dataEmp.RawData[index].emp_id;
              }) !== undefined
            ) {
              empList.push(dataEmp.RawData[index]);
            }
          }

          const empListOverlap = [];
          for (const item of empList) {
            if (isOverlapping(item.emp_start_work_day, item.emp_end_work || new Date(), startDate, endDate)) {
              empListOverlap.push(item);
            }
          }

          setDataEmp({
            ...dataEmp,
            empRowTable_3: empListOverlap,
          });
          dataEmp.empRowTable_3 = empListOverlap;
        } else {
          const tempOverlap = [];
          for (const item of dataEmp.RawData) {
            if (isOverlapping(item.emp_start_work_day, item.emp_end_work || new Date(), startDate, endDate)) {
              tempOverlap.push(item);
            }
          }

          setDataEmp({
            ...dataEmp,
            empRowTable_3: tempOverlap,
          });
          dataEmp.empRowTable_3 = tempOverlap;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function FilterTimeAttendanceDashboard(mode) {
    let get_filler_timeAtt = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_id:
        selectedEmpListMain.length === 0
          ? [null]
          : selectedEmpListMain.map((item) => {
              return item.value;
            }),
      startDate: startDate,
      endDate: endDate === null || endDate === "" ? startDate : endDate,
      emp_type_id:
        selectedEmpTypeList.length === 0
          ? [null]
          : selectedEmpTypeList.map((item) => {
              return item.value;
            }),
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterTimeAttendanceDashboard",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_timeAtt,
    })
      .then(async (response) => {
        setDataTimeAtt({
          ...dataTimeAtt,
          RawData: response.data.data,
        });

        if (mode === "search" && selectedEmpListMain.length !== 0) {
          const empList = [];
          for (let index = 0; index < dataEmp.RawData.length; index += 1) {
            if (
              selectedEmpListMain.find((item) => {
                return item.value === dataEmp.RawData[index].emp_id;
              }) !== undefined
            ) {
              empList.push(dataEmp.RawData[index]);
            }
          }

          const empListOverlap = [];
          for (const item of empList) {
            if (isOverlapping(item.emp_start_work_day, item.emp_end_work || new Date(), startDate, endDate)) {
              empListOverlap.push(item);
            }
          }

          setDataEmp({
            ...dataEmp,
            empRowTable_4_5: empListOverlap,
          });
          dataEmp.empRowTable_4_5 = empListOverlap;
        } else {
          const temp =
            selectedEmpTypeList.length > 0
              ? dataEmp.RawData.filter((el) => {
                  const _find = selectedEmpTypeList.find(
                    (el2) => el2.value === el.emp_contract_type_id
                  );
                  if (_find) return el;
                })
              : dataEmp.RawData;

          const tempOverlap = [];
          for (const item of temp) {
            if (isOverlapping(item.emp_start_work_day, item.emp_end_work || new Date(), startDate, endDate)) {
              tempOverlap.push(item);
            }
          }

          setDataEmp({
            ...dataEmp,
            empRowTable_4_5: tempOverlap,
          });
          dataEmp.empRowTable_4_5 = tempOverlap;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function downloadExcelDashboard() {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading...",
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let get_filler_timeAtt = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_id:
        selectedEmpListMain.length === 0
          ? [null]
          : selectedEmpListMain.map((item) => {
              return item.value;
            }),
      startDate: dateForReport.start_date,
      endDate:
        dateForReport.end_date === null || dateForReport.end_date === ""
          ? dateForReport.start_date
          : dateForReport.end_date,
      emp_type_id:
        selectedEmpTypeList.length === 0
          ? [null]
          : selectedEmpTypeList.map((item) => {
              return item.value;
            }),
    };

    const response = await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getReportToBase64",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: get_filler_timeAtt,
    }).catch((error) => {
      Swal.fire("Can not download file", error, "error");
    });
    const data = await response.data.data;
    if (!data) return;
    // Create a data URL for the PDF
    Swal.close();
    const pdfDataUrl = `data:application/pdf;base64,${data}`;
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = `TTT Time Attendance Dashboard${moment().format(
      "DD-MM-YYYY"
    )}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click
  }

  useEffect(async () => {
    const currentDate = new Date();

    // const firstDayOfWeek = new Date(
    //   currentDate.setDate(
    //     currentDate.getDate() -
    //       currentDate.getDay() +
    //       (currentDate.getDay() === 0 ? -6 : 1)
    //   )
    // );

    // let lastDayOfWeek =  new Date(
    //   currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5)
    // );

    let firstDayOfWeek =  new Date(moment().startOf("month"));
    let lastDayOfWeek =  new Date(moment().endOf("month"));

    // if (lastDayOfWeek > today) {
    //   lastDayOfWeek = today;
    // }

    setDateRange([firstDayOfWeek, lastDayOfWeek]);
    [startDate, endDate] = [firstDayOfWeek, lastDayOfWeek];
    setDateForReport({
      start_date: startDate,
      end_date: endDate,
    });
    dateForReport.start_date = startDate;
    dateForReport.end_date = endDate;

    await GetEmployeeWorkType();
    await FilterEmployee();
    await FilterTaskDashboard("clear");
    await FilterLeaveDashboard("clear");
    await FilterTimeAttendanceDashboard("clear");
  }, []);

  const rowTable_1 = [];
  for (let index = 0; index < dataToDo.RawData.length; index += 1) {
    const rowItem = {};

    if (dataToDo.RawData[index].task_count !== 0) {
      rowItem["no"] = {
        label: rowTable_1.length + 1,
        style: { textAlign: "right" },
      };

      rowItem["task_name"] = {
        label: dataToDo.RawData[index].task,
        style: {
          textAlign: "left",
          wordBreak: "break-word",
          minWidth: "200px",
        },
      };

      rowItem["count_fix"] = {
        label: dataToDo.RawData[index].task_count,
        style: { textAlign: "right" },
      };

      rowItem["assign_to"] = {
        label: `${dataToDo.RawData[index].assign_to_name} (${dataToDo.RawData[index].assign_to_nickname})`,
        style: {
          whiteSpace: "nowrap",
          color: "#000000",
        },
      };

      rowItem["status"] = {
        label: dataToDo.RawData[index].assign_to_status_name,
        style: {
          whiteSpace: "nowrap",
          color: "#000000",
        },
      };

      rowTable_1.push(rowItem);
    }
  }

  const dataTable_1 = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่องาน",
        field: "task_name",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "จำนวนครั้งที่แก้ไข",
        field: "count_fix",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่อผู้ได้รับงาน",
        field: "assign_to",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "สถานะ",
        field: "status",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
    ],
    rows: rowTable_1,
  };

  const pieChart_1 = {
    labels: ["สำเร็จ", "ไม่สำเร็จ"],
    datasets: [
      {
        data: [dataToDo.PassToDo, dataToDo.FailToDo],
        backgroundColor: ["#7ce6ae", "#ff9e9e"],
        borderColor: ["white", "white"],
        borderWidth: 2,
      },
    ],
  };

  const rowTable_2 = [];
  for (let index = 0; index < dataLeave.RawData.length; index += 1) {
    const rowItem = {};

    rowItem["emp_no"] = {
      label: dataLeave.RawData[index].emp_no,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["emp_name"] = {
      label: `${dataLeave.RawData[index].emp_name} (${dataLeave.RawData[index].emp_nickname})`,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["leave_type"] = {
      label: dataLeave.RawData[index].emp_status_name,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["leave_date"] = {
      // label: `${moment(dataLeave.RawData[index].start_date).format(
      //   "DD/MM/YYYY"
      // )} - ${moment(dataLeave.RawData[index].end_date).format("DD/MM/YYYY")}`,
      label: `${moment(dataLeave.RawData[index].start_date).format("DD/MM/YYYY")}` + 
       (moment(dataLeave.RawData[index].start_date).isSame(moment(dataLeave.RawData[index].end_date)) 
         ? '' 
         : ` - ${moment(dataLeave.RawData[index].end_date).format("DD/MM/YYYY")}`),
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowTable_2.push(rowItem);
  }

  const dataTable_2 = {
    columns: [
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่อพนักงาน",
        field: "emp_name",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ประเภทการลา",
        field: "leave_type",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "วันที่ลา",
        field: "leave_date",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
    ],
    rows: rowTable_2,
  };

  const businessLeaveCount = dataLeave.RawData.filter((item) => {
    return item.emp_status_id === "33634864-0565-4f8b-9565-415e4ac4ace8";
  }).reduce((a, b) => {
    const daysToAdd =
      b.leave_type === 0
        ? moment(b.end_date).diff(b.start_date, "day") + 0.5
        : moment(b.end_date).diff(b.start_date, "day") + 1;
    return a + daysToAdd;
  }, 0);

  const sickLeaveCount = dataLeave.RawData.filter((item) => {
    return item.emp_status_id === "7ab619b9-98f9-41ac-a2c9-09e5217d7c64";
  }).reduce((a, b) => {
    const daysToAdd =
      b.leave_type === 0
        ? moment(b.end_date).diff(b.start_date, "day") + 0.5
        : moment(b.end_date).diff(b.start_date, "day") + 1;
    return a + daysToAdd;
  }, 0);

  const takeAnnualLeaveCount = dataLeave.RawData.filter((item) => {
    return item.emp_status_id === "51062121-eb22-433b-adc1-58f260102b97";
  }).reduce((a, b) => {
    const daysToAdd =
      b.leave_type === 0
        ? moment(b.end_date).diff(b.start_date, "day") + 0.5
        : moment(b.end_date).diff(b.start_date, "day") + 1;
    return a + daysToAdd;
  }, 0);

  const pieChart_2 = {
    labels: ["ลากิจ", "ลาป่วย", "ลาพักร้อน"],
    datasets: [
      {
        data: [businessLeaveCount, sickLeaveCount, takeAnnualLeaveCount],
        backgroundColor: ["#a3f1ff", "#ffaddd", "#4ee695"],
        borderColor: ["white", "white", "white"],
        borderWidth: 2,
      },
    ],
  };

  const rowTable_3 = [];
  const sortRowTable_3 = [];

  for (let index = 0; index < dataEmp.empRowTable_3.length; index += 1) {
    const businessLeaveCount = dataLeave.RawData.filter((item) => {
      return (
        item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
        item.emp_status_id === "33634864-0565-4f8b-9565-415e4ac4ace8"
      );
    }).reduce(
      (a, b) => a + moment(b.end_date).diff(b.start_date, "day") + 1,
      0
    );

    const sickLeaveCount = dataLeave.RawData.filter((item) => {
      return (
        item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
        item.emp_status_id === "7ab619b9-98f9-41ac-a2c9-09e5217d7c64"
      );
    }).reduce(
      (a, b) => a + moment(b.end_date).diff(b.start_date, "day") + 1,
      0
    );

    const takeAnnualLeaveCount = dataLeave.RawData.filter((item) => {
      return (
        item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
        item.emp_status_id === "51062121-eb22-433b-adc1-58f260102b97"
      );
    }).reduce(
      (a, b) => a + moment(b.end_date).diff(b.start_date, "day") + 1,
      0
    );

    if (
      businessLeaveCount === 0 &&
      sickLeaveCount === 0 &&
      takeAnnualLeaveCount === 0
    ) {
      continue;
    }
    const rowItem = {};

    rowItem["emp_no"] = {
      label: dataEmp.empRowTable_3[index].emp_no,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["emp_name"] = {
      label: `${dataEmp.empRowTable_3[index].emp_name_th} (${dataEmp.empRowTable_3[index].emp_nickname})`,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["business_leave_count"] = {
      label: dataLeave.RawData.filter((item) => {
        return (
          item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
          item.emp_status_id === "33634864-0565-4f8b-9565-415e4ac4ace8"
        );
      }).reduce((a, b) => {
        const daysToAdd =
          b.leave_type === 0
            ? moment(b.end_date).diff(b.start_date, "day") + 0.5
            : moment(b.end_date).diff(b.start_date, "day") + 1;
        return a + daysToAdd;
      }, 0),
      style: { textAlign: "right", whiteSpace: "nowrap" },
    };

    rowItem["sick_leave_count"] = {
      label: dataLeave.RawData.filter((item) => {
        return (
          item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
          item.emp_status_id === "7ab619b9-98f9-41ac-a2c9-09e5217d7c64"
        );
      }).reduce((a, b) => {
        const daysToAdd =
          b.leave_type === 0
            ? moment(b.end_date).diff(b.start_date, "day") + 0.5
            : moment(b.end_date).diff(b.start_date, "day") + 1;
        return a + daysToAdd;
      }, 0),
      style: { textAlign: "right", whiteSpace: "nowrap" },
    };

    rowItem["take_annual_leave_count"] = {
      label: dataLeave.RawData.filter((item) => {
        return (
          item.emp_id === dataEmp.empRowTable_3[index].emp_id &&
          item.emp_status_id === "51062121-eb22-433b-adc1-58f260102b97"
        );
      }).reduce((a, b) => {
        const daysToAdd =
          b.leave_type === 0
            ? moment(b.end_date).diff(b.start_date, "day") + 0.5
            : moment(b.end_date).diff(b.start_date, "day") + 1;
        return a + daysToAdd;
      }, 0),
      style: { textAlign: "right", whiteSpace: "nowrap" },
    };

    rowItem["total_leave_day_count"] = {
      label: dataLeave.RawData.filter((item) => {
        return item.emp_id === dataEmp.empRowTable_3[index].emp_id;
      }).reduce((a, b) => {
        const daysToAdd =
          b.leave_type === 0
            ? moment(b.end_date).diff(b.start_date, "day") + 0.5
            : moment(b.end_date).diff(b.start_date, "day") + 1;
        return a + daysToAdd;
      }, 0),
      style: { textAlign: "right", whiteSpace: "nowrap" },
    };

    rowItem["total_leave_count"] = {
      label: dataLeave.RawData.filter((item) => {
        return item.emp_id === dataEmp.empRowTable_3[index].emp_id;
      }).reduce(
        (a, b) => a + moment(b.end_date).diff(b.start_date, "day") + 1,
        0
      ),
    };
    rowTable_3.push(rowItem);
    sortRowTable_3.push(rowItem);
  }

  const dataTable_3 = {
    columns: [
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่อพนักงาน",
        field: "emp_name",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ลากิจ",
        field: "business_leave_count",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ลาป่วย",
        field: "sick_leave_count",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ลาพักร้อน",
        field: "take_annual_leave_count",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "รวม",
        field: "total_leave_day_count",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
    ],
    rows: rowTable_3.sort(
      (a, b) =>
        b.total_leave_day_count.label - a.total_leave_day_count.label ||
        a.emp_no - b.emp_no
    ),
  };

  sortRowTable_3.sort((a, b) => {
    if (
      a.business_leave_count.label +
        a.sick_leave_count.label +
        a.take_annual_leave_count.label ===
      b.business_leave_count.label +
        b.sick_leave_count.label +
        b.take_annual_leave_count.label
    ) {
      if (a.emp_no.label === null) {
        return 1;
      }

      if (b.emp_no.label === null) {
        return -1;
      }

      if (a.emp_no.label === b.emp_no.label) {
        return 0;
      }

      return a.emp_no.label < b.emp_no.label ? -1 : 1;
    }

    return a.business_leave_count.label +
      a.sick_leave_count.label +
      a.take_annual_leave_count.label >
      b.business_leave_count.label +
        b.sick_leave_count.label +
        b.take_annual_leave_count.label
      ? -1
      : 1;
  });

  const verticalBarChart_1 = {
    labels: sortRowTable_3.map((item) => {
      return item.emp_no.label;
    }),
    datasets: [
      // {
      //   data: sortRowTable_3.map((item) => {
      //     return item.total_leave_day_count.label;
      //   }),
      //   backgroundColor: ["#FF597B"],
      //   borderColor: ["#FF597B"],
      //   borderWidth: 1,
      //   label: "จำนวนวันที่ลา",
      // },
      {
        data: sortRowTable_3.map((item) => {
          return item.business_leave_count.label;
        }),
        backgroundColor: ["#a3f1ff"],
        borderColor: ["#a3f1ff"],
        borderWidth: 1,
        label: "ลากิจ",
      },
      {
        data: sortRowTable_3.map((item) => {
          return item.sick_leave_count.label;
        }),
        backgroundColor: ["#ffaddd"],
        borderColor: ["#ffaddd"],
        borderWidth: 1,
        label: "ลาป่วย",
      },
      {
        data: sortRowTable_3.map((item) => {
          return item.take_annual_leave_count.label;
        }),
        backgroundColor: ["#4ee695"],
        borderColor: ["#4ee695"],
        borderWidth: 1,
        label: "ลาพักร้อน",
      },
    ],
  };

  const rowTable_4 = [];
  for (let index = 0; index < dataEmp.empRowTable_4_5.length; index += 1) {
    const rowItem = {};

    let belowTime = dataTimeAtt.RawData.filter((item) => {
      return (
        item.emp_id === dataEmp.empRowTable_4_5[index].emp_id &&
        item.normal_hours < 480 &&
        !item.emp_status_leave_id
      );
    });

    if (
      belowTime.length == 0 ||
      !belowTime.reduce((a, b) => a + (480 - Number(b.normal_hours)), 0)
    ) {
      continue;
    }

    rowItem["emp_no"] = {
      label: dataEmp.empRowTable_4_5[index].emp_no,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["emp_name"] = {
      label: `${dataEmp.empRowTable_4_5[index].emp_name_th} (${dataEmp.empRowTable_4_5[index].emp_nickname})`,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["total_time_min"] = {
      label: parseFloat(
        belowTime.length !== 0
          ? belowTime.reduce((a, b) => a + (480 - Number(b.normal_hours)), 0)
          : 0
      ).toFixed(2),
      style: { textAlign: "right" },
    };

    rowItem["avg_time_min"] = {
      label: parseFloat(
        belowTime.length !== 0
          ? belowTime.reduce((a, b) => a + (480 - Number(b.normal_hours)), 0) /
              belowTime.length
          : 0
      ).toFixed(2),
      style: { textAlign: "right" },
    };

    rowItem["avg_time_hour"] = {
      label: parseFloat(
        belowTime.length !== 0
          ? belowTime.reduce((a, b) => a + (480 - Number(b.normal_hours)), 0) /
              belowTime.length /
              60
          : 0
      ).toFixed(2),
      style: { textAlign: "right" },
    };

    rowTable_4.push(rowItem);
  }

  const dataTable_4 = {
    columns: [
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่อพนักงาน",
        field: "emp_name",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "รวมเวลา (นาที)",
        field: "total_time_min",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "เฉลี่ย (นาที)",
        field: "avg_time_min",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "เฉลี่ย (ชั่วโมง)",
        field: "avg_time_hour",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
    ],
    rows: rowTable_4.sort((a, b) => {
      if (a.avg_time_hour.label === b.avg_time_hour.label) {
        if (a.emp_no.label === null) {
          return 1;
        }

        if (b.emp_no.label === null) {
          return -1;
        }

        if (a.emp_no.label === b.emp_no.label) {
          return 0;
        }

        return a.emp_no.label < b.emp_no.label ? -1 : 1;
      }

      return a.avg_time_hour.label > b.avg_time_hour.label ? -1 : 1;
    }),
  };

  const rowTable_5 = [];
  for (let index = 0; index < dataEmp.empRowTable_4_5.length; index += 1) {
    const rowItem = {};

    let allTime = dataTimeAtt.RawData.filter((item) => {
      return item.emp_id === dataEmp.empRowTable_4_5[index].emp_id;
    });
    let sumTotalDate = 0,
      total_no_late_day_att = 0,
      total_late_day_att = 0,
      sum_avg_day_work_table = [];
    // if (dataEmp.empRowTable_4_5[index].emp_id === 'c954fcdb-89fc-4f56-9257-453c4fa891cf') {
    //   console.log('dream: ', allTime);
    // }
    for (let item of allTime) {
      if (
        item.leave_type === 0 &&
        item.emp_status_leave_id != "ee11eac2-23f7-4844-aba3-152ebe82c63a"
      ) {
        sumTotalDate += 0.5;
        sum_avg_day_work_table.push(item);
        if (item.rate_time) {
          total_late_day_att += 0.5;
        } else {
          total_no_late_day_att += 0.5;
        }
      } else if (item.leave_type === 1) {
        sumTotalDate += 1;
      } else {
        sumTotalDate += 1;
        sum_avg_day_work_table.push(item);
        if (item.rate_time) {
          total_late_day_att += 1;
        } else {
          total_no_late_day_att += 1;
        }
      }
    }

    rowItem["emp_no"] = {
      label: dataEmp.empRowTable_4_5[index].emp_no,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["emp_name"] = {
      label: `${dataEmp.empRowTable_4_5[index].emp_name_th} (${dataEmp.empRowTable_4_5[index].emp_nickname})`,
      style: {
        whiteSpace: "nowrap",
      },
    };

    rowItem["total_day_att"] = {
      label: sumTotalDate,
      style: { textAlign: "right" },
    };

    rowItem["avg_time_hour"] = {
      label: parseFloat(
        sum_avg_day_work_table.length !== 0
          ? sum_avg_day_work_table.reduce(
              (a, b) => a + Number(b.normal_hours),
              0
            ) /
              sumTotalDate /
              60
          : 0
      ).toFixed(2),
      style: { textAlign: "right" },
    };

    let allLateTime = dataTimeAtt.RawData.filter((item) => {
      return (
        item.emp_id === dataEmp.empRowTable_4_5[index].emp_id &&
        item.rate_time > 0
      );
    });

    rowItem["total_no_late_day_att"] = {
      label: total_no_late_day_att,
      /* allLateTime.length !== 0
          ? (allTime.length !== 0 ? allTime.length : 0) - allLateTime.length
          : allTime.length !== 0
            ? allTime.length
            : 0, */
    };

    rowItem["total_late_day_att"] = {
      label: total_late_day_att, // allLateTime.length !== 0 ? allLateTime.length : 0,
    };

    rowTable_5.push(rowItem);
  }

  const sortedRowTable = rowTable_5.sort(
    (a, b) => b.total_day_att.label - a.total_day_att.label
  );
  const horizontalBarChart_1 = {
    labels: sortedRowTable.map((item) => {
      return item.emp_no.label;
    }),
    datasets: [
      {
        data: sortedRowTable.map((item) => {
          return item.total_day_att.label;
        }),
        backgroundColor: ["#a3f1ff"],
        borderColor: ["#a3f1ff"],
        borderWidth: 1,
        label: "จำนวนวันที่มาทำงาน",
      },
    ],
  };

  const sortedRowLateDateTable = rowTable_5.sort(
    (a, b) => b.total_late_day_att.label - a.total_late_day_att.label
  );

  const horizontalBarChart_2 = {
    labels: sortedRowLateDateTable.map((item) => {
      return item.emp_no.label;
    }),
    datasets: [
      {
        data: sortedRowLateDateTable.map((item) => {
          return item.total_no_late_day_att.label;
        }),
        backgroundColor: ["#00c297"],
        borderColor: ["#00c297"],
        borderWidth: 1,
        label: "จำนวนวันที่ไม่สาย",
      },
      {
        data: sortedRowLateDateTable.map((item) => {
          return item.total_late_day_att.label;
        }),
        backgroundColor: ["#ff9393"],
        borderColor: ["#ff9393"],
        borderWidth: 1,
        label: "จำนวนวันที่สาย",
      },
    ],
  };

  const dataTable_5 = {
    columns: [
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "ชื่อพนักงาน",
        field: "emp_name",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "วันทำงาน",
        field: "total_day_att",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
      {
        label: "เฉลี่ย (ชั่วโมง)",
        field: "avg_time_hour",
        style: {
          backgroundColor: "#c0e7ff",
          color: "white",
          whiteSpace: "nowrap",
          color: "#000000",
        },
      },
    ],
    rows: rowTable_5.sort((a, b) => {
      return (
        (Number(b.avg_time_hour.label || 0) - Number(a.avg_time_hour.label || 0)) || 
        a.emp_no.label - b.emp_no.label
      )
      // if (a.avg_time_hour.label === b.avg_time_hour.label) {
      //   if (a.emp_no.label === null) {
      //     return 1;
      //   }

      //   if (b.emp_no.label === null) {
      //     return -1;
      //   }

      //   if (a.emp_no.label === b.emp_no.label) {
      //     return 0;
      //   }

      //   return a.emp_no.label < b.emp_no.label ? -1 : 1;
      // }

      // return b.avg_time_hour.label > a.avg_time_hour.label ? -1 : 1;
    }),
  };

  async function clearSearchData() {
    const currentDate = new Date();
  
    // const firstDayOfWeek = new Date(
    //   currentDate.setDate(
    //     currentDate.getDate() -
    //       currentDate.getDay() +
    //       (currentDate.getDay() === 0 ? -6 : 1)
    //   )
    // );    
  
    // let lastDayOfWeek = new Date(
    //   currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5)
    // );
  
    let firstDayOfWeek =  new Date(moment().startOf("month"));
    let lastDayOfWeek =  new Date(moment().endOf("month"));
    
  
    
    // if (lastDayOfWeek > today) {
    //   lastDayOfWeek = today;
    // }
  
    setDateRange([firstDayOfWeek, lastDayOfWeek]);
    [startDate, endDate] = [firstDayOfWeek, lastDayOfWeek];
  
    setDateForReport({
      start_date: startDate,
      end_date: endDate,
    });
    dateForReport.start_date = startDate;
    dateForReport.end_date = endDate;
  
    setSelectedEmpListMain([]);
    selectedEmpListMain = [];
  
    setSelectedEmpListToDo([]);
    selectedEmpListToDo = [];
  
    setSelectedEmpTypeList([]);
    selectedEmpTypeList = [];
  
    await FilterTaskDashboard("clear");
    await FilterLeaveDashboard("clear");
    await FilterTimeAttendanceDashboard("clear");
  }
  

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  HR Dashboard
                  {/*          Welcome {user.sub} */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">HR Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card">
            <div className="card-body">
              <div
                className="row"
                style={{ margin: "0px 0px 15px 0px", alignItems: "flex-end" }}
              >
                {/* <div class="form-group">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_employee_type"
                    // value={inputData.assign_by_id}
                    // onChange={(e) => {
                    //   setInputData({
                    //     ...inputData,
                    //     assign_by_id: e.target.value,
                    //   });
                    // }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {employeeData.map((item) => {
                      return (
                        <option value={item.emp_id}>{item.emp_name_th}</option>
                      );
                    })}
                  </select>
                  <label htmlFor="">รหัสพนักงาน</label>
                </div> */}

                <div className="col-12 col-md-6 col-xl-6">
                  <div style={{ padding: "10px 0px" }}>
                    <label
                      style={{
                        margin: "0px 0px 0px 2px",
                      }}
                    >
                      รหัสพนักงานผู้มอบหมายงาน
                    </label>
                    <MultiSelect
                      hasSelectAll={false}
                      options={selectOptionEmp}
                      value={selectedEmpListToDo}
                      onChange={setSelectedEmpListToDo}
                      labelledBy="Select"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-6">
                  <div style={{ padding: "10px 0px" }}>
                    <label
                      style={{
                        margin: "0px 0px 0px 2px",
                      }}
                    >
                      รหัสพนักงาน
                    </label>
                    <MultiSelect
                      hasSelectAll={false}
                      options={selectOptionEmp}
                      value={selectedEmpListMain}
                      onChange={setSelectedEmpListMain}
                      labelledBy="Select"
                    />
                    {/* <label
                    style={{
                      fontSize: "20px",
                      position: "absolute",
                      top: "-22px",
                      left: "8px",
                      transform: "translateY(30px)",
                    }}
                    htmlFor=""
                  >
                    รหัสพนักงาน
                  </label> */}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                  <div style={{ padding: "10px 0px" }}>
                    <label
                      style={{
                        margin: "0px 0px 0px 2px",
                      }}
                    >
                      ประเภทพนักงาน
                    </label>
                    <MultiSelect
                      hasSelectAll={false}
                      options={selectOptionEmpTypeList}
                      value={selectedEmpTypeList}
                      onChange={setSelectedEmpTypeList}
                      labelledBy="Select"
                    />
                    {/* <label
                    style={{
                      fontSize: "20px",
                      position: "absolute",
                      top: "-22px",
                      left: "8px",
                      transform: "translateY(30px)",
                    }}
                    htmlFor=""
                  >
                    รหัสพนักงาน
                  </label> */}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                  <div style={{ padding: "10px 0px" }}>
                    <label
                      style={{
                        margin: "0px 0px 0px 2px",
                      }}
                    >
                      วันที่
                    </label>
                    <DatePicker
                      wrapperClassName="w-100"
                      locale="th"
                      id="text_start_work"
                      dateFormat={"dd-MM-yyyy"}
                      value={`${moment(startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(endDate).format("DD/MM/YYYY")}`}
                      selected={startDate}
                      onChange={async (update) => {
                        setDateRange(update);
                      }}
                      shouldCloseOnSelect={
                        !(!startDate || Boolean(startDate && endDate))
                      }
                      
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange={true} 
                      // maxDate={today}
                      // isClearable={true}
                      showMonthDropdown
                      showYearDropdown
                      disabledKeyboardNavigation
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      // todayButton="Today"
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
                      
                <div className="col-6 col-md-3 col-xl-2">
                  <div style={{ padding: "10px 0px" }}>
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-primary"
                      onClick={async () => {
                        setDateForReport({
                          start_date: startDate,
                          end_date: endDate,
                        });
                        dateForReport.start_date = startDate;
                        dateForReport.end_date = endDate;
                        await FilterTaskDashboard("search");
                        await FilterLeaveDashboard("search");
                        await FilterTimeAttendanceDashboard("search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-3 col-xl-2">
                  <div style={{ padding: "10px 0px" }}>
                    <button
                      type="button"
                      id="btn_clear"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        clearSearchData();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-3 col-xl-2">
                  <div style={{ padding: "10px 0px" }}>
                    <button
                      type="button"
                      id="btn_clear"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        downloadExcelDashboard();
                      }}
                    >
                      Export Xlsx
                    </button>
                  </div>
                </div>
              </div>

              <div className="row" style={{ margin: "0px 0px 20px 0px" }}>
                <BarLine
                  Text={"ข้อมูล To Do"}
                  BackgroundColor={"#01408f"}
                  Color={"white"}
                />
              </div>

              <div
                className="row customScroll-1 warpRow-1"
                style={{ margin: "30px 0px 25px 0px" }}
              >
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"จำนวนงานทั้งหมด"}
                    Text2={dataToDo.TotalToDo}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #fff281",
                      backgroundColor: "#fff281",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"จำนวนงานที่ผ่าน"}
                    Text2={dataToDo.PassToDo}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #7ce6ae",
                      backgroundColor: "#7ce6ae",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"จำนวนงานที่ไม่ผ่าน"}
                    Text2={dataToDo.FailToDo}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #ff9e9e",
                      backgroundColor: "#ff9e9e",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"เปอร์เซ็นต์งานผ่าน"}
                    Text2={`${parseFloat(dataToDo.PercentPassToDo).toFixed(
                      2
                    )}%`}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #7ce6ae",
                      backgroundColor: "#7ce6ae",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"เปอร์เซ็นต์งานไม่ผ่าน"}
                    Text2={`${parseFloat(dataToDo.PercentFailToDo).toFixed(
                      2
                    )}%`}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #ff9e9e",
                      backgroundColor: "#ff9e9e",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-2 cardCountSize">
                  <CardCount
                    Text1={"จำนวนที่มีการแก้ไข"}
                    Text2={dataToDo.FixToDo}
                    ClassName="textWhite"
                    Style={{
                      border: "solid 3px #fff281",
                      backgroundColor: "#fff281",
                      justifyContent: "center",
                      color: "#000000",
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-4 col-xl-3"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <PieChart
                    Text={"งานที่สำเร็จ VS งานที่ไม่สำเร็จ"}
                    DataChart={pieChart_1}
                  />
                </div>
                <div
                  className="col-12 col-md-8 col-xl-9"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <CardTable
                    Text={"รายละเอียดงานที่มีการแก้ไขและงานที่ค้างอยู่"}
                    DataTable={dataTable_1}
                  />
                </div>
              </div>

              <div className="row" style={{ margin: "0px 0px 20px 0px" }}>
                <BarLine
                  Text={"ข้อมูลการลา"}
                  BackgroundColor={"#01408f"}
                  Color={"white"}
                />
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="row">
                    <div className="col-12">
                      <div style={{ margin: "0px 0px 30px 0px" }}>
                        <CardTable
                          Text={"ข้อมูลการลา"}
                          DataTable={dataTable_2}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-12 col-md-9 col-xl-6">
                      <div style={{ margin: "0px 0px 30px 0px" }}>
                        <PieChart
                          Text={"สรุปจำนวนวันลาทั้งหมด"}
                          DataChart={pieChart_2}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 col-xl-6"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <CardTable
                    Text={"สรุปจำนวนวันลาที่ใช้ไป"}
                    DataTable={dataTable_3}
                    maxHeight={"950px"}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <VerticalBarChart
                    Text={"ข้อมูลการลาแบ่งตามพนักงาน"}
                    DataChart={verticalBarChart_1}
                  />
                </div>
              </div>

              <div className="row" style={{ margin: "0px 0px 20px 0px" }}>
                <BarLine
                  Text={"ข้อมูลการเข้างาน"}
                  BackgroundColor={"#01408f"}
                  Color={"white"}
                />
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-6"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <HorizontalBarChart
                    Text={"จำนวนวันที่มาทำงานแบ่งตามพนักงาน"}
                    DataChart={horizontalBarChart_1}
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-xl-6"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <Stack100HorizontalBarChart
                    Text={"จำนวนวันที่มาตรงเวลาและมาสายแบ่งตามพนักงาน"}
                    DataChart={horizontalBarChart_2}
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-6"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <CardTable
                    Text={"สรุปรายชื่อพนักงานที่ทำงานไม่ครบ 8 ชั่วโมง"}
                    DataTable={dataTable_4}
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-xl-6"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  <CardTable
                    Text={"สรุปการทำงานทั้งหมดแบ่งตามพนักงาน"}
                    DataTable={dataTable_5}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Dashboard_hr_ttt;
