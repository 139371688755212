import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../../../component/Footter";
import Header from "../../../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../../../Utils/Common";
import Select from "react-select";
import { userdefault_img, logoTTT } from "../../../../routes/imgRoute/imgUrl";
import moment from "moment";
import "../../../../assets/css/add-on-2.css";

import Assign_Work_Card from "../../Manage_Component/Assign_Work_Card";
import PMRP_NoImage from "../../../../assets/img/Landing_Page/PMRP-Noimage.png";
import TTT_Person from "../../../../assets/img/booking/Person_Default.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "./Assign_Work.css";

function Assign_Work() {
  const closeModalRef = useRef();
  const [mode, setMode] = useState({ data: "table" });

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  const [employeeData, setEmployeeData] = useState({ data: [] });
  const [assignData, setAssignData] = useState({ data: [] });

  const [teamData, setTeamData] = useState({ data: [] });
  const [evaData, setEvaData] = useState({ data: [] });

  const [allWeek, setAllWeek] = useState(1);
  const [saveWeek, setSaveWeek] = useState(1);
  const [saveYear, setSaveYear] = useState(1);
  const [showWeek, setShowWeek] = useState(1);
  const [showYear, setShowYear] = useState(1);
  const [showDate, setShowDate] = useState({
    startDate: null,
    startMonth: null,
    endDate: null,
    endMonth: null,
    year: null,
  });

  const [inputData, setInputData] = useState({
    assign_by_id: null,
    assign_to_id: null,
    task: null,
    show_passed: false,
  });

  const [dupInputData, setDupInputData] = useState({
    assign_by_id: null,
    assign_to_id: null,
    task: null,
    show_passed: false,
  });

  // const [checkTab, setCheckTab] = useState("callBackUser");
  let [callBackUser, setCallBackUser] = useState("2");

  // console.log('callBackUser', callBackUser)
  const [saveCurrentDate, setSaveCurrentDate] = useState({
    startDate: null,
    endDate: null,
    week: 0,
    year: 0,
  });

  const [employeeOption, setEmployeeOption] = useState({ data: [] });

  const [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState({
    data: [],
  });

  const [tmIntm, settmIntm] = useState({ data: [] });
  const [taskByEmpList, setTaskByEmpList] = useState({ data: {} });
  const [currentTasks, setCurrentTasks] = useState({ data: {} });
  const [tableByEmpList, setTableByEmpList] = useState({ data: {} });
  const [filteredData, setFilteredData] = useState({ data: {} });
  const [headTeam, setHeadTeam] = useState([]);

  const [noteModalData, setNoteModalData] = useState({
    is_open: false,
    week_status_id: "",
    employee_id: "",
    id: "",
    note: "",
    is_new: true,
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const getAssignToNameLength = (data) => {
    let count = 0;
    const seenNames = new Set();

    data.forEach((item) => {
      if (item.assign_to_name !== item.assign_by_name) {
        if (!seenNames.has(item.assign_to_name)) {
          seenNames.add(item.assign_to_name);
          count++;
        } else {
          count++;
        }
      }
    });

    return count;
  };

  function DistanceStatus({ data }) {
    return (
      <div style={{ display: "flex", textAlign: "left" }}>
        <div
          style={{
            background: `${
              data.assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                ? "#AEAEAE"
                : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#FF415C"
                : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#3579F6"
                : "#E9B500"
            }`,
            borderRadius: "28px",
            width: "25px",
            height: "43px",
            flexShrink: "0",
          }}
        />

        <div style={{ paddingLeft: "10px" }}>
          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_by_status_id ===
                "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "16px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {data.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
              ? "SUCCESSED TASK"
              : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "LATE TASK"
              : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "FUTURE TASK"
              : "CURRENT TASK"}
          </span>

          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_by_status_id ===
                "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {moment(data.due_date).locale("en").format("MMMM DD, YYYY")}
          </span>
        </div>
      </div>
    );
  }

  function tableData(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["task"] = (
      <>
        <div style={{ textAlign: "left", fontWeight: "700" }}>
          {assignData.data[index].task}
        </div>
        <div
          style={{
            textAlign: "left",
            maxWidth: "500px",
            whiteSpace: "pre-line",
          }}
        >
          {assignData.data[index].task_detail}
        </div>
      </>
    );
    rowItem["searchTask"] = assignData.data[index].task;
    rowItem["searchTaskDetail"] = assignData.data[index].task_detail;
    // rowItem["due_date"] = assignData.data[index].due_date
    //   ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
    //   : null;
    rowItem["searchDueDate"] = assignData.data[index].due_date
      ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
      : "";
    rowItem["assign_by"] = `${assignData.data[index].assign_by_no || ""} ${
      assignData.data[index].assign_by_nickname
        ? "( " + assignData.data[index].assign_by_nickname + " )"
        : ""
    } `;
    rowItem["assign_to"] = `${assignData.data[index].assign_to_no || ""} ${
      assignData.data[index].assign_to_nickname
        ? "( " + assignData.data[index].assign_to_nickname + " )"
        : ""
    } `;
    rowItem["created_date"] = assignData.data[index].created_date
      ? moment(assignData.data[index].created_date).format("DD-MM-YYYY")
      : null;
    rowItem["status"] =
      assignData.data[index].assign_by_status_name +
      (assignData.data[index].task_count === 0
        ? ""
        : ` ( ${assignData.data[index].task_count} )`);

    if (assignData.data[index].assign_by_id === getUser().fup) {
      if (
        assignData.data[index].assign_by_status_id ===
        "ffaa9180-e258-4aaa-96ab-afd4f991a572"
      ) {
        rowItem["due_date"] = (
          <>
            {assignData.data[index].due_date
              ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
              : null}
            {assignData.data[index].task_count === 0 ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ width: "100%" }} className="mr-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-secondary"
                      style={{ minWidth: "60px" }}
                      disabled
                    >
                      Pass
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-secondary"
                      style={{ minWidth: "60px" }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "auto" }}>
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-danger"
                      style={{ minWidth: "60px" }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        );
      }

      if (
        assignData.data[index].assign_by_status_id ===
        "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
      ) {
        rowItem["due_date"] = (
          <>
            {assignData.data[index].due_date
              ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
              : null}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-success"
                  style={{ minWidth: "60px" }}
                  onClick={() =>
                    CheckWork({
                      task_id: assignData.data[index].task_id,
                      assign_to_status_id:
                        "22bb6045-d8b6-44dd-8f2f-be7330c17aa9",
                      assign_by_status_id:
                        "f46730e7-9980-46b5-a4e5-7967550e4ea2",
                      task_count: assignData.data[index].task_count,
                      week: saveCurrentDate.week,
                      year: saveCurrentDate.year,
                    })
                  }
                >
                  Pass
                </button>
              </div>
              <div style={{ width: "100%" }} className="ml-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "60px" }}
                  onClick={() =>
                    CheckWork({
                      task_id: assignData.data[index].task_id,
                      assign_to_status_id:
                        assignData.data[index].task_count === 0 ||
                        assignData.data[index].task_count === 1
                          ? "53140a82-3fdb-44d8-a726-77492c63c800"
                          : "dd65b3a6-9946-491f-8fd9-688113f52daf",
                      assign_by_status_id:
                        "ffaa9180-e258-4aaa-96ab-afd4f991a572",
                      task_count: assignData.data[index].task_count + 1,
                    })
                  }
                >
                  Fail
                </button>
              </div>
            </div>
          </>
        );
      }

      if (
        assignData.data[index].assign_by_status_id ===
        "f46730e7-9980-46b5-a4e5-7967550e4ea2"
      ) {
        rowItem["due_date"] = (
          <>
            {assignData.data[index].due_date
              ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
              : null}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "auto" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-success"
                  style={{ minWidth: "60px" }}
                  disabled
                >
                  Passed
                </button>
              </div>
            </div>
          </>
        );
      }

      rowItem["management"] = (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              href={
                "/Human_Resource/to_do_list/assign_work/read/" +
                assignData.data[index].task_id
              }
              id="btn_read"
              key={assignData.data[index].task_id}
              className="btn btn-xs "
              onClick={() => {
                sessionStorage.setItem(
                  "state_filter_assign_work",
                  JSON.stringify({
                    assign_by_id: dupInputData.assign_by_id,
                    assign_to_id: dupInputData.assign_to_id,
                    task: dupInputData.task,
                    show_passed: inputData.show_passed,
                    callBackUser: callBackUser,
                    mode: mode.data,
                  })
                );
              }}
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>

          {assignData.data[index].assign_by_status_id !==
          "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
            <>
              {" "}
              <div className="col-xl-3 col-md-3 col-xs-3 ">
                <a
                  href={
                    "/Human_Resource/to_do_list/assign_work/edit/" +
                    assignData.data[index].task_id
                  }
                  id="btn_edit"
                  key={assignData.data[index].task_id}
                  className=" btn btn-xs "
                  onClick={() => {
                    sessionStorage.setItem(
                      "state_filter_assign_work",
                      JSON.stringify({
                        assign_by_id: dupInputData.assign_by_id,
                        assign_to_id: dupInputData.assign_to_id,
                        task: dupInputData.task,
                        show_passed: inputData.show_passed,
                        callBackUser: callBackUser,
                        mode: mode.data,
                      })
                    );
                  }}
                >
                  {"   "}
                  <i class="fas fa-pencil-alt"></i>
                  {"   "}
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-xs-3 ">
                <a
                  key={assignData.data[index].task_id}
                  id="btn_delete"
                  className=" btn btn-xs "
                  onClick={() => DeleteAssign(assignData.data[index].task_id)}
                >
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>{" "}
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      rowItem["due_date"] = (
        <>
          {assignData.data[index].due_date
            ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
            : null}
        </>
      );

      rowItem["management"] = (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              href={
                "/Human_Resource/to_do_list/assign_work/read/" +
                assignData.data[index].task_id
              }
              id="btn_read"
              key={assignData.data[index].task_id}
              className="btn btn-xs "
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
        </div>
      );
    }

    rowItem["task_due"] = <DistanceStatus data={assignData.data[index]} />;

    // if (
    //   assignData.data[index].assign_by_status_id ===
    //   "ffaa9180-e258-4aaa-96ab-afd4f991a572"
    // ) {
    //   rowItem["due_date"] = (
    //     <>
    //       {assignData.data[index].due_date
    //         ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
    //         : null}
    //       {assignData.data[index].task_count === 0 ? (
    //         <>
    //           <div style={{ display: "flex", justifyContent: "space-around" }}>
    //             <div style={{ width: "100%" }} className="mr-1">
    //               <button
    //                 type="button"
    //                 id="btn_search"
    //                 className="btn btn-block btn-secondary"
    //                 style={{ minWidth: "60px" }}
    //                 disabled
    //               >
    //                 Pass
    //               </button>
    //             </div>
    //             <div style={{ width: "100%" }} className="ml-1">
    //               <button
    //                 type="button"
    //                 id="btn_search"
    //                 className="btn btn-block btn-secondary"
    //                 style={{ minWidth: "60px" }}
    //                 disabled
    //               >
    //                 Fail
    //               </button>
    //             </div>
    //           </div>
    //         </>
    //       ) : (
    //         <>
    //           <div style={{ display: "flex", justifyContent: "center" }}>
    //             <div style={{ width: "auto" }}>
    //               <button
    //                 type="button"
    //                 id="btn_search"
    //                 className="btn btn-block btn-danger"
    //                 style={{ minWidth: "60px" }}
    //                 disabled
    //               >
    //                 Fail
    //               </button>
    //             </div>
    //           </div>
    //         </>
    //       )}
    //     </>
    //   );
    // }

    // if (
    //   assignData.data[index].assign_by_status_id ===
    //   "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
    // ) {
    //   rowItem["due_date"] = (
    //     <>
    //       {assignData.data[index].due_date
    //         ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
    //         : null}
    //       <div style={{ display: "flex", justifyContent: "space-around" }}>
    //         <div style={{ width: "100%" }} className="mr-1">
    //           <button
    //             type="button"
    //             id="btn_search"
    //             className="btn btn-block btn-success"
    //             style={{ minWidth: "60px" }}
    //             onClick={() =>
    //               CheckWork({
    //                 task_id: assignData.data[index].task_id,
    //                 assign_to_status_id: "22bb6045-d8b6-44dd-8f2f-be7330c17aa9",
    //                 assign_by_status_id: "f46730e7-9980-46b5-a4e5-7967550e4ea2",
    //                 task_count: assignData.data[index].task_count,
    //               })
    //             }
    //           >
    //             Pass
    //           </button>
    //         </div>
    //         <div style={{ width: "100%" }} className="ml-1">
    //           <button
    //             type="button"
    //             id="btn_search"
    //             className="btn btn-block btn-danger"
    //             style={{ minWidth: "60px" }}
    //             onClick={() =>
    //               CheckWork({
    //                 task_id: assignData.data[index].task_id,
    //                 assign_to_status_id:
    //                   assignData.data[index].task_count === 0 ||
    //                   assignData.data[index].task_count === 1
    //                     ? "53140a82-3fdb-44d8-a726-77492c63c800"
    //                     : "dd65b3a6-9946-491f-8fd9-688113f52daf",
    //                 assign_by_status_id: "ffaa9180-e258-4aaa-96ab-afd4f991a572",
    //                 task_count: assignData.data[index].task_count + 1,
    //               })
    //             }
    //           >
    //             Fail
    //           </button>
    //         </div>
    //       </div>
    //     </>
    //   );
    // }

    // if (
    //   assignData.data[index].assign_by_status_id ===
    //   "f46730e7-9980-46b5-a4e5-7967550e4ea2"
    // ) {
    //   rowItem["due_date"] = (
    //     <>
    //       {assignData.data[index].due_date
    //         ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
    //         : null}
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <div style={{ width: "auto" }}>
    //           <button
    //             type="button"
    //             id="btn_search"
    //             className="btn btn-block btn-success"
    //             style={{ minWidth: "60px" }}
    //             disabled
    //           >
    //             Passed
    //           </button>
    //         </div>
    //       </div>
    //     </>
    //   );
    // }

    // rowItem["management"] = (
    //   <div className="row" style={{ flexWrap: "nowrap" }}>
    //     <div className="col-xl-3 col-md-3 col-xs-3 ">
    //       <a
    //         href={
    //           "/Human_Resource/to_do_list/assign_work/read/" +
    //           assignData.data[index].task_id
    //         }
    //         id="btn_read"
    //         key={assignData.data[index].task_id}
    //         className="btn btn-xs "
    //       >
    //         <i class="fas fa-eye"></i>
    //         {"   "}
    //       </a>
    //     </div>

    //     {assignData.data[index].assign_by_status_id !==
    //     "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
    //       <>
    //         {" "}
    //         <div className="col-xl-3 col-md-3 col-xs-3 ">
    //           <a
    //             href={
    //               "/Human_Resource/to_do_list/assign_work/edit/" +
    //               assignData.data[index].task_id
    //             }
    //             id="btn_edit"
    //             key={assignData.data[index].task_id}
    //             className=" btn btn-xs "
    //           >
    //             {"   "}
    //             <i class="fas fa-pencil-alt"></i>
    //             {"   "}
    //           </a>
    //         </div>
    //         <div className="col-xl-3 col-md-3 col-xs-3 ">
    //           <a
    //             key={assignData.data[index].task_id}
    //             id="btn_delete"
    //             className=" btn btn-xs "
    //             onClick={() => DeleteAssign(assignData.data[index].task_id)}
    //           >
    //             <i class="fas fa-trash-alt"></i>
    //           </a>
    //         </div>{" "}
    //       </>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // );

    return rowItem;
  }

  const rowsData = [];
  for (let index = 0; index < assignData.data.length; index += 1) {
    rowsData.push(tableData(index));
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Task",
        field: "task",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Due Date",
        field: "due_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Assign by",
        field: "assign_by",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Assign to",
        field: "assign_to",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Create Date",
        field: "created_date",
        // sort: "asc",
        width: 50,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   // sort: "asc",
      //   width: 50,
      // },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Task Due",
        field: "task_due",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  async function TableByEmp() {
    const objLis = {};

    for (let teamMem of tmIntm.data) {
      const rowsData = [];
      let runIndex = 1;
      if (taskByEmpList.data[teamMem.id]) {
        for (
          let index = 0;
          index < taskByEmpList.data[teamMem.id].length;
          index += 1
        ) {
          const rowItem = {};
          if (
            teamMem.is_late &&
            new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
              new Date(taskByEmpList.data[teamMem.id][index].due_date).setHours(
                0,
                0,
                0,
                0
              )
          )
            continue;
          if (
            teamMem.is_current &&
            new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) <=
              new Date(taskByEmpList.data[teamMem.id][index].due_date).setHours(
                0,
                0,
                0,
                0
              ) &&
            new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) >=
              new Date(taskByEmpList.data[teamMem.id][index].due_date).setHours(
                0,
                0,
                0,
                0
              )
          )
            continue;
          if (
            teamMem.is_future &&
            new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
              new Date(taskByEmpList.data[teamMem.id][index].due_date).setHours(
                0,
                0,
                0,
                0
              )
          )
            continue;
          if (
            taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2" &&
            new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
              new Date(taskByEmpList.data[teamMem.id][index].due_date).setHours(
                0,
                0,
                0,
                0
              )
          )
            continue;
          rowItem["no"] = runIndex;
          runIndex++;
          rowItem["task"] = (
            <>
              <div style={{ textAlign: "left", fontWeight: "700" }}>
                {taskByEmpList.data[teamMem.id][index].task}
              </div>
              <div
                style={{
                  textAlign: "left",
                  maxWidth: "500px",
                  whiteSpace: "pre-line",
                }}
              >
                {taskByEmpList.data[teamMem.id][index].task_detail}
              </div>
            </>
          );
          rowItem["searchTask"] = taskByEmpList.data[teamMem.id][index].task;
          rowItem["searchTaskDetail"] =
            taskByEmpList.data[teamMem.id][index].task_detail;
          // rowItem["due_date"] = taskByEmpList.data[teamMem.id][index].due_date
          //   ? moment(taskByEmpList.data[teamMem.id][index].due_date).format("DD-MM-YYYY")
          //   : null;
          rowItem["searchDueDate"] = taskByEmpList.data[teamMem.id][index]
            .due_date
            ? moment(taskByEmpList.data[teamMem.id][index].due_date).format(
                "DD-MM-YYYY"
              )
            : "";
          rowItem["assign_by"] = `${
            taskByEmpList.data[teamMem.id][index].assign_by_no || ""
          } ${
            taskByEmpList.data[teamMem.id][index].assign_by_nickname
              ? "( " +
                taskByEmpList.data[teamMem.id][index].assign_by_nickname +
                " )"
              : ""
          } `;
          rowItem["assign_to"] = `${
            taskByEmpList.data[teamMem.id][index].assign_to_no || ""
          } ${
            taskByEmpList.data[teamMem.id][index].assign_to_nickname
              ? "( " +
                taskByEmpList.data[teamMem.id][index].assign_to_nickname +
                " )"
              : ""
          } `;
          rowItem["created_date"] = taskByEmpList.data[teamMem.id][index]
            .created_date
            ? moment(taskByEmpList.data[teamMem.id][index].created_date).format(
                "DD-MM-YYYY"
              )
            : null;
          rowItem["status"] =
            taskByEmpList.data[teamMem.id][index].assign_by_status_name +
            (taskByEmpList.data[teamMem.id][index].task_count === 0
              ? ""
              : ` ( ${taskByEmpList.data[teamMem.id][index].task_count} )`);

          if (
            taskByEmpList.data[teamMem.id][index].assign_by_id === getUser().fup
          ) {
            if (
              taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
              "ffaa9180-e258-4aaa-96ab-afd4f991a572"
            ) {
              rowItem["due_date"] = (
                <>
                  {taskByEmpList.data[teamMem.id][index].due_date
                    ? moment(
                        taskByEmpList.data[teamMem.id][index].due_date
                      ).format("DD-MM-YYYY")
                    : null}
                  {taskByEmpList.data[teamMem.id][index].task_count === 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div style={{ width: "100%" }} className="mr-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-secondary"
                            style={{ minWidth: "60px" }}
                            disabled
                          >
                            Pass
                          </button>
                        </div>
                        <div style={{ width: "100%" }} className="ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-secondary"
                            style={{ minWidth: "60px" }}
                            disabled
                          >
                            Fail
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ width: "auto" }}>
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-danger"
                            style={{ minWidth: "60px" }}
                            disabled
                          >
                            Fail
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            }

            if (
              taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
              "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
            ) {
              rowItem["due_date"] = (
                <>
                  {taskByEmpList.data[teamMem.id][index].due_date
                    ? moment(
                        taskByEmpList.data[teamMem.id][index].due_date
                      ).format("DD-MM-YYYY")
                    : null}
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ width: "100%" }} className="mr-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-success"
                        style={{ minWidth: "60px" }}
                        onClick={() =>
                          CheckWork({
                            task_id:
                              taskByEmpList.data[teamMem.id][index].task_id,
                            assign_to_status_id:
                              "22bb6045-d8b6-44dd-8f2f-be7330c17aa9",
                            assign_by_status_id:
                              "f46730e7-9980-46b5-a4e5-7967550e4ea2",
                            task_count:
                              taskByEmpList.data[teamMem.id][index].task_count,
                            week: saveCurrentDate.week,
                            year: saveCurrentDate.year,
                          })
                        }
                      >
                        Pass
                      </button>
                    </div>
                    <div style={{ width: "100%" }} className="ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-danger"
                        style={{ minWidth: "60px" }}
                        onClick={() =>
                          CheckWork({
                            task_id:
                              taskByEmpList.data[teamMem.id][index].task_id,
                            assign_to_status_id:
                              taskByEmpList.data[teamMem.id][index]
                                .task_count === 0 ||
                              taskByEmpList.data[teamMem.id][index]
                                .task_count === 1
                                ? "53140a82-3fdb-44d8-a726-77492c63c800"
                                : "dd65b3a6-9946-491f-8fd9-688113f52daf",
                            assign_by_status_id:
                              "ffaa9180-e258-4aaa-96ab-afd4f991a572",
                            task_count:
                              taskByEmpList.data[teamMem.id][index].task_count +
                              1,
                          })
                        }
                      >
                        Fail
                      </button>
                    </div>
                  </div>
                </>
              );
            }

            if (
              taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2"
            ) {
              rowItem["due_date"] = (
                <>
                  {taskByEmpList.data[teamMem.id][index].due_date
                    ? moment(
                        taskByEmpList.data[teamMem.id][index].due_date
                      ).format("DD-MM-YYYY")
                    : null}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "auto" }}>
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-success"
                        style={{ minWidth: "60px" }}
                        disabled
                      >
                        Passed
                      </button>
                    </div>
                  </div>
                </>
              );
            }

            rowItem["management"] = (
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    href={
                      "/Human_Resource/to_do_list/assign_work/read/" +
                      taskByEmpList.data[teamMem.id][index].task_id
                    }
                    id="btn_read"
                    key={taskByEmpList.data[teamMem.id][index].task_id}
                    className="btn btn-xs "
                    onClick={() => {
                      sessionStorage.setItem(
                        "state_filter_assign_work",
                        JSON.stringify({
                          assign_by_id: dupInputData.assign_by_id,
                          assign_to_id: dupInputData.assign_to_id,
                          task: dupInputData.task,
                          show_passed: inputData.show_passed,
                          callBackUser: callBackUser,
                          mode: mode.data,
                        })
                      );
                    }}
                  >
                    <i class="fas fa-eye"></i>
                    {"   "}
                  </a>
                </div>

                {taskByEmpList.data[teamMem.id][index].assign_by_status_id !==
                "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
                  <>
                    {" "}
                    <div className="col-xl-3 col-md-3 col-xs-3 ">
                      <a
                        href={
                          "/Human_Resource/to_do_list/assign_work/edit/" +
                          taskByEmpList.data[teamMem.id][index].task_id
                        }
                        id="btn_edit"
                        key={taskByEmpList.data[teamMem.id][index].task_id}
                        className=" btn btn-xs "
                        onClick={() => {
                          sessionStorage.setItem(
                            "state_filter_assign_work",
                            JSON.stringify({
                              assign_by_id: dupInputData.assign_by_id,
                              assign_to_id: dupInputData.assign_to_id,
                              task: dupInputData.task,
                              show_passed: inputData.show_passed,
                              callBackUser: callBackUser,
                              mode: mode.data,
                            })
                          );
                        }}
                      >
                        {"   "}
                        <i class="fas fa-pencil-alt"></i>
                        {"   "}
                      </a>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-3 ">
                      <a
                        key={taskByEmpList.data[teamMem.id][index].task_id}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={() =>
                          DeleteAssign(
                            taskByEmpList.data[teamMem.id][index].task_id
                          )
                        }
                      >
                        <i class="fas fa-trash-alt"></i>
                      </a>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          } else {
            rowItem["due_date"] = (
              <>
                {taskByEmpList.data[teamMem.id][index].due_date
                  ? moment(
                      taskByEmpList.data[teamMem.id][index].due_date
                    ).format("DD-MM-YYYY")
                  : null}
              </>
            );

            rowItem["management"] = (
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    href={
                      "/Human_Resource/to_do_list/assign_work/read/" +
                      taskByEmpList.data[teamMem.id][index].task_id
                    }
                    id="btn_read"
                    key={taskByEmpList.data[teamMem.id][index].task_id}
                    className="btn btn-xs "
                    onClick={() => {
                      sessionStorage.setItem(
                        "state_filter_assign_work",
                        JSON.stringify({
                          assign_by_id: dupInputData.assign_by_id,
                          assign_to_id: dupInputData.assign_to_id,
                          task: dupInputData.task,
                          show_passed: inputData.show_passed,
                          callBackUser: callBackUser,
                          mode: mode.data,
                        })
                      );
                    }}
                  >
                    <i class="fas fa-eye"></i>
                    {"   "}
                  </a>
                </div>
              </div>
            );
          }

          rowItem["task_due"] = (
            <DistanceStatus data={taskByEmpList.data[teamMem.id][index]} />
          );

          // if (
          //   taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
          //   "ffaa9180-e258-4aaa-96ab-afd4f991a572"
          // ) {
          //   rowItem["due_date"] = (
          //     <>
          //       {taskByEmpList.data[teamMem.id][index].due_date
          //         ? moment(taskByEmpList.data[teamMem.id][index].due_date).format("DD-MM-YYYY")
          //         : null}
          //       {taskByEmpList.data[teamMem.id][index].task_count === 0 ? (
          //         <>
          //           <div style={{ display: "flex", justifyContent: "space-around" }}>
          //             <div style={{ width: "100%" }} className="mr-1">
          //               <button
          //                 type="button"
          //                 id="btn_search"
          //                 className="btn btn-block btn-secondary"
          //                 style={{ minWidth: "60px" }}
          //                 disabled
          //               >
          //                 Pass
          //               </button>
          //             </div>
          //             <div style={{ width: "100%" }} className="ml-1">
          //               <button
          //                 type="button"
          //                 id="btn_search"
          //                 className="btn btn-block btn-secondary"
          //                 style={{ minWidth: "60px" }}
          //                 disabled
          //               >
          //                 Fail
          //               </button>
          //             </div>
          //           </div>
          //         </>
          //       ) : (
          //         <>
          //           <div style={{ display: "flex", justifyContent: "center" }}>
          //             <div style={{ width: "auto" }}>
          //               <button
          //                 type="button"
          //                 id="btn_search"
          //                 className="btn btn-block btn-danger"
          //                 style={{ minWidth: "60px" }}
          //                 disabled
          //               >
          //                 Fail
          //               </button>
          //             </div>
          //           </div>
          //         </>
          //       )}
          //     </>
          //   );
          // }

          // if (
          //   taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
          //   "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
          // ) {
          //   rowItem["due_date"] = (
          //     <>
          //       {taskByEmpList.data[teamMem.id][index].due_date
          //         ? moment(taskByEmpList.data[teamMem.id][index].due_date).format("DD-MM-YYYY")
          //         : null}
          //       <div style={{ display: "flex", justifyContent: "space-around" }}>
          //         <div style={{ width: "100%" }} className="mr-1">
          //           <button
          //             type="button"
          //             id="btn_search"
          //             className="btn btn-block btn-success"
          //             style={{ minWidth: "60px" }}
          //             onClick={() =>
          //               CheckWork({
          //                 task_id: taskByEmpList.data[teamMem.id][index].task_id,
          //                 assign_to_status_id: "22bb6045-d8b6-44dd-8f2f-be7330c17aa9",
          //                 assign_by_status_id: "f46730e7-9980-46b5-a4e5-7967550e4ea2",
          //                 task_count: taskByEmpList.data[teamMem.id][index].task_count,
          //               })
          //             }
          //           >
          //             Pass
          //           </button>
          //         </div>
          //         <div style={{ width: "100%" }} className="ml-1">
          //           <button
          //             type="button"
          //             id="btn_search"
          //             className="btn btn-block btn-danger"
          //             style={{ minWidth: "60px" }}
          //             onClick={() =>
          //               CheckWork({
          //                 task_id: taskByEmpList.data[teamMem.id][index].task_id,
          //                 assign_to_status_id:
          //                   taskByEmpList.data[teamMem.id][index].task_count === 0 ||
          //                   taskByEmpList.data[teamMem.id][index].task_count === 1
          //                     ? "53140a82-3fdb-44d8-a726-77492c63c800"
          //                     : "dd65b3a6-9946-491f-8fd9-688113f52daf",
          //                 assign_by_status_id: "ffaa9180-e258-4aaa-96ab-afd4f991a572",
          //                 task_count: taskByEmpList.data[teamMem.id][index].task_count + 1,
          //               })
          //             }
          //           >
          //             Fail
          //           </button>
          //         </div>
          //       </div>
          //     </>
          //   );
          // }

          // if (
          //   taskByEmpList.data[teamMem.id][index].assign_by_status_id ===
          //   "f46730e7-9980-46b5-a4e5-7967550e4ea2"
          // ) {
          //   rowItem["due_date"] = (
          //     <>
          //       {taskByEmpList.data[teamMem.id][index].due_date
          //         ? moment(taskByEmpList.data[teamMem.id][index].due_date).format("DD-MM-YYYY")
          //         : null}
          //       <div style={{ display: "flex", justifyContent: "center" }}>
          //         <div style={{ width: "auto" }}>
          //           <button
          //             type="button"
          //             id="btn_search"
          //             className="btn btn-block btn-success"
          //             style={{ minWidth: "60px" }}
          //             disabled
          //           >
          //             Passed
          //           </button>
          //         </div>
          //       </div>
          //     </>
          //   );
          // }

          // rowItem["management"] = (
          //   <div className="row" style={{ flexWrap: "nowrap" }}>
          //     <div className="col-xl-3 col-md-3 col-xs-3 ">
          //       <a
          //         href={
          //           "/Human_Resource/to_do_list/assign_work/read/" +
          //           taskByEmpList.data[teamMem.id][index].task_id
          //         }
          //         id="btn_read"
          //         key={taskByEmpList.data[teamMem.id][index].task_id}
          //         className="btn btn-xs "
          //       >
          //         <i class="fas fa-eye"></i>
          //         {"   "}
          //       </a>
          //     </div>

          //     {taskByEmpList.data[teamMem.id][index].assign_by_status_id !==
          //     "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
          //       <>
          //         {" "}
          //         <div className="col-xl-3 col-md-3 col-xs-3 ">
          //           <a
          //             href={
          //               "/Human_Resource/to_do_list/assign_work/edit/" +
          //               taskByEmpList.data[teamMem.id][index].task_id
          //             }
          //             id="btn_edit"
          //             key={taskByEmpList.data[teamMem.id][index].task_id}
          //             className=" btn btn-xs "
          //           >
          //             {"   "}
          //             <i class="fas fa-pencil-alt"></i>
          //             {"   "}
          //           </a>
          //         </div>
          //         <div className="col-xl-3 col-md-3 col-xs-3 ">
          //           <a
          //             key={taskByEmpList.data[teamMem.id][index].task_id}
          //             id="btn_delete"
          //             className=" btn btn-xs "
          //             onClick={() => DeleteAssign(taskByEmpList.data[teamMem.id][index].task_id)}
          //           >
          //             <i class="fas fa-trash-alt"></i>
          //           </a>
          //         </div>{" "}
          //       </>
          //     ) : (
          //       ""
          //     )}
          //   </div>
          // );

          rowsData.push(rowItem);
        }
      }

      const data = {
        columns: [
          {
            label: "No",
            field: "no",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Task",
            field: "task",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Due Date",
            field: "due_date",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Assign by",
            field: "assign_by",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Assign to",
            field: "assign_to",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Create Date",
            field: "created_date",
            // sort: "asc",
            width: 50,
          },
          // {
          //   label: "Action",
          //   field: "action",
          //   // sort: "asc",
          //   width: 50,
          // },
          {
            label: "Status",
            field: "status",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Task Due",
            field: "task_due",
            // sort: "asc",
            width: 50,
          },
          {
            label: "Management",
            field: "management",
            // sort: "asc",
            width: 50,
          },
        ],

        rows: rowsData,
      };

      objLis[teamMem.id] = data;
    }

    setTableByEmpList({
      data: objLis,
    });
    tableByEmpList.data = objLis;
  }

  useEffect(() => {
    // const newValue = "1";
    // if (callBackUser !== newValue) {
    //   setCallBackUser(newValue);
    // }
    TableByEmp();
  }, [dupInputData, inputData, mode]);

  const rowsDataEva = [];
  for (let index = 0; index < teamData.data.length; index += 1) {
    const eva = evaData.data.find((value) => {
      return value.employee_id === teamData.data[index].employee_id;
    });

    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["assign_to"] =
      eva?.emp_status_type_id === null || eva?.emp_status_type_id === undefined
        ? `${teamData.data[index].employee_name}`
        : `${teamData.data[index].employee_name} ( ${eva?.status_name} )`;

    // teamData.data[index].employee_type_status_id ===
    //   "6c4a34ff-f9d4-4f7e-91ae-5a90697b10cb"
    //     ? `${teamData.data[index].employee_name}`
    //     : `${teamData.data[index].employee_name} ( ${teamData.data[index].status_name} )`;

    rowItem["remark"] = (
      <div
        style={{
          textAlign: "left",
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {eva === undefined ? "" : eva.remark || ""}
      </div>
    );

    if (saveYear > moment(new Date()).year()) {
      rowItem["assignment"] = (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ width: "100%" }} className="mr-1 ml-1">
              <button
                type="button"
                id="btn_search"
                className="btn btn-block btn-dark"
                style={{ minWidth: "130px" }}
                disabled
              >
                Over Expectation
              </button>
            </div>
            <div style={{ width: "100%" }} className="mr-1 ml-1">
              <button
                type="button"
                id="btn_search"
                className="btn btn-block btn-dark"
                style={{ minWidth: "130px" }}
                disabled
              >
                Pass
              </button>
            </div>
            <div style={{ width: "100%" }} className="mr-1 ml-1">
              <button
                type="button"
                id="btn_search"
                className="btn btn-block btn-dark"
                style={{ minWidth: "130px" }}
                disabled
              >
                Fail
              </button>
            </div>
          </div>
        </>
      );
    } else if (saveYear === moment(new Date()).year()) {
      if (saveWeek > moment(new Date()).isoWeekday(1).week()) {
        rowItem["assignment"] = (
          <>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ width: "100%" }} className="mr-1 ml-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-dark"
                  style={{ minWidth: "130px" }}
                  disabled
                >
                  Over Expectation
                </button>
              </div>
              <div style={{ width: "100%" }} className="mr-1 ml-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-dark"
                  style={{ minWidth: "130px" }}
                  disabled
                >
                  Pass
                </button>
              </div>
              <div style={{ width: "100%" }} className="mr-1 ml-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-dark"
                  style={{ minWidth: "130px" }}
                  disabled
                >
                  Fail
                </button>
              </div>
            </div>
          </>
        );
      } else if (saveWeek === moment(new Date()).isoWeekday(1).week()) {
        if (eva === undefined) {
          rowItem["assignment"] = (
            <>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    data-toggle="modal"
                    data-target="#modal-sm2"
                    className="btn btn-block btn-success"
                    style={{ minWidth: "130px" }}
                    onClick={() => {
                      setNoteModalData({
                        is_open: true,
                        week_status_id: "7a232969-76c8-4133-bc0a-62079d01d10b",
                        employee_id: teamData.data[index].employee_id,
                        id: "",
                        note: "",
                        is_new: true,
                      });
                      // SaveEvaMember({
                      //   week_status_id: "7a232969-76c8-4133-bc0a-62079d01d10b",
                      //   employee_id: teamData.data[index].employee_id,
                      // });
                    }}
                  >
                    Over Expectation
                  </button>
                </div>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-warning"
                    data-toggle="modal"
                    data-target="#modal-sm2"
                    style={{ minWidth: "130px" }}
                    onClick={() => {
                      setNoteModalData({
                        is_open: true,
                        week_status_id: "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                        employee_id: teamData.data[index].employee_id,
                        id: "",
                        note: "",
                        is_new: true,
                      });
                      // SaveEvaMember({
                      //   week_status_id: "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                      //   employee_id: teamData.data[index].employee_id,
                      // });
                    }}
                  >
                    Pass
                  </button>
                </div>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-danger"
                    data-toggle="modal"
                    data-target="#modal-sm2"
                    style={{ minWidth: "130px" }}
                    onClick={() => {
                      setNoteModalData({
                        is_open: true,
                        week_status_id: "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                        employee_id: teamData.data[index].employee_id,
                        id: "",
                        note: "",
                        is_new: true,
                      });
                      // SaveEvaMember({
                      //   week_status_id: "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                      //   employee_id: teamData.data[index].employee_id,
                      // });
                    }}
                  >
                    Fail
                  </button>
                </div>
              </div>
            </>
          );
        } else {
          if (eva?.emp_status_type_id === null) {
            rowItem["assignment"] = (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {eva?.week_status_id ===
                  "7a232969-76c8-4133-bc0a-62079d01d10b" ? (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-success"
                          style={{ minWidth: "130px", opacity: "1" }}
                          disabled
                        >
                          Over Expectation
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-secondary"
                          data-toggle="modal"
                          data-target="#modal-sm2"
                          style={{ minWidth: "130px", opacity: "1" }}
                          onClick={() => {
                            setNoteModalData({
                              is_open: true,
                              week_status_id:
                                "7a232969-76c8-4133-bc0a-62079d01d10b",
                              employee_id: eva.employee_id,
                              id: eva.id,
                              note: "",
                              is_new: false,
                            });
                            // updateEvaMember({
                            //   id: eva.id,
                            //   week_status_id:
                            //     "7a232969-76c8-4133-bc0a-62079d01d10b",
                            // });
                          }}
                        >
                          Over Expectation
                        </button>
                      </div>
                    </>
                  )}
                  {eva?.week_status_id ===
                  "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa" ? (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-warning"
                          style={{ minWidth: "130px", opacity: "1" }}
                          disabled
                        >
                          Pass
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-secondary"
                          data-toggle="modal"
                          data-target="#modal-sm2"
                          style={{ minWidth: "130px", opacity: "1" }}
                          onClick={() => {
                            setNoteModalData({
                              is_open: true,
                              week_status_id:
                                "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                              employee_id: eva.employee_id,
                              id: eva.id,
                              note: "",
                              is_new: false,
                            });
                            // updateEvaMember({
                            //   id: eva.id,
                            //   week_status_id:
                            //     "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                            // });
                          }}
                        >
                          Pass
                        </button>
                      </div>
                    </>
                  )}
                  {eva?.week_status_id ===
                  "d4edf04e-94c3-4b26-aad8-62b0906bfc55" ? (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-danger"
                          style={{ minWidth: "130px", opacity: "1" }}
                          disabled
                        >
                          Fail
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100%" }} className="mr-1 ml-1">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-secondary"
                          data-toggle="modal"
                          data-target="#modal-sm2"
                          style={{ minWidth: "130px", opacity: "1" }}
                          onClick={() => {
                            setNoteModalData({
                              is_open: true,
                              week_status_id:
                                "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                              employee_id: eva.employee_id,
                              id: eva.id,
                              note: "",
                              is_new: false,
                            });
                            // updateEvaMember({
                            //   id: eva.id,
                            //   week_status_id:
                            //     "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                            // });
                          }}
                        >
                          Fail
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          } else {
            rowItem["assignment"] = (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Over Expectation
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Pass
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </div>
              </>
            );
          }
        }
      } else if (
        saveWeek === moment(new Date()).subtract(1, "week").isoWeekday(1).week()
      ) {
        if (
          moment(new Date()).day() === 1 &&
          moment(new Date()).hour() <= 8 &&
          moment(new Date()).minute() < 30
        ) {
          if (eva === undefined) {
            rowItem["assignment"] = (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-success"
                      data-toggle="modal"
                      data-target="#modal-sm2"
                      style={{ minWidth: "130px" }}
                      onClick={() => {
                        setNoteModalData({
                          is_open: true,
                          week_status_id:
                            "7a232969-76c8-4133-bc0a-62079d01d10b",
                          employee_id: teamData.data[index].employee_id,
                          id: "",
                          note: "",
                          is_new: true,
                        });
                        // SaveEvaMember({
                        //   week_status_id:
                        //     "7a232969-76c8-4133-bc0a-62079d01d10b",
                        //   employee_id: teamData.data[index].employee_id,
                        // });
                      }}
                    >
                      Over Expectation
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-warning"
                      data-toggle="modal"
                      data-target="#modal-sm2"
                      style={{ minWidth: "130px" }}
                      onClick={() => {
                        setNoteModalData({
                          is_open: true,
                          week_status_id:
                            "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                          employee_id: teamData.data[index].employee_id,
                          id: "",
                          note: "",
                          is_new: true,
                        });
                        // SaveEvaMember({
                        //   week_status_id:
                        //     "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                        //   employee_id: teamData.data[index].employee_id,
                        // });
                      }}
                    >
                      Pass
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-danger"
                      data-toggle="modal"
                      data-target="#modal-sm2"
                      style={{ minWidth: "130px" }}
                      onClick={() => {
                        setNoteModalData({
                          is_open: true,
                          week_status_id:
                            "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                          employee_id: teamData.data[index].employee_id,
                          id: "",
                          note: "",
                          is_new: true,
                        });
                        // SaveEvaMember({
                        //   week_status_id:
                        //     "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                        //   employee_id: teamData.data[index].employee_id,
                        // });
                      }}
                    >
                      Fail
                    </button>
                  </div>
                </div>
              </>
            );
          } else {
            if (eva?.emp_status_type_id === null) {
              rowItem["assignment"] = (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    {eva?.week_status_id ===
                    "7a232969-76c8-4133-bc0a-62079d01d10b" ? (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-success"
                            style={{ minWidth: "130px", opacity: "1" }}
                            disabled
                          >
                            Over Expectation
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-secondary"
                            data-toggle="modal"
                            data-target="#modal-sm2"
                            style={{ minWidth: "130px", opacity: "1" }}
                            onClick={() => {
                              setNoteModalData({
                                is_open: true,
                                week_status_id:
                                  "7a232969-76c8-4133-bc0a-62079d01d10b",
                                employee_id: eva.employee_id,
                                id: eva.id,
                                note: "",
                                is_new: false,
                              });
                              // updateEvaMember({
                              //   id: eva.id,
                              //   week_status_id:
                              //     "7a232969-76c8-4133-bc0a-62079d01d10b",
                              // });
                            }}
                          >
                            Over Expectation
                          </button>
                        </div>
                      </>
                    )}
                    {eva?.week_status_id ===
                    "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa" ? (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-warning"
                            style={{ minWidth: "130px", opacity: "1" }}
                            disabled
                          >
                            Pass
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-secondary"
                            data-toggle="modal"
                            data-target="#modal-sm2"
                            style={{ minWidth: "130px", opacity: "1" }}
                            onClick={() => {
                              setNoteModalData({
                                is_open: true,
                                week_status_id:
                                  "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                                employee_id: eva.employee_id,
                                id: eva.id,
                                note: "",
                                is_new: false,
                              });
                              // updateEvaMember({
                              //   id: eva.id,
                              //   week_status_id:
                              //     "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa",
                              // });
                            }}
                          >
                            Pass
                          </button>
                        </div>
                      </>
                    )}
                    {eva?.week_status_id ===
                    "d4edf04e-94c3-4b26-aad8-62b0906bfc55" ? (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-danger"
                            style={{ minWidth: "130px", opacity: "1" }}
                            disabled
                          >
                            Fail
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "100%" }} className="mr-1 ml-1">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-secondary"
                            data-toggle="modal"
                            data-target="#modal-sm2"
                            style={{ minWidth: "130px", opacity: "1" }}
                            onClick={() => {
                              setNoteModalData({
                                is_open: true,
                                week_status_id:
                                  "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                                employee_id: eva.employee_id,
                                id: eva.id,
                                note: "",
                                is_new: false,
                              });
                              // updateEvaMember({
                              //   id: eva.id,
                              //   week_status_id:
                              //     "d4edf04e-94c3-4b26-aad8-62b0906bfc55",
                              // });
                            }}
                          >
                            Fail
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            } else {
              rowItem["assignment"] = (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Over Expectation
                      </button>
                    </div>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Pass
                      </button>
                    </div>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Fail
                      </button>
                    </div>
                  </div>
                </>
              );
            }
          }
        } else {
          rowItem["assignment"] = (
            <>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {eva?.week_status_id ===
                "7a232969-76c8-4133-bc0a-62079d01d10b" ? (
                  <>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-success"
                        style={{ minWidth: "130px", opacity: "1" }}
                        disabled
                      >
                        Over Expectation
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {eva?.week_status_id ===
                "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa" ? (
                  <>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-warning"
                        style={{ minWidth: "130px", opacity: "1" }}
                        disabled
                      >
                        Pass
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {eva?.week_status_id ===
                "d4edf04e-94c3-4b26-aad8-62b0906bfc55" ? (
                  <>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-danger"
                        style={{ minWidth: "130px", opacity: "1" }}
                        disabled
                      >
                        Fail
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {eva?.week_status_id === null ? (
                  <>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Over Expectation
                      </button>
                    </div>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Pass
                      </button>
                    </div>
                    <div style={{ width: "100%" }} className="mr-1 ml-1">
                      <button
                        type="button"
                        id="btn_search"
                        className="btn btn-block btn-dark"
                        style={{ minWidth: "130px" }}
                        disabled
                      >
                        Fail
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          );
        }
      } else if (saveWeek < moment(new Date()).isoWeekday(1).week()) {
        rowItem["assignment"] = (
          <>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {eva?.week_status_id ===
              "7a232969-76c8-4133-bc0a-62079d01d10b" ? (
                <>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-success"
                      style={{ minWidth: "130px", opacity: "1" }}
                      disabled
                    >
                      Over Expectation
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {eva?.week_status_id ===
              "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa" ? (
                <>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-warning"
                      style={{ minWidth: "130px", opacity: "1" }}
                      disabled
                    >
                      Pass
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {eva?.week_status_id ===
              "d4edf04e-94c3-4b26-aad8-62b0906bfc55" ? (
                <>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-danger"
                      style={{ minWidth: "130px", opacity: "1" }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {eva?.week_status_id === null ? (
                <>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Over Expectation
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Pass
                    </button>
                  </div>
                  <div style={{ width: "100%" }} className="mr-1 ml-1">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-dark"
                      style={{ minWidth: "130px" }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      }
    } else if (saveYear < moment(new Date()).year()) {
      rowItem["assignment"] = (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {eva?.week_status_id === "7a232969-76c8-4133-bc0a-62079d01d10b" ? (
              <>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-success"
                    style={{ minWidth: "130px", opacity: "1" }}
                    disabled
                  >
                    Over Expectation
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {eva?.week_status_id === "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa" ? (
              <>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-warning"
                    style={{ minWidth: "130px", opacity: "1" }}
                    disabled
                  >
                    Pass
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {eva?.week_status_id === "d4edf04e-94c3-4b26-aad8-62b0906bfc55" ? (
              <>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-danger"
                    style={{ minWidth: "130px", opacity: "1" }}
                    disabled
                  >
                    Fail
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {eva?.week_status_id === null ? (
              <>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-dark"
                    style={{ minWidth: "130px" }}
                    disabled
                  >
                    Over Expectation
                  </button>
                </div>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-dark"
                    style={{ minWidth: "130px" }}
                    disabled
                  >
                    Pass
                  </button>
                </div>
                <div style={{ width: "100%" }} className="mr-1 ml-1">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-dark"
                    style={{ minWidth: "130px" }}
                    disabled
                  >
                    Fail
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    }

    rowsDataEva.push(rowItem);
  }

  const dataEva = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Assign to",
        field: "assign_to",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 10,
      },
      {
        label: "Assignment",
        field: "assignment",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsDataEva,
  };

  const clearFilter = async () => {
    await setInputData({
      ...inputData,
      assign_by_id: "",
      assign_to_id: "",
      task: "",
    });

    inputData.assign_by_id = "";
    inputData.assign_to_id = "";
    inputData.task = "";

    await setDupInputData({
      ...dupInputData,
      assign_by_id: "",
      assign_to_id: "",
      task: "",
    });

    dupInputData.assign_by_id = "";
    dupInputData.assign_to_id = "";
    dupInputData.task = "";

    await FilterAssign();
  };

  const clearFilterModal = async () => {
    const currentDate = new Date();
    setShowWeek(moment(currentDate).isoWeekday(1).week());
    setShowYear(moment(currentDate).year());
    setSaveWeek(moment(currentDate).isoWeekday(1).week());
    setSaveYear(moment(currentDate).year());

    setShowDate({
      ...showDate,
      startDate: moment(currentDate)
        .clone()
        .startOf("week")
        .add(1, "days")
        .date(),
      startMonth: moment(currentDate)
        .clone()
        .startOf("week")
        .add(1, "days")
        .locale("en")
        .format("MMM"),
      endDate: moment(currentDate)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .date(),
      endMonth: moment(currentDate)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .locale("en")
        .format("MMM"),
      year: moment(currentDate)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .year(),
    });

    await FilterEvaMember(
      moment(currentDate).isoWeekday(1).week(),
      moment(currentDate).year()
    );
  };

  const SaveEvaMember = async (/* model */) => {
    // Swal.fire({
    //   title: "หมายเหตุ",
    //   // input: "textarea",
    //   html: `<div class="form-group" style="margin: 0px;">
    //       <textarea
    //         type="text"
    //         class="form-control"
    //         required=""
    //         id="swal-input1"
    //         style="height: 150px; font-size: 25px; padding: 5px 10px;"
    //       ></textarea>
    //     </div>`,
    //   confirmButtonText: "ยืนยัน",
    //   confirmButtonColor: "#28A745",
    //   showCancelButton: true,
    //   cancelButtonText: "ยกเลิก",
    //   cancelButtonColor: "#DC3545",
    //   preConfirm: () => {
    //     return [document.getElementById("swal-input1").value];
    //   },
    // }).then(async function (result) {
    //   if (result.isConfirmed) {
    let pushData = {
      week_status_id: noteModalData.week_status_id,
      employee_id: noteModalData.employee_id,
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
      week: saveWeek,
      year: saveYear,
      remark: noteModalData.note,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/saveEvaMember",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        setNoteModalData({
          is_open: false,
          week_status_id: "",
          employee_id: "",
          id: "",
          note: "",
          is_new: true,
        });
        await FilterEvaMember(showWeek, saveYear);
        if (closeModalRef.current) {
          closeModalRef.current.click();
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    //   }
    // });
  };

  const updateEvaMember = async (/* model */) => {
    // Swal.fire({
    //   title: "หมายเหตุ",
    //   // input: "textarea",
    //   html: `<div class="form-group" style="margin: 0px;">
    //       <textarea
    //         type="text"
    //         class="form-control"
    //         required=""
    //         id="swal-input1"
    //         style="height: 150px; font-size: 25px; padding: 5px 10px;"
    //       ></textarea>
    //     </div>`,
    //   confirmButtonText: "ยืนยัน",
    //   confirmButtonColor: "#28A745",
    //   showCancelButton: true,
    //   cancelButtonText: "ยกเลิก",
    //   cancelButtonColor: "#DC3545",
    //   preConfirm: () => {
    //     return [document.getElementById("swal-input1").value];
    //   },
    // }).then(async function (result) {
    //   if (result.isConfirmed) {
    let pushData = {
      id: noteModalData.id,
      week_status_id: noteModalData.week_status_id,
      user: getUser().fup,
      remark: noteModalData.note,
      oem_id: getOem(),
      company_id: getUser().com,
      employee_id: noteModalData.employee_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/updateEvaMember",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        setNoteModalData({
          is_open: false,
          week_status_id: "",
          employee_id: "",
          id: "",
          note: "",
          is_new: true,
        });
        await FilterEvaMember(showWeek, saveYear);
        if (closeModalRef.current) {
          closeModalRef.current.click();
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    // }
    // });
  };

  const CheckWork = async (model) => {
    let pushData = {
      id: model.task_id,
      assign_to_status_id: model.assign_to_status_id,
      assign_by_status_id: model.assign_by_status_id,
      task_count: model.task_count,
      week: model.week || null,
      year: model.year || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/updateCheckWork",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        await FilterAssign();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DeleteAssign = async (task_id) => {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "กำลังลบข้อมูล",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/deleteAssign",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: { id: task_id },
        })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(async () => {
              await FilterAssign();
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };

  async function GetTeamMemberInTeamMember() {
    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getTeamMemberInTeamMember",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          let empEqualType = response.data.data.filter((empItem) => {
            empItem.is_late = false;
            empItem.is_current = false;
            empItem.is_future = false;
            return empItem.contract_type === item.id;
          });

          // empEqualType.sort((a, b) => {
          //   if (a.no === null) {
          //     return 1;
          //   }

          //   if (b.no === null) {
          //     return -1;
          //   }

          //   if (a.no.toLowerCase() === b.no.toLowerCase()) {
          //     return 0;
          //   }

          //   return a.no.toLowerCase() < b.no.toLowerCase() ? -1 : 1;
          // });

          empAll.push(...empEqualType);
        }
        const contractTypeOrder = {
          "fc368f6c-eeeb-4f9d-8931-6ac7689d9b91": 1,
          "587fe099-a8e8-4fd4-b4f6-4351e6376e18": 2,
          "6da1572c-9195-4790-a459-9809f46d5ce9": 3,
          "f32efffa-1abe-4cd6-9ab9-3318cd1718c0": 4,
        };

        empAll.sort((a, b) => {
          const typeOrderA = contractTypeOrder[a.contract_type] || 100;
          const typeOrderB = contractTypeOrder[b.contract_type] || 100;

          if (a.is_child && !b.is_child) return -1;
          if (!a.is_child && b.is_child) return 1;

          if (typeOrderA !== typeOrderB) {
            return typeOrderA - typeOrderB;
          }

          const parseValue = (str) => {
            const match = str.match(/([a-zA-Z]+)(\d*)/);
            return match
              ? [match[1].toLowerCase(), parseInt(match[2] || "0", 10)]
              : [str.toLowerCase(), 0];
          };

          const [textA, numA] = parseValue(a.no);
          const [textB, numB] = parseValue(b.no);

          if (textA !== textB) {
            return textA < textB ? -1 : 1;
          }

          return numA - numB;
        });

        settmIntm({
          data: empAll,
        });
        tmIntm.data = empAll;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData({ data: arrList });
        getEmployeeWorkTypeData.data = arrList;

        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const FilterEmployee = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // console.log("response.data.data", response.data.data);
        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        setEmployeeData({ data: empAll });
        employeeData.data = empAll;

        const options = [{ value: "", label: "Select All" }];

        empAll.forEach((el) => {
          if (el.is_active === true) {
            var element = {};
            element["value"] = el.emp_id;
            element["label"] = `${el.emp_no || ""} ${
              el.emp_nickname ? "( " + el.emp_nickname + " )" : ""
            } `;
            options.push(element);
          }
        });

        setEmployeeOption({ data: options });
        setFilteredData({ data: response.data.data });
        employeeOption.data = options;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterAssign = async () => {
    // Swal.fire({
    //   title: "กำลังดึงข้อมูล",
    //   text: "Loading",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
      assign_by_id: inputData.assign_by_id || null,
      assign_to_id: inputData.assign_to_id || null,
      task: inputData.task ? inputData.task.trim() : null,
      show_passed: inputData.show_passed,
    };
    // console.log("get_filler_assign", get_filler_assign);

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterAssign",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        // response.data.data.sort((a, b) => {
        //   if (
        //     a.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
        //   ) {
        //     return 1;
        //   }

        //   if (
        //     b.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
        //   ) {
        //     return -1;
        //   }

        //   if (a.assign_by_status_id === b.assign_by_status_id) {
        //     return (
        //       Number(moment(b.updated_date).format("YYYYMMDDHHmmss")) -
        //       Number(moment(a.updated_date).format("YYYYMMDDHHmmss"))
        //     );
        //   }

        //   return a.assign_by_status_id < b.assign_by_status_id
        //     ? Number(moment(b.updated_date).format("YYYYMMDDHHmmss")) -
        //         Number(moment(a.updated_date).format("YYYYMMDDHHmmss"))
        //     : Number(moment(b.updated_date).format("YYYYMMDDHHmmss")) -
        //         Number(moment(a.updated_date).format("YYYYMMDDHHmmss"));
        // });

        const finishData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
          );
        });

        const waitData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "ffaa9180-e258-4aaa-96ab-afd4f991a572"
          );
        });

        const successfulData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
          );
        });

        finishData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        waitData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        successfulData.sort((a, b) => {
          if (moment(a.due_date) < moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) > moment(b.due_date)) {
            return -1;
          }
        });

        setAssignData({
          data: [...finishData, ...waitData, ...successfulData],
        });
        assignData.data = [...finishData, ...waitData, ...successfulData];

        const taskByEmp = [
          ...finishData,
          ...waitData,
          ...successfulData,
        ].reduce((item_1, item_2) => {
          (item_1[item_2.assign_to_id] =
            item_1[item_2.assign_to_id] || []).push(item_2);
          return item_1;
        }, {});
        // console.log('taskByEmp :>> ', taskByEmp);
        setTaskByEmpList({
          data: taskByEmp,
        });
        taskByEmpList.data = taskByEmp;

        await TableByEmp();
        // Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const getCurrentTasks = async () => {
    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
      assign_by_id: inputData.assign_by_id || null,
      assign_to_id: inputData.assign_to_id || null,
      task: inputData.task ? inputData.task.trim() : null,
      show_passed: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterAssign",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        const finishData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "7a2a0ab3-cc81-4b17-9129-c95347337dd0"
          );
        });

        const waitData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "ffaa9180-e258-4aaa-96ab-afd4f991a572"
          );
        });

        const successfulData = response.data.data.filter((item) => {
          return (
            item.assign_by_status_id === "f46730e7-9980-46b5-a4e5-7967550e4ea2"
          );
        });

        finishData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        waitData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        successfulData.sort((a, b) => {
          if (moment(a.due_date) < moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) > moment(b.due_date)) {
            return -1;
          }
        });

        const taskByEmp = [
          ...finishData,
          ...waitData,
          ...successfulData,
        ].reduce((item_1, item_2) => {
          (item_1[item_2.assign_to_id] =
            item_1[item_2.assign_to_id] || []).push(item_2);
          return item_1;
        }, {});

        setCurrentTasks({
          data: taskByEmp,
        });
        currentTasks.data = taskByEmp;

        // await TableByEmp();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterTeam = async () => {
    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterTeam",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.employee_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.employee_no === null) {
              return 1;
            }

            if (b.employee_no === null) {
              return -1;
            }

            if (a.employee_no.toLowerCase() === b.employee_no.toLowerCase()) {
              return 0;
            }

            return a.employee_no.toLowerCase() < b.employee_no.toLowerCase()
              ? -1
              : 1;
          });

          empAll.push(...empEqualType);
        }

        setTeamData({ data: empAll });
        teamData.data = empAll;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterEvaMember = async (weekShow, yearShow) => {
    const currentDate = new Date();

    if (weekShow === "") {
      weekShow = moment(currentDate).isoWeekday(1).week();
      setShowWeek(moment(currentDate).isoWeekday(1).week());
    }

    if (yearShow === "") {
      yearShow = moment(currentDate).year();
      setShowYear(moment(currentDate).year());
    }

    setShowDate({
      ...showDate,
      startDate: moment()
        .year(yearShow)
        .week(weekShow)
        .clone()
        .startOf("week")
        .add(1, "days")
        .date(),
      startMonth: moment()
        .year(yearShow)
        .week(weekShow)
        .clone()
        .startOf("week")
        .add(1, "days")
        .locale("en")
        .format("MMM"),
      endDate: moment()
        .year(yearShow)
        .week(weekShow)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .date(),
      endMonth: moment()
        .year(yearShow)
        .week(weekShow)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .locale("en")
        .format("MMM"),
      year: moment()
        .year(yearShow)
        .week(weekShow)
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .year(),
    });
    showDate.startDate = moment()
      .year(yearShow)
      .week(weekShow)
      .clone()
      .startOf("week")
      .add(1, "days")
      .date();
    showDate.startMonth = moment()
      .year(yearShow)
      .week(weekShow)
      .clone()
      .startOf("week")
      .add(1, "days")
      .locale("en")
      .format("MMM");
    showDate.endDate = moment()
      .year(yearShow)
      .week(weekShow)
      .clone()
      .endOf("week")
      .subtract(1, "days")
      .date();
    showDate.endMonth = moment()
      .year(yearShow)
      .week(weekShow)
      .clone()
      .endOf("week")
      .subtract(1, "days")
      .locale("en")
      .format("MMM");
    showDate.year = moment()
      .year(yearShow)
      .week(weekShow)
      .clone()
      .endOf("week")
      .subtract(1, "days")
      .year();

    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
      week: weekShow,
      year: yearShow,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEvaMember",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        setEvaData({ data: response.data.data });
        evaData.data = response.data.data;
        // Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  // const AutoSaveEvaMember = async () => {
  //   let get_filler_assign = {
  //     oem_id: getOem(),
  //     company_id: getUser().com,
  //     user: getUser().fup,
  //     hours: 15,
  //     minutes: 38,
  //   };

  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_hrMagenatement + "/api/hrManagement/autoSaveEvaMember",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: get_filler_assign,
  //   })
  //     .then(async function (response) {
  //       // await setTeamData(response.data.data);
  //       setTeamData({data: empAll});
  //       teamData.data = empAll;
  //       // Swal.close();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
  //     });
  // };

  const AutoGetEvaMember = async () => {
    await FilterEvaMember(
      (saveWeek) => saveWeek,
      (saveYear) => saveYear
    );
  };

  useEffect(async () => {
    const currentDate = new Date();
    // const startDate = new Date(currentDate.getFullYear(), 0, 1);
    // let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    // let weekNumber = Math.ceil(days / 7);
    setAllWeek(moment().year(moment(currentDate).year()).weeksInYear());
    setSaveWeek(moment(currentDate).isoWeekday(1).week());
    setSaveYear(moment(currentDate).year());
    // const tabToClick = document.getElementById("to-do-list-assigned-tab");

    // if (tabToClick) {
    //   tabToClick.click();

    //   // setCallBackUser("2");

    //   FilterAssign();
    // }

    const sessionCallBackUser = sessionStorage.getItem("callBackUser");
    let sessionFilters = JSON.parse(sessionCallBackUser)?.item;

    if (!sessionFilters) {
      const tabToClick = document.getElementById(
        `to-do-list-${sessionFilters}-tab`
      );
      if (tabToClick) {
        tabToClick.click();
      }
    }
    setSaveCurrentDate({
      ...saveCurrentDate,
      startDate: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .startOf("week")
        .add(1, "days"),
      endDate: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .endOf("week")
        .subtract(1, "days"),
      week: moment(currentDate).isoWeekday(1).week(),
      year: moment()
        .year(moment(currentDate).year())
        .week(moment(currentDate).isoWeekday(1).week())
        .clone()
        .endOf("week")
        .subtract(1, "days")
        .year(),
    });
    saveCurrentDate.startDate = moment()
      .year(moment(currentDate).year())
      .week(moment(currentDate).isoWeekday(1).week())
      .clone()
      .startOf("week")
      .add(1, "days");
    saveCurrentDate.endDate = moment()
      .year(moment(currentDate).year())
      .week(moment(currentDate).isoWeekday(1).week())
      .clone()
      .endOf("week")
      .subtract(1, "days");
    saveCurrentDate.week = moment(currentDate).isoWeekday(1).week();
    saveCurrentDate.year = moment()
      .year(moment(currentDate).year())
      .week(moment(currentDate).isoWeekday(1).week())
      .clone()
      .endOf("week")
      .subtract(1, "days")
      .year();

    await GetEmployeeWorkType();
    await FilterEmployee();
    await GetTeamMemberInTeamMember();
    await FilterTeam();
    await FilterAssign();
    await getHeadTeam();
    await getCurrentTasks();
    await FilterEvaMember(
      moment(currentDate).isoWeekday(1).week(),
      moment(currentDate).year()
    );
    // await AutoSaveEvaMember();

    if (moment(new Date()).day() === 1) {
      if (moment(new Date()).hour() < 8) {
        var timeCount = setInterval(async () => {
          if (moment(new Date()).hour() === 8) {
            if (moment(new Date()).minute() >= 30) {
              clearInterval(timeCount);
              await AutoGetEvaMember();

              var timer = setTimeout(async () => {
                clearTimeout(timer);
                await AutoGetEvaMember();
              }, 3 * 1000);
            }
          }
        }, 1 * 1000);
      } else if (moment(new Date()).hour() === 8) {
        if (moment(new Date()).minute() < 30) {
          var timeCount = setInterval(async () => {
            if (moment(new Date()).hour() === 8) {
              if (moment(new Date()).minute() >= 30) {
                clearInterval(timeCount);
                await AutoGetEvaMember();

                var timer = setTimeout(async () => {
                  clearTimeout(timer);
                  await AutoGetEvaMember();
                }, 3 * 1000);
              }
            }
          }, 1 * 1000);
        }
      }
    }
  }, []);

  // const testDay = new Date();
  // if (testDay.getDay() === 1) {
  //   if (testDay.getHours() >= 8 && testDay.getMinutes() >= 30) {
  //     console.log("yes");
  //   } else {
  //     console.log("no");
  //     setInterval(() => {
  //       console.log("test");
  //     }, 1000);
  //   }
  // }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      windowSize.width = window.innerWidth;
      windowSize.height = window.innerHeight;

      if (window.innerWidth <= 600) {
        setMode({
          data: "design",
        });
        mode.data = "design";
      }

      // setElementSize({
      //   width: document.getElementById(`slider-${id}`).offsetWidth,
      // });
      // elementSize.width = document.getElementById(`slider-${id}`).offsetWidth;
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  function addToDo(params) {
    if (params) {
      sessionStorage.setItem("todoId", params);
      sessionStorage.setItem(
        "state_filter_assign_work",
        JSON.stringify({
          assign_by_id: dupInputData.assign_by_id,
          assign_to_id: dupInputData.assign_to_id,
          task: dupInputData.task,
          show_passed: inputData.show_passed,
          callBackUser: callBackUser,
          mode: mode.data,
        })
      );
    }
    window.location.href = "/Human_Resource/to_do_list/assign_work/add";
  }

  const totalBars = 10;
  const barHeight = 25;

  const getBarColor = (index) => {
    const colors = [
      "#A7CF38", // แท่งแรก
      "#A7CF38", // แท่งที่สอง
      "#83B200", // แท่งที่สาม
      "#83B200", // แท่งที่สี่
      "#60A000", // แท่งที่ห้า
      "#60A000", // แท่งที่หก
      "#00AB64", // แท่งที่เจ็ด
      "#00AB64", // แท่งที่แปด
      "#007142", // แท่งที่เก้า
      "#007142", // แท่งสุดท้าย
    ];
    return colors[index % colors.length];
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowClick = (id) => {
    const currentIndex = expandedRows.indexOf(id);
    const newExpandedRows = [...expandedRows];
    if (currentIndex >= 0) {
      newExpandedRows.splice(currentIndex, 1);
    } else {
      newExpandedRows.push(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const getHeadTeam = async (model) => {
    // let pushData = {
    //   id: model.task_id,
    //   assign_to_status_id: model.assign_to_status_id,
    //   assign_by_status_id: model.assign_by_status_id,
    //   task_count: model.task_count,
    // };

    await axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getMemberToHeadTeam",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: pushData,
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          setHeadTeam(response.data.data);
        } else {
          setHeadTeam([]);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const [isLabtop, setIsLabtop] = useState(window.innerWidth <= 1400);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLabtop(window.innerWidth <= 1400);
      setIsTablet(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const shouldIncludeTask = (item, saveCurrentDate, index) => {
    if (
      item.is_late &&
      new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
        new Date(taskByEmpList.data[item.id][index].due_date).setHours(
          0,
          0,
          0,
          0
        )
    ) {
      return false;
    }
    if (
      item.is_current &&
      new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) <=
        new Date(taskByEmpList.data[item.id][index].due_date).setHours(
          0,
          0,
          0,
          0
        ) &&
      new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) >=
        new Date(taskByEmpList.data[item.id][index].due_date).setHours(
          0,
          0,
          0,
          0
        )
    ) {
      return false;
    }
    if (
      item.is_future &&
      new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
        new Date(taskByEmpList.data[item.id][index].due_date).setHours(
          0,
          0,
          0,
          0
        )
    ) {
      return false;
    }
    if (
      taskByEmpList.data[item.id][index].assign_by_status_id ===
        "f46730e7-9980-46b5-a4e5-7967550e4ea2" &&
      new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
        new Date(taskByEmpList.data[item.id][index].due_date).setHours(
          0,
          0,
          0,
          0
        )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const data2 = sessionStorage.getItem("state_filter_assign_work");
    if (data2) {
      const data_filter = JSON.parse(data2);
      setDupInputData({
        ...dupInputData,
        assign_by_id: data_filter.assign_by_id,
        assign_to_id: data_filter.assign_to_id,
        task: data_filter.task,
        show_passed: data_filter.show_passed,
      });
      setInputData({
        ...inputData,
        show_passed: data_filter.show_passed,
      });
      setCallBackUser(data_filter.callBackUser);
      callBackUser = data_filter.callBackUser;
      setMode({
        data: data_filter.mode,
      });
      dupInputData.assign_by_id = data_filter.assign_by_id;
      dupInputData.assign_to_id = data_filter.assign_to_id;
      dupInputData.task = data_filter.task;
      inputData.show_passed = data_filter.show_passed;

      mode.data = data_filter.mode;
      sessionStorage.removeItem("state_filter_assign_work");
    }
    FilterAssign();
  }, []);

  return (
    <>
      <div className="wrapper">
        {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>
                    มอบหมายงาน{"  "}
                    {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                  </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>

                    <li className="breadcrumb-item active">Human Resource</li>
                    <li className="breadcrumb-item active">มอบหมายงาน</li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row mb-2">
                <div
                  className="col-12 col-md-4 col-xl-2"
                  style={{ zIndex: "97" }}
                >
                  <div className="form-group" style={{ zIndex: "11" }}>
                    {/* <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_employee_type"
                    value={inputData.assign_by_id}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        assign_by_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {employeeData.map((item) => {
                      return (
                        <option value={item.emp_id}>{item.emp_name_th}</option>
                      );
                    })}
                  </select> */}
                    <Select
                      // isDisabled={disable}
                      options={employeeOption.data}
                      value={employeeOption.data.filter((e) => {
                        return (
                          e.value ===
                          (inputData.assign_by_id != null &&
                          inputData.assign_by_id != ""
                            ? inputData.assign_by_id
                            : dupInputData.assign_by_id)
                        );
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          // setInputData({
                          //   ...inputData,
                          //   assign_by_id: e.value,
                          // });

                          setDupInputData({
                            ...dupInputData,
                            assign_by_id: e.value,
                          });
                        }
                      }}
                    />
                    <label htmlFor="">Assign By</label>
                  </div>
                </div>
                <div
                  className="col-12 col-md-3 col-xl-2"
                  // style={{ "z-index": "97" }}
                >
                  <div className="form-group" style={{ zIndex: "11" }}>
                    {/* <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_employee_type"
                    value={inputData.assign_to_id}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        assign_to_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {employeeData.map((item) => {
                      return (
                        <option value={item.emp_id}>{item.emp_name_th}</option>
                      );
                    })}
                  </select> */}
                    <Select
                      // isDisabled={disable}
                      options={employeeOption.data}
                      value={employeeOption.data.filter((e) => {
                        return (
                          e.value ===
                          (inputData.assign_to_id != null &&
                          inputData.assign_to_id != ""
                            ? inputData.assign_to_id
                            : dupInputData.assign_to_id)
                        );
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          // setInputData({
                          //   ...inputData,
                          //   assign_to_id: e.value,
                          // });
                          setDupInputData({
                            ...dupInputData,
                            assign_to_id: e.value,
                          });
                        }
                      }}
                    />
                    <label htmlFor="">Assign To</label>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      id="text_empname"
                      value={
                        inputData.task ? inputData.task : dupInputData.task
                      }
                      onChange={(e) => {
                        // setInputData({
                        //   ...inputData,
                        //   task: e.target.value,
                        // });

                        setDupInputData({
                          ...dupInputData,
                          task: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="">Task</label>{" "}
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        setInputData({
                          ...inputData,
                          assign_by_id: dupInputData.assign_by_id,
                          assign_to_id: dupInputData.assign_to_id,
                          task: dupInputData.task,
                        });
                        inputData.assign_by_id = dupInputData.assign_by_id;
                        inputData.assign_to_id = dupInputData.assign_to_id;
                        inputData.task = dupInputData.task;
                        FilterAssign();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_clear"
                      className="btn btn-block btn-primary"
                      onClick={clearFilter}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-primary"
                      onClick={addToDo}
                    >
                      Add To Do
                    </button>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_clear"
                      className="btn btn-block btn-primary"
                      data-toggle="modal"
                      data-target="#modal-xl2"
                      onClick={async () => {
                        const currentDate = new Date();
                        // const startDate = new Date(
                        //   currentDate.getFullYear(),
                        //   0,
                        //   1
                        // );
                        // let days = Math.floor(
                        //   (currentDate - startDate) / (24 * 60 * 60 * 1000)
                        // );
                        // let weekNumber = Math.ceil(days / 7);
                        setAllWeek(
                          moment()
                            .year(moment(currentDate).year())
                            .weeksInYear()
                        );
                        setShowWeek(moment(currentDate).isoWeekday(1).week());
                        setShowYear(moment(currentDate).year());

                        setShowDate({
                          ...showDate,
                          startDate: moment(currentDate)
                            .clone()
                            .startOf("week")
                            .add(1, "days")
                            .date(),
                          startMonth: moment(currentDate)
                            .clone()
                            .startOf("week")
                            .add(1, "days")
                            .locale("en")
                            .format("MMM"),
                          endDate: moment(currentDate)
                            .clone()
                            .endOf("week")
                            .subtract(1, "days")
                            .date(),
                          endMonth: moment(currentDate)
                            .clone()
                            .endOf("week")
                            .subtract(1, "days")
                            .locale("en")
                            .format("MMM"),
                          year: moment(currentDate)
                            .clone()
                            .endOf("week")
                            .subtract(1, "days")
                            .year(),
                        });

                        clearFilterModal();
                      }}
                    >
                      ประเมินประจําสัปดาห์
                    </button>
                  </div>
                </div>
                <div
                  className="col-6 col-md-3 col-xl-2"
                  style={{ marginLeft: "15px", alignSelf: "center" }}
                >
                  <div className="form-check" style={{ height: "38px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // id="work_choup"
                      // name="work_choup"
                      // value="work_choup"
                      checked={inputData.show_passed}
                      onChange={async (event) => {
                        setInputData({
                          ...inputData,
                          show_passed: event.currentTarget.checked,
                        });
                        inputData.show_passed = event.currentTarget.checked;
                        setDupInputData({
                          ...dupInputData,
                          show_passed: event.currentTarget.checked,
                        });
                        dupInputData.show_passed = event.currentTarget.checked;
                        await FilterAssign();
                      }}
                    />
                    <label className="form-check-label" htmlFor="">
                      Show Passed
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            {windowSize.width > 600 ? (
              <div className="row">
                <div className="col-12" style={{ textAlign: "right" }}>
                  <button
                    className={`buttonIcon ${
                      mode.data === "design" ? "active" : ""
                    }`}
                    style={{ marginRight: "2.5px" }}
                    onClick={() => {
                      setMode({ data: "design" });
                    }}
                  >
                    <i
                      className={`customSvgBasil ${
                        mode.data === "design" ? "active" : ""
                      }`}
                    />
                  </button>
                  <button
                    className={`buttonIcon ${
                      mode.data === "table" ? "active" : ""
                    }`}
                    style={{ marginLeft: "2.5px" }}
                    onClick={() => {
                      setMode({ data: "table" });
                    }}
                  >
                    <i
                      className={`customSvgRadix ${
                        mode.data === "table" ? "active" : ""
                      }`}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}

            {mode.data === "design" ? (
              // <div
              //   style={{
              //     display: "grid",
              //     gridTemplateColumns:
              //       windowSize.width > 600
              //         ? "repeat(auto-fill, minmax(400px, 1fr))"
              //         : "repeat(auto-fill, minmax(300px, 1fr))",
              //     justifyItems: "center",
              //     paddingBottom: "10px",
              //     gap: "10px",
              //   }}
              // >
              //   {assignData.data.map((item) => {
              //     return (
              //       <Assign_Work_Card
              //         data={item}
              //         DeleteAssign={DeleteAssign}
              //         CheckWork={CheckWork}
              //         saveCurrentDate={saveCurrentDate}
              //       />
              //     );
              //   })}
              // </div>

              <div className="card card-primary card-outline card-outline-tabs">
                <div className="card-header p-0 border-bottom-0">
                  <ul
                    className="nav nav-tabs"
                    id="custom-to-do-list-tab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        // className="nav-link  active"
                        className={`nav-link ${
                          callBackUser == 1 ? "active" : ""
                        }`}
                        id="to-do-list-assigned-tab"
                        data-toggle="pill"
                        href="#to-do-list-assigned"
                        role="tab"
                        aria-controls="to-do-list-assigned"
                        aria-selected="true"
                        onClick={() => {
                          // sessionStorage.setItem(
                          //   "callBackUser",
                          //   JSON.stringify({ item: "assigned" })
                          // );
                          setCallBackUser("1");
                          callBackUser = "1";
                          FilterAssign();
                          TableByEmp();
                        }}
                      >
                        มอบหมายงาน{" "}
                        {`( ${getAssignToNameLength(assignData.data)} )`}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        // className="nav-link "
                        className={`nav-link ${
                          callBackUser == 2 ? "active" : ""
                        }`}
                        id="to-do-list-all-tab"
                        data-toggle="pill"
                        href="#to-do-list-all"
                        role="tab"
                        aria-controls="to-do-list-all"
                        aria-selected="false"
                        onClick={() => {
                          // sessionStorage.removeItem("callBackUser");
                          setCallBackUser("2");
                          callBackUser = "2";
                          // setInputData({
                          //   ...inputData,
                          //   show_passed: false,
                          // });
                          // inputData.show_passed = false;
                          FilterAssign();
                          TableByEmp();
                        }}
                      >
                        ทั้งหมด {`( ${assignData.data.length} )`}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body" style={{ padding: "20px 10px" }}>
                  <div
                    className="tab-content"
                    id="custom-to-do-list-tab-content"
                  >
                    <div
                      className={`tab-pane fade ${
                        callBackUser == 2 ? "show active" : ""
                      }`}
                      // className="tab-pane fade show active"
                      id="to-do-list-all"
                      role="tabpanel"
                      aria-labelledby="to-do-list-all-tab"
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            windowSize.width > 600
                              ? "repeat(auto-fill, minmax(390px, 1fr))"
                              : "repeat(auto-fill, minmax(290px, 1fr))",
                          justifyItems: "center",
                          paddingBottom: "10px",
                          gap: "10px",
                        }}
                      >
                        {assignData.data.map((item) => {
                          return (
                            <Assign_Work_Card
                              data={item}
                              DeleteAssign={DeleteAssign}
                              CheckWork={CheckWork}
                              saveCurrentDate={saveCurrentDate}
                              inputData={dupInputData}
                              callBackUser={callBackUser}
                              mode={mode}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        callBackUser == 1 ? "show active" : ""
                      }`}
                      id="to-do-list-assigned"
                      role="tabpanel"
                      aria-labelledby="to-do-list-assigned-tab"
                    >
                      <div
                        className="table-responsive"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <section className="content">
                          <div className="row" style={{ margin: 0 }}>
                            <div className="col-12">
                              {tmIntm.data.map((item, index) => {
                                const lateTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) >
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          late.assign_by_status_id !==
                                            "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const currentTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) <=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) >=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const futureTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) <
                                          new Date(late.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const lateTasksSuccessCount = currentTasks.data[
                                  item.id
                                ]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) >
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          late.assign_by_status_id !==
                                            "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const currentTasksSuccessCount = currentTasks
                                  .data[item.id]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) <=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) >=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const futureTasksSuccessCount = currentTasks
                                  .data[item.id]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) <
                                          new Date(late.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const successedTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          late.assign_by_status_id ===
                                          "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const passCurrent = taskByEmpList.data[item.id]
                                  ? taskByEmpList.data[item.id].filter(
                                      (el) =>
                                        el.assign_by_status_id ===
                                          "f46730e7-9980-46b5-a4e5-7967550e4ea2" &&
                                        new Date(
                                          saveCurrentDate.startDate
                                        ).setHours(0, 0, 0, 0) <=
                                          new Date(el.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          ) &&
                                        new Date(
                                          saveCurrentDate.endDate
                                        ).setHours(0, 0, 0, 0) >=
                                          new Date(el.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                    ).length
                                  : 0;

                                const totalTasks =
                                  (Number(passCurrent) /
                                    Number(currentTasksCount)) *
                                    100 || 0;
                                const filledBars = Math.round(
                                  (totalTasks / 100) * totalBars
                                );

                                const totalAllTasks =
                                  lateTasksSuccessCount +
                                  currentTasksSuccessCount +
                                  futureTasksSuccessCount;

                                const renderHeadTeamNames = () => {
                                  if (!Array.isArray(headTeam)) {
                                    console.log(
                                      "headTeam is not an array:",
                                      headTeam
                                    ); // Log if headTeam is not an array
                                    return null;
                                  }

                                  const names = headTeam
                                    .filter(
                                      (head) => item.id === head.employee_id
                                    ) // Filter only matching employees
                                    .map((head) => (
                                      <p
                                        style={{ margin: 0 }}
                                        key={head.employee_id}
                                      >
                                        ผู้ประเมิน : {head.emp_no}{" "}
                                        {head.nickname}
                                      </p>
                                    ));

                                  if (names.length === 0) {
                                    // console.log("No matching employees found");
                                    return null;
                                  }

                                  return names;
                                };
                                // filteredData
                                const imagePath = filteredData.data
                                  ? filteredData.data
                                      .filter((el) => el.emp_id === item.id)
                                      .map((el) => el.file_path)
                                  : "";
                                return (
                                  <div>
                                    <div
                                      className="card"
                                      style={{
                                        border: "0.5px solid #B6B6B6",
                                        boxShadow: "4px 4px 4px #B6B6B6",
                                        width: "100%",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div
                                        className="card-body"
                                        style={{
                                          padding: "10px",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          hidden={!item.is_child}
                                          style={{
                                            height: "100%",
                                            background:
                                              "linear-gradient(#004aad, #5de0e6)",
                                            position: "absolute",
                                            width: "7px",
                                            left: 0,
                                            top: 0,
                                            borderRadius: "7.5px 0 0 7.5px",
                                          }}
                                        ></div>
                                        <div className="grid-con">
                                          <div
                                            className="grid-item-1"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              whiteSpace: "break-spaces",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <img
                                              alt="..."
                                              className="img-fluid"
                                              style={{
                                                width: `${
                                                  windowSize.width <= 600
                                                    ? "48px"
                                                    : "68px"
                                                }`,
                                                height: `${
                                                  windowSize.width <= 600
                                                    ? "48px"
                                                    : "68px"
                                                }`,
                                                marginRight: "10px",
                                                objectFit: "cover",
                                                border: "2px solid #394DA1",
                                                borderRadius: "50%",
                                              }}
                                              src={
                                                imagePath.length > 0 &&
                                                imagePath[0]
                                                  ? Configs.API_URL_IMG +
                                                    imagePath[0]
                                                  : TTT_Person
                                              }
                                            />
                                            <div
                                              style={{
                                                width: "100%",
                                                color: "#394DA1",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  margin: 0,
                                                  fontSize: `${
                                                    windowSize.width <= 600
                                                      ? "16px"
                                                      : "22px"
                                                  }`,
                                                  color:
                                                    currentTasksSuccessCount ===
                                                    0
                                                      ? `red`
                                                      : "#394DA1",
                                                }}
                                              >
                                                {item.no} {item.nickname} (
                                                {totalAllTasks
                                                  ? totalAllTasks
                                                  : 0}
                                                )
                                              </div>
                                              <div
                                                className="line"
                                                style={{
                                                  borderBottom: "2px solid",
                                                  width: "100%",
                                                  borderRadius: "20px",
                                                  margin: "10px 0",
                                                }}
                                              ></div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  margin: 0,
                                                  fontWeight: "100",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {renderHeadTeamNames()}
                                              </div>
                                            </div>
                                          </div>
                                          {isLabtop && (
                                            <>
                                              <div
                                                className="grid-item-2"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "2px solid #D9D9D9",
                                                    width: "0px",
                                                    height: "100%",
                                                    margin: "0 10px",
                                                    flexShrink: 0, // ป้องกันการหดตัว
                                                  }}
                                                ></div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <label>
                                                    <p
                                                      style={{
                                                        margin: 0,
                                                        fontSize: "19px",
                                                        textAlign: "end",
                                                      }}
                                                    >
                                                      Progress
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        margin: 0,
                                                        fontSize: "24px",
                                                        color: "#92C700",
                                                        backgroundColor:
                                                          "#F9FFE6",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {totalTasks.toFixed(2)}%
                                                    </p>
                                                  </label>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-end",
                                                    }}
                                                  >
                                                    {Array.from(
                                                      { length: totalBars },
                                                      (_, index) => (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            width: "8px",
                                                            height: "20px",
                                                            backgroundColor:
                                                              index < filledBars
                                                                ? getBarColor(
                                                                    index
                                                                  )
                                                                : "#D9D9D9",
                                                            margin: "0 1.5px",
                                                            borderRadius: "2px",
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {!isTablet && !isLabtop && (
                                            <>
                                              <div
                                                className="grid-item-2"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  // flexWrap: "wrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "2px solid #D9D9D9",
                                                    // borderRadius: "20px",
                                                    height: "100%",
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                ></div>
                                                <div
                                                  className="form-group "
                                                  style={{
                                                    margin: 0,
                                                    paddingRight: "10px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_late
                                                        ? "secondary"
                                                        : "danger"
                                                    }`}
                                                    style={{ width: "120px" }}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_late =
                                                        !temp[index].is_late;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Late Tasks ({lateTasksCount}
                                                    )
                                                  </button>
                                                </div>
                                                <div
                                                  className="form-group "
                                                  style={{
                                                    margin: 0,
                                                    paddingRight: "10px",
                                                  }}
                                                >
                                                  <button
                                                    style={{
                                                      color: "white",
                                                      width: "120px",
                                                    }}
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_current
                                                        ? "secondary"
                                                        : "warning"
                                                    }`}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_current =
                                                        !temp[index].is_current;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Current Tasks (
                                                    {currentTasksCount})
                                                  </button>
                                                </div>
                                                <div
                                                  className="form-group "
                                                  style={{ margin: 0 }}
                                                >
                                                  <button
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_future
                                                        ? "secondary"
                                                        : "primary"
                                                    }`}
                                                    style={{ width: "120px" }}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_future =
                                                        !temp[index].is_future;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Future Tasks (
                                                    {futureTasksCount})
                                                  </button>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <label>
                                                    <p
                                                      style={{
                                                        margin: 0,
                                                        fontSize: "19px",
                                                        textAlign: "end",
                                                      }}
                                                    >
                                                      Progress
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        margin: 0,
                                                        fontSize: "24px",
                                                        color: "#92C700",
                                                        backgroundColor:
                                                          "#F9FFE6",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {totalTasks.toFixed(2)}%
                                                    </p>
                                                  </label>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-end",
                                                    }}
                                                  >
                                                    {Array.from(
                                                      { length: totalBars },
                                                      (_, index) => (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            width: "8px",
                                                            height: "20px",
                                                            backgroundColor:
                                                              index < filledBars
                                                                ? getBarColor(
                                                                    index
                                                                  )
                                                                : "#D9D9D9",
                                                            margin: "0 1.5px",
                                                            borderRadius: "2px",
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="grid-item-3"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderRight:
                                                      "2px solid #D9D9D9",
                                                    // borderRadius: "20px",
                                                    height: "100%",
                                                    margin: "0 10px",
                                                  }}
                                                ></div>
                                                <button
                                                  type="button"
                                                  id="btn_search"
                                                  className="btn btn-block btn-primary"
                                                  onClick={() =>
                                                    addToDo(item.id)
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-plus-circle "
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    ></i>
                                                    Add To Do
                                                  </div>
                                                </button>
                                                <div
                                                  onClick={() =>
                                                    handleRowClick(item.id)
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "40px",
                                                    height: "30px",
                                                    backgroundColor: "#F1F1F1",
                                                    marginLeft: "20px",
                                                    marginRight: "20px",
                                                    borderRadius: "5px",
                                                    color: "#7F7F7F",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {expandedRows.includes(
                                                    item.id
                                                  ) ? (
                                                    <i className="fas fa-chevron-up"></i>
                                                  ) : (
                                                    <i className="fas fa-chevron-down"></i>
                                                  )}
                                                </div>
                                              </div>

                                              {/* <div className="grid-item-4"></div> */}
                                            </>
                                          )}
                                          {/* <div className="grid-item-5"></div> */}
                                        </div>
                                        {expandedRows.includes(item.id) &&
                                          !isTablet &&
                                          !isLabtop && (
                                            <div
                                              style={{
                                                display: "grid",
                                                gridTemplateColumns:
                                                  windowSize.width > 600
                                                    ? "repeat(auto-fill, minmax(400px, 1fr))"
                                                    : "repeat(auto-fill, minmax(300px, 1fr))",
                                                justifyItems: "center",
                                                paddingBottom: "10px",
                                                gap: "10px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              {taskByEmpList.data[item.id]
                                                ?.filter((item2, index) => {
                                                  return shouldIncludeTask(
                                                    item,
                                                    saveCurrentDate,
                                                    index
                                                  );
                                                })
                                                ?.map((item) => {
                                                  return (
                                                    <Assign_Work_Card
                                                      data={item}
                                                      DeleteAssign={
                                                        DeleteAssign
                                                      }
                                                      CheckWork={CheckWork}
                                                      saveCurrentDate={
                                                        saveCurrentDate
                                                      }
                                                      inputData={dupInputData}
                                                      callBackUser={
                                                        callBackUser
                                                      }
                                                      mode={mode}
                                                    />
                                                  );
                                                })}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {isTablet && (
                                      <div className="grid-con-mobile">
                                        <div
                                          className="grid-item-mobile-2"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // flexWrap: "wrap",
                                          }}
                                        >
                                          {/* <div
                                          style={{
                                            borderLeft: "2px solid #D9D9D9",
                                            // borderRadius: "20px",
                                            height: "100%",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                          }}
                                        ></div> */}
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_late
                                                  ? "secondary"
                                                  : "danger"
                                              }`}
                                              // style={{ width: "120px" }}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_late =
                                                  !temp[index].is_late;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Late Tasks ({lateTasksCount})
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="grid-item-mobile-3"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              style={{
                                                color: "white",
                                                // width: "120px",
                                              }}
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_current
                                                  ? "secondary"
                                                  : "warning"
                                              }`}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_current =
                                                  !temp[index].is_current;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Current Tasks ({currentTasksCount}
                                              )
                                            </button>
                                          </div>
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              width: "100%",
                                              padding: "10px",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_future
                                                  ? "secondary"
                                                  : "primary"
                                              }`}
                                              // style={{ width: "120px" }}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_future =
                                                  !temp[index].is_future;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Future Tasks ({futureTasksCount})
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="grid-item-mobile-4"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className="btn btn-block btn-primary"
                                              onClick={() => addToDo(item.id)}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-plus-circle "
                                                  style={{ marginRight: "5px" }}
                                                ></i>
                                                Add To Do
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleRowClick(item.id)
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "30px",
                                              height: "30px",
                                              backgroundColor: "#F1F1F1",
                                              marginLeft: "20px",
                                              marginRight: "20px",
                                              borderRadius: "5px",
                                              color: "#7F7F7F",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {expandedRows.includes(item.id) ? (
                                              <i className="fas fa-chevron-up"></i>
                                            ) : (
                                              <i className="fas fa-chevron-down"></i>
                                            )}
                                          </div>
                                        </div>
                                        {expandedRows.includes(item.id) && (
                                          <div
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns:
                                                windowSize.width > 600
                                                  ? "repeat(auto-fill, minmax(400px, 1fr))"
                                                  : "repeat(auto-fill, minmax(300px, 1fr))",
                                              justifyItems: "center",
                                              paddingBottom: "10px",
                                              gap: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {taskByEmpList.data[item.id]
                                              ?.filter((item2, index) => {
                                                return shouldIncludeTask(
                                                  item,
                                                  saveCurrentDate,
                                                  index
                                                );
                                              })
                                              ?.map((item) => {
                                                return (
                                                  <Assign_Work_Card
                                                    data={item}
                                                    DeleteAssign={DeleteAssign}
                                                    CheckWork={CheckWork}
                                                    saveCurrentDate={
                                                      saveCurrentDate
                                                    }
                                                    inputData={dupInputData}
                                                    callBackUser={callBackUser}
                                                    mode={mode}
                                                  />
                                                );
                                              })}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {isLabtop && !isTablet && (
                                      <div>
                                        <div className="grid-con-labtop">
                                          <div
                                            className="grid-item-labtop-1"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              // flexWrap: "wrap",
                                            }}
                                          >
                                            {/* <div
                                          style={{
                                            borderLeft: "2px solid #D9D9D9",
                                            // borderRadius: "20px",
                                            height: "100%",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                          }}
                                        ></div> */}
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_late
                                                    ? "secondary"
                                                    : "danger"
                                                }`}
                                                style={{ width: "120px" }}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_late =
                                                    !temp[index].is_late;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Late Tasks ({lateTasksCount})
                                              </button>
                                            </div>
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <button
                                                style={{
                                                  color: "white",
                                                  width: "120px",
                                                }}
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_current
                                                    ? "secondary"
                                                    : "warning"
                                                }`}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_current =
                                                    !temp[index].is_current;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Current Tasks (
                                                {currentTasksCount})
                                              </button>
                                            </div>
                                            <div
                                              className="form-group "
                                              style={{ margin: 0 }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_future
                                                    ? "secondary"
                                                    : "primary"
                                                }`}
                                                style={{ width: "120px" }}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_future =
                                                    !temp[index].is_future;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Future Tasks ({futureTasksCount}
                                                )
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="grid-item-labtop-2"
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                                width: "120px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className="btn btn-block btn-primary"
                                                onClick={() => addToDo(item.id)}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-plus-circle "
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  ></i>
                                                  Add To Do
                                                </div>
                                              </button>
                                            </div>
                                            <div
                                              onClick={() =>
                                                handleRowClick(item.id)
                                              }
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "30px",
                                                height: "30px",
                                                backgroundColor: "#F1F1F1",
                                                marginLeft: "20px",
                                                marginRight: "20px",
                                                borderRadius: "5px",
                                                color: "#7F7F7F",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {expandedRows.includes(
                                                item.id
                                              ) ? (
                                                <i className="fas fa-chevron-up"></i>
                                              ) : (
                                                <i className="fas fa-chevron-down"></i>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {expandedRows.includes(item.id) && (
                                          <div
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns:
                                                windowSize.width > 600
                                                  ? "repeat(auto-fill, minmax(400px, 1fr))"
                                                  : "repeat(auto-fill, minmax(300px, 1fr))",
                                              justifyItems: "center",
                                              paddingBottom: "10px",
                                              gap: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {taskByEmpList.data[item.id]
                                              ?.filter((item2, index) => {
                                                return shouldIncludeTask(
                                                  item,
                                                  saveCurrentDate,
                                                  index
                                                );
                                              })
                                              ?.map((item) => {
                                                return (
                                                  <Assign_Work_Card
                                                    data={item}
                                                    DeleteAssign={DeleteAssign}
                                                    CheckWork={CheckWork}
                                                    saveCurrentDate={
                                                      saveCurrentDate
                                                    }
                                                    inputData={dupInputData}
                                                    callBackUser={callBackUser}
                                                    mode={mode}
                                                  />
                                                );
                                              })}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer" />
              </div>
            ) : (
              // <div className="card">
              //   <div className="card-header">
              //     {/* <h3 className="card-title">Project</h3> */}
              //     <div className="card-tools"></div>
              //   </div>

              //   <div className="card-body">
              //     <div
              //       className="table-responsive "
              //       style={{ whiteSpace: "nowrap" }}
              //     >
              //       <MDBDataTable
              //         sortable={false}
              //         className="table table-head-fixed text-center"
              //         striped
              //         bordered
              //         hover
              //         fixedHeader
              //         data={data}
              //       />
              //     </div>
              //   </div>
              //   <div className="card-footer" />
              // </div>

              <div className="card card-primary card-outline card-outline-tabs">
                <div className="card-header p-0 border-bottom-0">
                  <ul
                    className="nav nav-tabs"
                    id="custom-to-do-list-tab"
                    role="tablist"
                  >
                    <li className="nav-item active">
                      <a
                        // className="nav-link "
                        className={`nav-link ${
                          callBackUser == 1 ? "active" : ""
                        }`}
                        id="to-do-list-assigned-tab"
                        data-toggle="pill"
                        href="#to-do-list-assigned"
                        role="tab"
                        aria-controls="to-do-list-assigned"
                        aria-selected="true"
                        onClick={() => {
                          // sessionStorage.setItem(
                          //   "",
                          //   JSON.stringify({ item: "assigned" })
                          // );
                          setCallBackUser("1");
                          callBackUser = "1";
                          FilterAssign();
                          TableByEmp();
                        }}
                      >
                        มอบหมายงาน{" "}
                        {`( ${getAssignToNameLength(assignData.data)} )`}
                      </a>
                    </li>

                    <li className="nav-item ">
                      <a
                        // className="nav-link"
                        className={`nav-link ${
                          callBackUser == 2 ? "active" : ""
                        }`}
                        id="to-do-list-all-tab"
                        data-toggle="pill"
                        href="#to-do-list-all"
                        role="tab"
                        aria-controls="to-do-list-all"
                        aria-selected="false"
                        onClick={() => {
                          // sessionStorage.removeItem("callBackUser");
                          setCallBackUser("2");
                          callBackUser = "2";
                          FilterAssign();
                          TableByEmp();
                        }}
                      >
                        ทั้งหมด {`( ${assignData.data.length} )`}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body" style={{ padding: "20px 10px" }}>
                  <div
                    className="tab-content"
                    id="custom-to-do-list-tab-content"
                  >
                    <div
                      // className="tab-pane fade show active"
                      className={`tab-pane fade ${
                        callBackUser == 2 ? "show active" : ""
                      }`}
                      id="to-do-list-all"
                      role="tabpanel"
                      aria-labelledby="to-do-list-all-tab"
                    >
                      <div
                        className="table-responsive "
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed text-center"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={data}
                        />
                      </div>
                    </div>

                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        callBackUser == 1 ? "show active" : ""
                      }`}
                      id="to-do-list-assigned"
                      role="tabpanel"
                      aria-labelledby="to-do-list-assigned-tab"
                    >
                      <div
                        className="table-responsive"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <section className="content">
                          <div className="row" style={{ margin: 0 }}>
                            <div className="col-12">
                              {tmIntm.data.map((item, index) => {
                                const lateTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) >
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          late.assign_by_status_id !==
                                            "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const currentTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) <=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) >=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const futureTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) <
                                          new Date(late.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const lateTasksSuccessCount = currentTasks.data[
                                  item.id
                                ]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) >
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          late.assign_by_status_id !==
                                            "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const currentTasksSuccessCount = currentTasks
                                  .data[item.id]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.startDate
                                          ).setHours(0, 0, 0, 0) <=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            ) &&
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) >=
                                            new Date(late.due_date).setHours(
                                              0,
                                              0,
                                              0,
                                              0
                                            )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const futureTasksSuccessCount = currentTasks
                                  .data[item.id]
                                  ? currentTasks.data[item.id].filter(
                                      (late) => {
                                        return (
                                          new Date(
                                            saveCurrentDate.endDate
                                          ).setHours(0, 0, 0, 0) <
                                          new Date(late.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                        );
                                      }
                                    ).length
                                  : 0;

                                const successedTasksCount = taskByEmpList.data[
                                  item.id
                                ]
                                  ? taskByEmpList.data[item.id].filter(
                                      (late) => {
                                        return (
                                          late.assign_by_status_id ===
                                          "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                                        );
                                      }
                                    ).length
                                  : 0;

                                const totalAllTasks =
                                  lateTasksSuccessCount +
                                  currentTasksSuccessCount +
                                  futureTasksSuccessCount;

                                const passCurrent = currentTasks.data[item.id]
                                  ? currentTasks.data[item.id].filter(
                                      (el) =>
                                        el.assign_by_status_id ===
                                          "f46730e7-9980-46b5-a4e5-7967550e4ea2" &&
                                        new Date(
                                          saveCurrentDate.startDate
                                        ).setHours(0, 0, 0, 0) <=
                                          new Date(el.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          ) &&
                                        new Date(
                                          saveCurrentDate.endDate
                                        ).setHours(0, 0, 0, 0) >=
                                          new Date(el.due_date).setHours(
                                            0,
                                            0,
                                            0,
                                            0
                                          )
                                    ).length
                                  : 0;

                                const totalTasks =
                                  (Number(passCurrent) /
                                    Number(currentTasksSuccessCount)) *
                                    100 || 0;
                                const filledBars = Math.round(
                                  (totalTasks / 100) * totalBars
                                );

                                const renderHeadTeamNames = () => {
                                  if (!Array.isArray(headTeam)) {
                                    console.log(
                                      "headTeam is not an array:",
                                      headTeam
                                    ); // Log if headTeam is not an array
                                    return null;
                                  }

                                  const names = headTeam
                                    .filter(
                                      (head) => item.id === head.employee_id
                                    ) // Filter only matching employees
                                    .map((head) => (
                                      <p
                                        style={{ margin: 0 }}
                                        key={head.employee_id}
                                      >
                                        ผู้ประเมิน : {head.emp_no}{" "}
                                        {head.nickname}
                                      </p>
                                    ));

                                  if (names.length === 0) {
                                    // console.log("No matching employees found");
                                    return null;
                                  }

                                  return names;
                                };
                                // filteredData
                                const imagePath = filteredData.data
                                  ? filteredData.data
                                      .filter((el) => el.emp_id === item.id)
                                      .map((el) => el.file_path)
                                  : "";
                                return (
                                  <div>
                                    <div
                                      className="card"
                                      style={{
                                        border: "0.5px solid #B6B6B6",
                                        boxShadow: "4px 4px 4px #B6B6B6",
                                        width: "100%",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div
                                        className="card-body"
                                        style={{ padding: "10px" }}
                                      >
                                        <div
                                          hidden={!item.is_child}
                                          style={{
                                            height: "100%",
                                            background:
                                              "linear-gradient(#004aad, #5de0e6)",
                                            position: "absolute",
                                            width: "7px",
                                            left: 0,
                                            top: 0,
                                            borderRadius: "7.5px 0 0 7.5px",
                                          }}
                                        ></div>
                                        <div className="grid-con">
                                          <div
                                            className="grid-item-1"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              whiteSpace: "break-spaces",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <img
                                              alt="..."
                                              className="img-fluid"
                                              style={{
                                                width: "68px",
                                                height: "68px",
                                                marginRight: "10px",
                                                objectFit: "cover",
                                                border: "2px solid #394DA1",
                                                borderRadius: "50%",
                                              }}
                                              src={
                                                imagePath.length > 0 &&
                                                imagePath[0]
                                                  ? Configs.API_URL_IMG +
                                                    imagePath[0]
                                                  : TTT_Person
                                              }
                                            />
                                            <div
                                              style={{
                                                width: "100%",
                                                color: "#394DA1",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  margin: 0,
                                                  fontSize: "22px",
                                                  color:
                                                    currentTasksSuccessCount ===
                                                    0
                                                      ? `red`
                                                      : "#394DA1",
                                                }}
                                              >
                                                {item.no} {item.nickname} (
                                                {totalAllTasks
                                                  ? totalAllTasks
                                                  : 0}
                                                )
                                              </div>
                                              <div
                                                className="line"
                                                style={{
                                                  borderBottom: "2px solid",
                                                  width: "100%",
                                                  borderRadius: "20px",
                                                  margin: "10px 0",
                                                }}
                                              ></div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  margin: 0,
                                                  fontWeight: "100",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {renderHeadTeamNames()}
                                              </div>
                                            </div>
                                          </div>
                                          {isLabtop && (
                                            <>
                                              <div
                                                className="grid-item-2"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "2px solid #D9D9D9",
                                                    width: "0px",
                                                    height: "100%",
                                                    margin: "0 10px",
                                                    flexShrink: 0, // ป้องกันการหดตัว
                                                  }}
                                                ></div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <label>
                                                    <p
                                                      style={{
                                                        margin: 0,
                                                        fontSize: "19px",
                                                        textAlign: "end",
                                                      }}
                                                    >
                                                      Progress
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        margin: 0,
                                                        fontSize: "24px",
                                                        color: "#92C700",
                                                        backgroundColor:
                                                          "#F9FFE6",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {totalTasks.toFixed(2)}%
                                                    </p>
                                                  </label>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-end",
                                                    }}
                                                  >
                                                    {Array.from(
                                                      { length: totalBars },
                                                      (_, index) => (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            width: "8px",
                                                            height: "20px",
                                                            backgroundColor:
                                                              index < filledBars
                                                                ? getBarColor(
                                                                    index
                                                                  )
                                                                : "#D9D9D9",
                                                            margin: "0 1.5px",
                                                            borderRadius: "2px",
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {!isTablet && !isLabtop && (
                                            <>
                                              <div
                                                className="grid-item-2"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  // flexWrap: "wrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "2px solid #D9D9D9",
                                                    // borderRadius: "20px",
                                                    height: "100%",
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                  }}
                                                ></div>
                                                <div
                                                  className="form-group "
                                                  style={{
                                                    margin: 0,
                                                    paddingRight: "10px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_late
                                                        ? "secondary"
                                                        : "danger"
                                                    }`}
                                                    style={{ width: "120px" }}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_late =
                                                        !temp[index].is_late;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Late Tasks ({lateTasksCount}
                                                    )
                                                  </button>
                                                </div>
                                                <div
                                                  className="form-group "
                                                  style={{
                                                    margin: 0,
                                                    paddingRight: "10px",
                                                  }}
                                                >
                                                  <button
                                                    style={{
                                                      color: "white",
                                                      width: "120px",
                                                    }}
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_current
                                                        ? "secondary"
                                                        : "warning"
                                                    }`}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_current =
                                                        !temp[index].is_current;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Current Tasks (
                                                    {currentTasksCount})
                                                  </button>
                                                </div>
                                                <div
                                                  className="form-group "
                                                  style={{ margin: 0 }}
                                                >
                                                  <button
                                                    type="button"
                                                    id="btn_search"
                                                    className={`btn btn-block btn-${
                                                      item.is_future
                                                        ? "secondary"
                                                        : "primary"
                                                    }`}
                                                    style={{ width: "120px" }}
                                                    onClick={() => {
                                                      const temp = tmIntm.data;
                                                      temp[index].is_future =
                                                        !temp[index].is_future;
                                                      settmIntm({ data: temp });
                                                      tmIntm.data = temp;
                                                      TableByEmp();
                                                    }}
                                                  >
                                                    Future Tasks (
                                                    {futureTasksCount})
                                                  </button>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <label>
                                                    <p
                                                      style={{
                                                        margin: 0,
                                                        fontSize: "19px",
                                                        textAlign: "end",
                                                      }}
                                                    >
                                                      Progress
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        margin: 0,
                                                        fontSize: "24px",
                                                        color: "#92C700",
                                                        backgroundColor:
                                                          "#F9FFE6",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {totalTasks.toFixed(2)}%
                                                    </p>
                                                  </label>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-end",
                                                    }}
                                                  >
                                                    {Array.from(
                                                      { length: totalBars },
                                                      (_, index) => (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            width: "8px",
                                                            height: "20px",
                                                            backgroundColor:
                                                              index < filledBars
                                                                ? getBarColor(
                                                                    index
                                                                  )
                                                                : "#D9D9D9",
                                                            margin: "0 1.5px",
                                                            borderRadius: "2px",
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="grid-item-3"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    borderRight:
                                                      "2px solid #D9D9D9",
                                                    // borderRadius: "20px",
                                                    height: "100%",
                                                    margin: "0 10px",
                                                  }}
                                                ></div>
                                                <button
                                                  type="button"
                                                  id="btn_search"
                                                  className="btn btn-block btn-primary"
                                                  onClick={() =>
                                                    addToDo(item.id)
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-plus-circle "
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    ></i>
                                                    Add To Do
                                                  </div>
                                                </button>
                                                <div
                                                  onClick={() =>
                                                    handleRowClick(item.id)
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "40px",
                                                    height: "30px",
                                                    backgroundColor: "#F1F1F1",
                                                    marginLeft: "20px",
                                                    marginRight: "20px",
                                                    borderRadius: "5px",
                                                    color: "#7F7F7F",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {expandedRows.includes(
                                                    item.id
                                                  ) ? (
                                                    <i className="fas fa-chevron-up"></i>
                                                  ) : (
                                                    <i className="fas fa-chevron-down"></i>
                                                  )}
                                                </div>
                                              </div>

                                              {/* <div className="grid-item-4"></div> */}
                                            </>
                                          )}
                                          {/* <div className="grid-item-5"></div> */}
                                        </div>
                                        {expandedRows.includes(item.id) &&
                                          !isTablet &&
                                          !isLabtop && (
                                            <div
                                              className="sub-table"
                                              style={{
                                                backgroundColor: "#fafafa",
                                                padding: "5px",
                                                marginTop: "10px",
                                                boxShadow:
                                                  "0 0 1px rgba(0,0,0,.125), 0 1px 10px rgba(0,0,0,.2)",
                                                overflow: "scroll",
                                              }}
                                            >
                                              <MDBDataTable
                                                sortable={false}
                                                className="table table-head-fixed text-center"
                                                striped
                                                bordered
                                                hover
                                                fixedHeader
                                                data={
                                                  tableByEmpList.data[item.id]
                                                }
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {isTablet && (
                                      <div className="grid-con-mobile">
                                        <div
                                          className="grid-item-mobile-2"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // flexWrap: "wrap",
                                          }}
                                        >
                                          {/* <div
                                          style={{
                                            borderLeft: "2px solid #D9D9D9",
                                            // borderRadius: "20px",
                                            height: "100%",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                          }}
                                        ></div> */}
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_late
                                                  ? "secondary"
                                                  : "danger"
                                              }`}
                                              // style={{ width: "120px" }}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_late =
                                                  !temp[index].is_late;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Late Tasks ({lateTasksCount})
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="grid-item-mobile-3"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              style={{
                                                color: "white",
                                                // width: "120px",
                                              }}
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_current
                                                  ? "secondary"
                                                  : "warning"
                                              }`}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_current =
                                                  !temp[index].is_current;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Current Tasks ({currentTasksCount}
                                              )
                                            </button>
                                          </div>
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              width: "100%",
                                              padding: "10px",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className={`btn btn-block btn-${
                                                item.is_future
                                                  ? "secondary"
                                                  : "primary"
                                              }`}
                                              // style={{ width: "120px" }}
                                              onClick={() => {
                                                const temp = tmIntm.data;
                                                temp[index].is_future =
                                                  !temp[index].is_future;
                                                settmIntm({ data: temp });
                                                tmIntm.data = temp;
                                                TableByEmp();
                                              }}
                                            >
                                              Future Tasks ({futureTasksCount})
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="grid-item-mobile-4"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className="form-group "
                                            style={{
                                              margin: 0,
                                              padding: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              id="btn_search"
                                              className="btn btn-block btn-primary"
                                              onClick={() => addToDo(item.id)}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-plus-circle "
                                                  style={{ marginRight: "5px" }}
                                                ></i>
                                                Add To Do
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleRowClick(item.id)
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "30px",
                                              height: "30px",
                                              backgroundColor: "#F1F1F1",
                                              marginLeft: "20px",
                                              marginRight: "20px",
                                              borderRadius: "5px",
                                              color: "#7F7F7F",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {expandedRows.includes(item.id) ? (
                                              <i className="fas fa-chevron-up"></i>
                                            ) : (
                                              <i className="fas fa-chevron-down"></i>
                                            )}
                                          </div>
                                        </div>
                                        {expandedRows.includes(item.id) && (
                                          <div
                                            className="sub-table"
                                            style={{
                                              backgroundColor: "#fafafa",
                                              padding: "5px",
                                              marginTop: "10px",
                                              boxShadow:
                                                "0 0 1px rgba(0,0,0,.125), 0 1px 10px rgba(0,0,0,.2)",
                                              overflow: "scroll",
                                            }}
                                          >
                                            <MDBDataTable
                                              sortable={false}
                                              className="table table-head-fixed text-center"
                                              striped
                                              bordered
                                              hover
                                              fixedHeader
                                              data={
                                                tableByEmpList.data[item.id]
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {isLabtop && !isTablet && (
                                      <div>
                                        <div className="grid-con-labtop">
                                          <div
                                            className="grid-item-labtop-1"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              // flexWrap: "wrap",
                                            }}
                                          >
                                            {/* <div
                                          style={{
                                            borderLeft: "2px solid #D9D9D9",
                                            // borderRadius: "20px",
                                            height: "100%",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                          }}
                                        ></div> */}
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_late
                                                    ? "secondary"
                                                    : "danger"
                                                }`}
                                                style={{ width: "120px" }}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_late =
                                                    !temp[index].is_late;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Late Tasks ({lateTasksCount})
                                              </button>
                                            </div>
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <button
                                                style={{
                                                  color: "white",
                                                  width: "120px",
                                                }}
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_current
                                                    ? "secondary"
                                                    : "warning"
                                                }`}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_current =
                                                    !temp[index].is_current;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Current Tasks (
                                                {currentTasksCount})
                                              </button>
                                            </div>
                                            <div
                                              className="form-group "
                                              style={{ margin: 0 }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className={`btn btn-block btn-${
                                                  item.is_future
                                                    ? "secondary"
                                                    : "primary"
                                                }`}
                                                style={{ width: "120px" }}
                                                onClick={() => {
                                                  const temp = tmIntm.data;
                                                  temp[index].is_future =
                                                    !temp[index].is_future;
                                                  settmIntm({ data: temp });
                                                  tmIntm.data = temp;
                                                  TableByEmp();
                                                }}
                                              >
                                                Future Tasks ({futureTasksCount}
                                                )
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="grid-item-labtop-2"
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              className="form-group "
                                              style={{
                                                margin: 0,
                                                paddingRight: "10px",
                                                width: "120px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                id="btn_search"
                                                className="btn btn-block btn-primary"
                                                onClick={() => addToDo(item.id)}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-plus-circle "
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  ></i>
                                                  Add To Do
                                                </div>
                                              </button>
                                            </div>
                                            <div
                                              onClick={() =>
                                                handleRowClick(item.id)
                                              }
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "30px",
                                                height: "30px",
                                                backgroundColor: "#F1F1F1",
                                                marginLeft: "20px",
                                                marginRight: "20px",
                                                borderRadius: "5px",
                                                color: "#7F7F7F",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {expandedRows.includes(
                                                item.id
                                              ) ? (
                                                <i className="fas fa-chevron-up"></i>
                                              ) : (
                                                <i className="fas fa-chevron-down"></i>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {expandedRows.includes(item.id) && (
                                          <div
                                            className="sub-table"
                                            style={{
                                              backgroundColor: "#fafafa",
                                              padding: "5px",
                                              marginTop: "10px",
                                              boxShadow:
                                                "0 0 1px rgba(0,0,0,.125), 0 1px 10px rgba(0,0,0,.2)",
                                              overflow: "scroll",
                                              marginBottom: "20px",
                                            }}
                                          >
                                            <MDBDataTable
                                              sortable={false}
                                              className="table table-head-fixed text-center"
                                              striped
                                              bordered
                                              hover
                                              fixedHeader
                                              data={
                                                tableByEmpList.data[item.id]
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer" />
              </div>
            )}
          </section>

          <div className="modal fade" id="modal-xl2"
            style={{ overflowY: 'auto' }}
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">ประเมินประจําสัปดาห์</h4>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    //onClick={close}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="row">
                          <div className="col-2 col-xl-3">
                            <div className="form-group">
                              <button
                                type="button"
                                id="btn_clear"
                                className="btn btn-block btn-primary"
                                style={{ width: "auto" }}
                                onClick={() => {
                                  Number(showWeek) === 1
                                    ? setShowWeek(Number(allWeek))
                                    : setShowWeek(Number(showWeek) - 1);

                                  setShowDate({
                                    ...showDate,
                                    startDate: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) - 1)
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .date(),
                                    startMonth: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) - 1)
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    endDate: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) - 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .date(),
                                    endMonth: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) - 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    year: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) - 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .year(),
                                  });
                                }}
                              >
                                <span
                                  className="fa fa-chevron-left"
                                  style={{ verticalAlign: "middle" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="col-8 col-xl-6">
                            <div className="row">
                              <div className="col">
                                <div
                                  style={{
                                    position: "relative",
                                    padding: "10px 0px",
                                    marginBottom: "1rem",
                                    textAlign: "center",
                                  }}
                                >
                                  <label
                                    style={{
                                      fontSize: "1.4rem",
                                      margin: "4px 0px 0px 0px",
                                    }}
                                  >
                                    Week
                                  </label>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    style={{
                                      textAlign: "center",
                                    }}
                                    onChange={(e) => {
                                      if (
                                        Number(e.target.value) < 1 &&
                                        e.target.value !== ""
                                      ) {
                                        setShowWeek(1);
                                      } else if (
                                        Number(e.target.value) >
                                          Number(allWeek) &&
                                        e.target.value !== ""
                                      ) {
                                        setShowWeek(Number(allWeek));
                                      } else {
                                        setShowWeek(e.target.value);
                                      }

                                      setShowDate({
                                        ...showDate,
                                        startDate: moment()
                                          .year(Number(showYear))
                                          .week(
                                            Number(e.target.value) < 1 &&
                                              e.target.value !== ""
                                              ? 1
                                              : Number(e.target.value) >
                                                  allWeek &&
                                                e.target.value !== ""
                                              ? Number(allWeek)
                                              : Number(e.target.value)
                                          )
                                          .clone()
                                          .startOf("week")
                                          .add(1, "days")
                                          .date(),
                                        startMonth: moment()
                                          .year(Number(showYear))
                                          .week(
                                            Number(e.target.value) < 1 &&
                                              e.target.value !== ""
                                              ? 1
                                              : Number(e.target.value) >
                                                  allWeek &&
                                                e.target.value !== ""
                                              ? Number(allWeek)
                                              : Number(e.target.value)
                                          )
                                          .clone()
                                          .startOf("week")
                                          .add(1, "days")
                                          .locale("en")
                                          .format("MMM"),
                                        endDate: moment()
                                          .year(Number(showYear))
                                          .week(
                                            Number(e.target.value) < 1 &&
                                              e.target.value !== ""
                                              ? 1
                                              : Number(e.target.value) >
                                                  allWeek &&
                                                e.target.value !== ""
                                              ? Number(allWeek)
                                              : Number(e.target.value)
                                          )
                                          .clone()
                                          .startOf("week")
                                          .subtract(1, "days")
                                          .date(),
                                        endMonth: moment()
                                          .year(Number(showYear))
                                          .week(
                                            Number(e.target.value) < 1 &&
                                              e.target.value !== ""
                                              ? 1
                                              : Number(e.target.value) >
                                                  allWeek &&
                                                e.target.value !== ""
                                              ? Number(allWeek)
                                              : Number(e.target.value)
                                          )
                                          .clone()
                                          .startOf("week")
                                          .subtract(1, "days")
                                          .locale("en")
                                          .format("MMM"),
                                        year:
                                          moment()
                                            .year(Number(showYear))
                                            .week(
                                              Number(e.target.value) < 1 &&
                                                e.target.value !== ""
                                                ? 1
                                                : Number(e.target.value) >
                                                    allWeek &&
                                                  e.target.value !== ""
                                                ? Number(allWeek)
                                                : Number(e.target.value)
                                            )
                                            .clone()
                                            .startOf("week")
                                            .add(1, "days")
                                            .month() >
                                          moment()
                                            .year(Number(showYear))
                                            .week(
                                              Number(e.target.value) < 1 &&
                                                e.target.value !== ""
                                                ? 1
                                                : Number(e.target.value) >
                                                    allWeek &&
                                                  e.target.value !== ""
                                                ? Number(allWeek)
                                                : Number(e.target.value)
                                            )
                                            .clone()
                                            .startOf("week")
                                            .subtract(1, "days")
                                            .month()
                                            ? moment()
                                                .year(Number(showYear))
                                                .week(
                                                  Number(e.target.value) < 1 &&
                                                    e.target.value !== ""
                                                    ? 1
                                                    : Number(e.target.value) >
                                                        allWeek &&
                                                      e.target.value !== ""
                                                    ? Number(allWeek)
                                                    : Number(e.target.value)
                                                )
                                                .clone()
                                                .startOf("week")
                                                .subtract(1, "days")
                                                .year() + 1
                                            : moment()
                                                .year(Number(showYear))
                                                .week(
                                                  Number(e.target.value) < 1 &&
                                                    e.target.value !== ""
                                                    ? 1
                                                    : Number(e.target.value) >
                                                        allWeek &&
                                                      e.target.value !== ""
                                                    ? Number(allWeek)
                                                    : Number(e.target.value)
                                                )
                                                .clone()
                                                .startOf("week")
                                                .subtract(1, "days")
                                                .year(),
                                      });
                                    }}
                                    value={showWeek}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-2 col-xl-3"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="form-group">
                              <button
                                type="button"
                                id="btn_clear"
                                className="btn btn-block btn-primary"
                                style={{ width: "auto" }}
                                onClick={() => {
                                  Number(showWeek) === Number(allWeek)
                                    ? setShowWeek(1)
                                    : setShowWeek(Number(showWeek) + 1);

                                  setShowDate({
                                    ...showDate,
                                    startDate: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) + 1)
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .date(),
                                    startMonth: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) + 1)
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    endDate: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) + 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .date(),
                                    endMonth: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) + 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    year: moment()
                                      .year(Number(showYear))
                                      .week(Number(showWeek) + 1)
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .year(),
                                  });
                                }}
                              >
                                <span
                                  className="fa fa-chevron-right"
                                  style={{ verticalAlign: "middle" }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <button
                                type="button"
                                id="btn_clear"
                                className="btn btn-block btn-primary"
                                style={{ width: "auto" }}
                                onClick={() => {
                                  setShowYear(Number(showYear) - 1);
                                  setAllWeek(
                                    moment()
                                      .year(Number(showYear) - 1)
                                      .weeksInYear()
                                  );

                                  if (
                                    moment()
                                      .year(Number(showYear) + 1)
                                      .weeksInYear() < allWeek &&
                                    moment()
                                      .year(Number(showYear) + 1)
                                      .weeksInYear() < showWeek
                                  ) {
                                    setShowWeek(
                                      moment()
                                        .year(Number(showYear) - 1)
                                        .weeksInYear()
                                    );
                                  }

                                  setShowDate({
                                    ...showDate,
                                    startDate: moment()
                                      .year(Number(showYear) - 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .date(),
                                    startMonth: moment()
                                      .year(Number(showYear) - 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    endDate: moment()
                                      .year(Number(showYear) - 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .date(),
                                    endMonth: moment()
                                      .year(Number(showYear) - 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    year: moment()
                                      .year(Number(showYear) - 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .year(),
                                  });
                                }}
                              >
                                <span
                                  className="fa fa-chevron-left"
                                  style={{ verticalAlign: "middle" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="row">
                              <div className="col">
                                <div
                                  style={{
                                    position: "relative",
                                    padding: "10px 0px",
                                    marginBottom: "1rem",
                                    textAlign: "center",
                                  }}
                                >
                                  <label
                                    style={{
                                      fontSize: "1.4rem",
                                      margin: "4px 0px 0px 0px",
                                    }}
                                  >
                                    Year
                                  </label>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    style={{
                                      textAlign: "center",
                                    }}
                                    onChange={(e) => {
                                      setShowYear(e.target.value);
                                      setAllWeek(
                                        moment()
                                          .year(Number(e.target.value))
                                          .weeksInYear()
                                      );

                                      if (
                                        moment()
                                          .year(Number(e.target.value))
                                          .weeksInYear() < allWeek
                                      ) {
                                        setShowWeek(
                                          moment()
                                            .year(Number(e.target.value))
                                            .weeksInYear()
                                        );
                                      }

                                      setShowDate({
                                        ...showDate,
                                        startDate: moment()
                                          .year(Number(e.target.value))
                                          .week(Number(showWeek))
                                          .clone()
                                          .startOf("week")
                                          .add(1, "days")
                                          .date(),
                                        startMonth: moment()
                                          .year(Number(e.target.value))
                                          .week(Number(showWeek))
                                          .clone()
                                          .startOf("week")
                                          .add(1, "days")
                                          .locale("en")
                                          .format("MMM"),
                                        endDate: moment()
                                          .year(Number(e.target.value))
                                          .week(Number(showWeek))
                                          .clone()
                                          .endOf("week")
                                          .subtract(1, "days")
                                          .date(),
                                        endMonth: moment()
                                          .year(Number(e.target.value))
                                          .week(Number(showWeek))
                                          .clone()
                                          .endOf("week")
                                          .subtract(1, "days")
                                          .locale("en")
                                          .format("MMM"),
                                        year: moment()
                                          .year(Number(e.target.value))
                                          .week(Number(showWeek))
                                          .clone()
                                          .endOf("week")
                                          .subtract(1, "days")
                                          .year(),
                                      });
                                    }}
                                    value={showYear}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="form-group">
                              <button
                                type="button"
                                id="btn_clear"
                                className="btn btn-block btn-primary"
                                style={{ width: "auto" }}
                                onClick={() => {
                                  setShowYear(Number(showYear) + 1);
                                  setAllWeek(
                                    moment()
                                      .year(Number(showYear) + 1)
                                      .weeksInYear()
                                  );

                                  if (
                                    moment()
                                      .year(Number(showYear) + 1)
                                      .weeksInYear() < allWeek &&
                                    moment()
                                      .year(Number(showYear) + 1)
                                      .weeksInYear() < showWeek
                                  ) {
                                    setShowWeek(
                                      moment()
                                        .year(Number(showYear) + 1)
                                        .weeksInYear()
                                    );
                                  }

                                  setShowDate({
                                    ...showDate,
                                    startDate: moment()
                                      .year(Number(showYear) + 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .date(),
                                    startMonth: moment()
                                      .year(Number(showYear) + 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .startOf("week")
                                      .add(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    endDate: moment()
                                      .year(Number(showYear) + 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .date(),
                                    endMonth: moment()
                                      .year(Number(showYear) + 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .locale("en")
                                      .format("MMM"),
                                    year: moment()
                                      .year(Number(showYear) + 1)
                                      .week(Number(showWeek))
                                      .clone()
                                      .endOf("week")
                                      .subtract(1, "days")
                                      .year(),
                                  });
                                }}
                              >
                                <span
                                  className="fa fa-chevron-right"
                                  style={{ verticalAlign: "middle" }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-2">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            {`${showDate.startDate} ${showDate.startMonth} - ${showDate.endDate} ${showDate.endMonth} ${showDate.year}`}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-1">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-primary"
                            onClick={async () => {
                              await FilterEvaMember(showWeek, showYear);

                              const currentDate = new Date();
                              // const startDate = new Date(
                              //   currentDate.getFullYear(),
                              //   0,
                              //   1
                              // );
                              // let days = Math.floor(
                              //   (currentDate - startDate) / (24 * 60 * 60 * 1000)
                              // );
                              // let weekNumber = Math.ceil(days / 7);

                              if (showWeek === "") {
                                setSaveWeek(
                                  moment(currentDate).isoWeekday(1).week()
                                );
                              } else {
                                setSaveWeek(showWeek);
                              }
                              if (showYear === "") {
                                setSaveYear(moment(currentDate).year());
                              } else {
                                setSaveYear(showYear);
                              }
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-1">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_clear"
                            className="btn btn-block btn-primary"
                            onClick={clearFilterModal}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div
                        className="table-responsive "
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed text-center"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={dataEva}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-center"></div>
              </div>
            </div>
          </div>

          <div
            className="modal fade test-modal"
            id="modal-sm2"
            /*    data-backdrop="static" */
            style={{ zIndex: 1090, backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog modal-md modal-dialog-centered">
              <div className="modal-content">
                {/* <div className="modal-header">

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setNoteModalData({
                        is_open: false,
                        week_status_id: "",
                        employee_id: "",
                        id: "",
                        note: "",
                        is_new: true,
                      });
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div> */}
                <div className="modal-body">
                  <div className="container-fluid">
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "30px",
                        fontWeight: "bold",
                        marginBottom: "12px",
                      }}
                    >
                      หมายเหตุ
                    </div>{" "}
                    <div
                      className="form-group"
                      style={{ margin: "0px", padding: "0px 28px" }}
                    >
                      <textarea
                        type="text"
                        className="form-control"
                        style={{
                          height: "150px",
                          fontSize: "25px",
                          padding: "5px 10px",
                        }}
                        value={noteModalData.note}
                        onChange={(ev) => {
                          setNoteModalData({
                            ...noteModalData,
                            note: ev.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-block btn-success"
                        style={{
                          width: "fit-content",
                          margin: "0px 10px 0px 0px",
                          padding: "10px 18px",
                        }}
                        onClick={() => {
                          if (noteModalData.is_new) {
                            SaveEvaMember();
                          } else {
                            updateEvaMember();
                          }
                        }}
                      >
                        ยืนยัน
                      </button>

                      <button
                        type="button"
                        className="btn btn-block btn-danger"
                        ref={closeModalRef}
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          width: "fit-content",
                          margin: "0px 0px 0px 10px",
                          padding: "10px 18px",
                        }}
                        onClick={() => {
                          setNoteModalData({
                            is_open: false,
                            week_status_id: "",
                            employee_id: "",
                            id: "",
                            note: "",
                            is_new: true,
                          });
                        }}
                      >
                        ยกเลิก
                      </button>
                    </div>{" "}
                  </div>
                </div>
                {/* <div className="modal-footer justify-content-center"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Assign_Work;

/*     <Modal
        centered
        isOpen={noteModalData.is_open}
        toggle={() => {
          setNoteModalData({
            is_open: false,
            week_status_id: "",
            employee_id: "",
            id: "",
            note: "",
            is_new: true,
          });
        }}
      >
        <ModalBody>
          <>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                marginBottom: "12px",
              }}
            >
              หมายเหตุ
            </div>

            <div
              className="form-group"
              style={{ margin: "0px", padding: "0px 28px" }}
            >
              <textarea
                type="text"
                className="form-control"
                style={{
                  height: "150px",
                  fontSize: "25px",
                  padding: "5px 10px",
                }}
                onChange={(ev) => {
                  setNoteModalData({
                    ...noteModalData,
                    note: ev.target.value,
                  });
                }}
              ></textarea>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <button
                type="button"
                className="btn btn-block btn-success"
                style={{
                  width: "fit-content",
                  margin: "0px 10px 0px 0px",
                  padding: "10px 18px",
                }}
                onClick={() => {
                  if (noteModalData.is_new) {
                    SaveEvaMember();
                  } else {
                    updateEvaMember();
                  }
                }}
              >
                ยืนยัน
              </button>

              <button
                type="button"
                className="btn btn-block btn-danger"
                style={{
                  width: "fit-content",
                  margin: "0px 0px 0px 10px",
                  padding: "10px 18px",
                }}
                onClick={() => {
                  setNoteModalData({
                    is_open: false,
                    week_status_id: "",
                    employee_id: "",
                    id: "",
                    note: "",
                    is_new: true,
                  });
                }}
              >
                ยกเลิก
              </button>
            </div>
          </>
        </ModalBody>
      </Modal> */
