import React, { useState, useEffect, useRef } from "react";
import { Button as ButtonAnt, Upload, Modal, Progress, Select } from "antd";
import { UploadOutlined, CameraOutlined, DeleteOutlined, ToolOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './CountingFeature.module.css';
import moment from "moment";
import Swal from "sweetalert2";
import Configs from "../../config";
import { Aifeature } from "../../routes/imgRoute/imgUrl";
import ImageZoom from './ImageZoom';

const { Option } = Select;

function CountingFeature() {
  const colors = {
    primary: "#004080",
    secondary: "#333333",
    lightBackground: "#f8f9fa",
    textLight: "#ffffff",
    headerText: "#000000",
    border: "#4A90E2",
  };

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const wsRef = useRef(null);

  const [empData, setEmpData] = useState({ detected_image: "", detected_objects: [] });
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [showJson, setShowJson] = useState(false);
  const [rawJsonData, setRawJsonData] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedClass, setSelectedClass] = useState("All");

  useEffect(() => {
    setupWebSocket();
    return () => wsRef.current && wsRef.current.close();
  }, []);

  useEffect(() => {
    if (empData.detected_image && empData.detected_objects.length > 0) drawImageAndBoundingBoxes();
  }, [empData, selectedClass]);

  const setupWebSocket = () => {
    wsRef.current = new WebSocket("ws://150.95.25.8:8000/ws/progress/");
    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgress(data.progress);
    };
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setShowProgress(true);
  
    try {
      const response = await axios.post("http://localhost:3010/upload/", formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      });
  
      if (response.data) {
        const data = {
          detected_image: response.data.counting_result.detected_image,
          detected_objects: response.data.counting_result.detected_objects,
        };
        
        setEmpData(data);
        setRawJsonData(response.data);
        setProgress(100);
  
        // บันทึกข้อมูลลงใน Local Storage
        localStorage.setItem("detectedImageData", JSON.stringify(data));
      }
    } catch {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Upload failed.' });
      setProgress(0);
    } finally {
      setShowProgress(false);
    }
  };
  
  const drawImageAndBoundingBoxes = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = empData.detected_image;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      // Filter objects based on selected class
      const objectsToDraw = selectedClass === "All"
        ? empData.detected_objects
        : empData.detected_objects.filter(obj => obj.label === selectedClass);

      objectsToDraw.forEach(({ bbox, label, color }, index) => {
        const { x, y, width, height } = bbox;

        ctx.strokeStyle = color;
        ctx.lineWidth = 15;
        ctx.strokeRect(x, y, width, height);

        ctx.fillStyle = color;
        ctx.font = "bold 30px Arial";
        const displayText = `${label} ${index + 1}`;
        ctx.fillText(displayText, x, y - 15);
      });

      const dataURL = canvas.toDataURL("image/png");
      console.log('dataURL', dataURL);
      setCapturedImage(dataURL);
    };
  };

  const handleClassSelection = (value) => {
    setSelectedClass(value);
  };
  
  const handleTakePhoto = () => {
    setIsCameraOpen(true);
    const constraints = { video: { facingMode: { ideal: isMobileDevice() ? "environment" : "user" } } };
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => videoRef.current.srcObject = stream)
      .catch((err) => console.error("Error accessing camera: ", err));
  };

  const captureImage = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const newFile = new File([blob], `captured-${Date.now()}.jpeg`, { type: "image/jpeg" });
      setFileList([...fileList, { uid: newFile.name, name: newFile.name, status: "done", url: URL.createObjectURL(blob) }]);
      handleFileUpload(newFile);
      setIsCameraOpen(false);
      stopCamera();
    }, "image/jpeg");
  };

  const stopCamera = () => {
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraOpen(false);
  };

  const handleClear = () => {
    setProgress(0);
    setEmpData({ detected_image: "", detected_objects: [] });
    setFileList([]);
    setShowProgress(false);
  };

  const countDetectedObjects = (objects) => {
    return objects.reduce((acc, obj) => {
      acc[obj.label] = (acc[obj.label] || 0) + 1;
      return acc;
    }, {});
  };
  
  // Define helper functions and state
const isMobileDevice = () => {
  return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1;
};

const [uploadKey, setUploadKey] = useState(Date.now());

const handleUploadSuccess = (file) => {
  setFileList(file.fileList);
};


  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-sm-6">
                <h1 className={styles.headerTitle}>Counting Feature</h1>
              </div>
              <div className="col">
                <ol className={`breadcrumb float-sm-right ${styles.breadcrumbStyle}`}>
                  <li className="breadcrumb-item"><a href="" style={{ textDecoration: "none", color: colors.primary }}>Home</a></li>
                  <li className="breadcrumb-item active">AI Feature</li>
                  <li className="breadcrumb-item active">Counting Feature</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="p-3">
        <div className={styles.card}>
        <div className={styles.cardHeader}>Counting Feature</div>
        <div className="card-body">
          <p className="text-muted">
            ฟีเจอร์นี้ใช้โมเดลการพัฒนาจาก AI ซึ่งสามารถตรวจจับข้อความจากใบเสร็จและใบแจ้งหนี้ได้โดยอัตโนมัติ โมเดล AI นี้รองรับรูปแบบและการจัดวางข้อความของใบแจ้งหนี้และใบเสร็จ เพื่อให้การดึงข้อมูลมีประสิทธิภาพและความแม่นยำสำหรับการประมวลผลและวิเคราะห์
          </p>
          <p className="text-muted">
            การใช้งานฟีเจอร์ OCR:
            <ol>
              <li>ไปที่หน้า OCR Feature เพื่ออัพโหลดหรือถ่ายภาพของเอกสารที่ต้องการทำ OCR</li>
              <li>เลือกไฟล์หรือถ่ายรูปที่ต้องการ จากนั้นกดปุ่ม "Edit" เพื่อไปยังหน้าแก้ไขข้อมูล</li>
              <li>ในหน้าแก้ไข ระบบจะดึงข้อมูลจากเอกสารมาแสดง คุณสามารถแก้ไขหรือลบข้อมูลที่ไม่ต้องการได้</li>
            </ol>
            ฟีเจอร์นี้ช่วยให้การจัดการข้อมูลในเอกสารเป็นไปอย่างรวดเร็วและแม่นยำ ลดเวลาในการกรอกข้อมูลด้วยมือและช่วยเพิ่มประสิทธิภาพในการทำงาน
          </p>
        </div>
      </div>


          {/* Upload Section */}
          <div className={styles.card}>
            <div className={styles.cardHeader}>Upload Files</div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px", flexWrap: "wrap" }}>
                    {/* Container to keep the buttons fixed */}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                      {/* เรียกใช้ฟังก์ชัน handleFileUpload ทันทีเมื่อเลือกไฟล์ */}
                      {/* Take Photo Button */}
                      <ButtonAnt
                        icon={<CameraOutlined />}
                        onClick={handleTakePhoto}
                        className={styles.cameraButton}
                        style={{
                          marginBottom: "11px",
                          backgroundColor: "#3579F626",
                          borderColor: "#1976D2",
                          color: "#3579F6",
                          minWidth: "200px",
                        }}
                        disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                      >
                        Take Photo
                      </ButtonAnt>

                      {/* Upload Files Button */}
                      <Upload
                        key={uploadKey} // ใช้ key ใหม่เพื่อบังคับให้รีเฟรช component
                        listType="picture"
                        accept=".jpg,.png,.jpeg,.pdf"
                        beforeUpload={(file) => {
                          handleFileUpload(file);
                          return false;
                        }}
                        fileList={fileList}
                        onChange={handleUploadSuccess}
                        showUploadList={{ showRemoveIcon: true }}
                        disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                      >
                        <ButtonAnt
                          icon={<UploadOutlined />}
                          style={{
                            backgroundColor: showProgress ? "#d3d3d3" : "#3579F626",
                            borderColor: "#1976D2",
                            color: showProgress ? "#808080" : "#3579F6",
                            minWidth: "200px",
                          }}
                          className={styles.uploadButton}
                          disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                        >
                          Choose files
                        </ButtonAnt>
                      </Upload>

                      

                      {/* Clear Button */}
                      <ButtonAnt
                        icon={<DeleteOutlined />}
                        style={{
                          backgroundColor: "#ff4d4f26",
                          borderColor: "#ff4d4f",
                          color: "#ff4d4f",
                          minWidth: "200px",
                          marginTop: "10px",
                        }}
                        className={styles.clearButton}
                        onClick={handleClear}
                        disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                      >
                        Clear
                      </ButtonAnt>

                      {/* Progress Bar Section */}
                      {showProgress && (
                        <div style={{ marginBottom: "0px", width: "100%" }}> {/* ใช้ width: 100% เพื่อให้ responsive */}
                          <h4>Processing Progress</h4>
                          <div style={{ padding: "0 15px", width: "100%" }}> {/* เพิ่ม container รอบๆ progress bar */}
                            <Progress percent={progress} status="active" style={{ width: "100%" }} /> {/* เปลี่ยน width เป็น 100% */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="p-3">
          <div className={styles.card}>
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10" style={{ border: "2px solid #4A90E2", borderRadius: "8px", padding: "15px" }}>
                  <div className="row">
                  {/* Left Column: Display Canvas */}
                  <div className="col-12 col-md-6 d-flex justify-content-center align-items-center"
                    style={{
                      height: '400px',
                      overflow: 'hidden',
                      position: 'relative',
                      borderRight: "2px dashed #4A90E2",
                      borderRadius: "8px 0 0 8px"
                    }}
                  >
                    {empData.detected_image ? (
                      <canvas 
                        ref={canvasRef} 
                        style={{
                          maxWidth: "100%", 
                          maxHeight: "350px", 
                          borderRadius: "8px", 
                          cursor: "pointer"
                        }}
                        onClick={() => setIsImageModalVisible(true)}
                      />
                    ) : (
                      <p style={{ color: "#666", fontSize: "18px" }}>No Image Detected</p>
                    )}
                  </div>

                    {/* Right Column: Detected Objects Summary */}
                    <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center" style={{ padding: "20px" }}>
                      <h4>Total Detected Objects: {selectedClass === "All" ? empData.detected_objects.length : countDetectedObjects(empData.detected_objects)[selectedClass] || 0}</h4>
                      
                      {/* Class Selection Dropdown */}
                      <Select
                        style={{ width: 200, marginBottom: 15 }}
                        placeholder="Select Class"
                        onChange={handleClassSelection}
                        value={selectedClass}
                      >
                        <Option value="All">All</Option>
                        {Array.from(new Set(empData.detected_objects.map(obj => obj.label))).map((label, index) => (
                          <Option key={index} value={label}>{label}</Option>
                        ))}
                      </Select>

                      <div style={{ textAlign: "center" }}>
                        {empData.detected_objects.length > 0 ? (
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {(selectedClass === "All"
                              ? Object.entries(countDetectedObjects(empData.detected_objects))
                              : [[selectedClass, countDetectedObjects(empData.detected_objects)[selectedClass] || 0]]
                            ).map(([objectType, count], index) => (
                              <li key={index}>{objectType}: {count}</li>
                            ))}
                          </ul>
                        ) : (
                          <p style={{ color: "#666" }}>No objects detected</p>
                        )}
                      </div>

                      {/* Edit Button */}
                      <ButtonAnt
                        icon={<ToolOutlined />}
                        style={{
                          backgroundColor: !empData.detected_image ? "#d3d3d3" : "#3579F626",
                          borderColor: !empData.detected_image ? "#a9a9a9" : "#1976D2",
                          color: !empData.detected_image ? "#808080" : "#3579F6",
                          padding: "4px 12px",
                          width: "140px",
                          fontSize: "12px",
                          borderRadius: "6px",
                          marginBottom: "11px"
                        }}
                        disabled={!empData.detected_image}
                        onClick={() => {
                          window.location.href = "/ai_feature/Counting_FineTuning";
                        }}
                      >
                        Edit
                      </ButtonAnt>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Enlarged Image */}
          <Modal
            title="Detected Image"
            visible={isImageModalVisible}
            onCancel={() => setIsImageModalVisible(false)}
            footer={null}
            centered
            bodyStyle={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0
            }}
            style={{
              maxWidth: '90vw',
              width: 'auto',
              maxHeight: '90vh'
            }}
          >
            <div style={{ border: '4px solid #4A90E2', borderRadius: '8px', padding: '10px' }}>
              <ImageZoom
                src={empData.detected_image ? empData.detected_image : Aifeature.imgs}
                zoom="300"
                alt="Zoomable image"
                height={550}
              />
            </div>
          </Modal>
        </section>

          {/* JSON Raw Data Card with Show/Hide Button */}
          <div className={styles.card}>
            <div className="card-body">
              <ButtonAnt type="primary" onClick={() => setShowJson(!showJson)}>
                {showJson ? "Hide JSON" : "Show JSON"}
              </ButtonAnt>
              {showJson && (
                <div style={{ marginTop: "15px" }}>
                  <h4>Raw JSON Output</h4>
                  <pre style={{ background: "#f8f9fa", padding: "15px", borderRadius: "5px", border: "1px solid #4A90E2", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {JSON.stringify(rawJsonData, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Modal for Camera Capture */}
        <Modal
          title="Camera"
          visible={isCameraOpen}
          onCancel={stopCamera}
          footer={[
            <ButtonAnt key="capture" type="primary" onClick={captureImage}>
              Capture Photo
            </ButtonAnt>,
            <ButtonAnt key="stop" onClick={stopCamera}>
              Stop Camera
            </ButtonAnt>,
            <ButtonAnt key="cancel" onClick={() => setIsCameraOpen(false)}>
              Cancel
            </ButtonAnt>,
          ]}
        >
          <video ref={videoRef} autoPlay style={{ width: "100%" }} />
        </Modal>

        {/* Modal for Enlarged Image */}
        <Modal
          title="Detected Image"
          visible={isImageModalVisible}
          onCancel={() => setIsImageModalVisible(false)}
          footer={null}
          centered
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0
          }}
          style={{
            maxWidth: '90vw',
            width: 'auto',
            maxHeight: '90vh'
          }}
        >
          <div style={{ border: '4px solid #4A90E2', borderRadius: '8px', padding: '10px' }}>
          <ImageZoom
              src={
                // empData.detected_image && empData.detected_image.startsWith("data:image")
                //   ? empData.detected_image
                //   : empData.detected_image
                //     ? empData.detected_image
                //     : Aifeature.imgs
                capturedImage
              }
              zoom="300"
              alt="Zoomable image"
              height={550}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default CountingFeature;
