import React, { useState, useEffect } from "react";
import './ImageZoom.css';

function ImageZoom({
  zoom = "200",
  alt = "This is an imageZoom image",
  width = "100%",
  height = "auto",
  src,
  id,
  className,
  onError = () => {}, // Add default onError to avoid memory leak warning
  errorContent = <p>There was a problem loading your image</p>,
  zoomMargin = 40
}) {
  const [zoomed, setZoomed] = useState("1");
  const [position, setPosition] = useState("50% 50%");
  const [imgData, setImgData] = useState(null);
  const [error, setError] = useState(false);

  const zoomInPosition = (e) => {
    const zoomer = e.currentTarget.getBoundingClientRect();
    let x, y;
    if (e.type === 'touchmove') {
      const touch = e.touches[0];
      x = ((touch.clientX - zoomer.x) / zoomer.width) * 100;
      y = ((touch.clientY - zoomer.y) / zoomer.height) * 100;
    } else {
      x = ((e.clientX - zoomer.x) / zoomer.width) * 100;
      y = ((e.clientY - zoomer.y) / zoomer.height) * 100;
    }
    setPosition(`${Math.max(0, Math.min(x, 100))}% ${Math.max(0, Math.min(y, 100))}%`);
  };

  const toggleZoomImage = (e) => {
    if (zoomed === "0") {
      setZoomed("1");
    } else {
      setZoomed("0");
      zoomInPosition(e);
    }
  };

  useEffect(() => {
    setImgData(null);
    if (!src) {
      setError(true);
      return;
    }

    let img = new Image();
    img.addEventListener("load", () => {
      setTimeout(() => {
        setZoomed("1");
        setImgData(img.src);
      }, 200);
    });
    img.addEventListener("error", () => {
      setError(true);
    });
    img.src = src;
  }, [src]);

  if (error) {
    return <>{errorContent}</>;
  }

  return (
    <figure
      id={id}
      className={[imgData ? "loaded" : "loading", zoomed === "0" ? "zoomed" : "fullView", className].join(" ")}
      style={{
        position: 'relative',
        display: 'inline-block',
        width: 'auto',
        minHeight: '25vh',
        backgroundPosition: `50% 50%`,
        backgroundColor: '#eee',
        margin: '0',
        overflow: 'hidden',
        cursor: 'zoom-in',
        backgroundImage: `url(${zoomed === '0' && imgData ? imgData : ''})`,
        backgroundSize: `${zoom}%`,
        backgroundPosition: position
      }}
      onClick={toggleZoomImage}
      onMouseMove={(e) => zoomed === "0" && zoomInPosition(e)}
      onTouchStart={toggleZoomImage}
      onTouchMove={(e) => zoomed === "0" && zoomInPosition(e)}
      onMouseLeave={() => {
        setZoomed("1");
        setPosition("50% 50%");
      }}
    >
      {imgData && (
        <img
          id="imageZoom"
          src={imgData}
          alt={alt}
          style={{
            opacity: zoomed,
            transition: "opacity 0.8s",
            display: "block",
            width: width,
            height: height
          }}
        />
      )}
    </figure>
  );
}

export default ImageZoom;
