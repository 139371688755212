import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons"; 
const ITEMS_PER_PAGE = 6;

function Counting_FineTuning() {
    const [detectedImage, setDetectedImage] = useState(null);
    const canvasRef = useRef(null);
    const [bboxes, setBboxes] = useState([]);
    const [currentBbox, setCurrentBbox] = useState(null);
    const [selectedBbox, setSelectedBbox] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [labelInput, setLabelInput] = useState("");
    const [isLabeling, setIsLabeling] = useState(false);
    const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
    const [availableClasses] = useState(["hexagonal_bar", "hollowround_bar", "round_bar", "square_bar", "steel_bar"]);
    const [newClassInput, setNewClassInput] = useState("");
    const [classToDelete, setClassToDelete] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isApproving, setIsApproving] = useState(false);
    const [approveDisabled, setApproveDisabled] = useState(false);
    const [objectCount, setObjectCount] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
        
    const classCounts = bboxes.reduce((acc, bbox) => {
        acc[bbox.label] = (acc[bbox.label] || 0) + 1;
        return acc;
    }, {});

    const totalPages = Math.ceil(bboxes.length / ITEMS_PER_PAGE);
    
    // Function to handle page changes
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    // Calculate the items for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const bboxesToDisplay = bboxes.slice(startIndex, endIndex);

    {/* Calculate the average confidence */}
    const averageConfidence = bboxes.length > 0
    ? (bboxes.reduce((sum, bbox) => sum + (bbox.confidence || 0), 0) / bboxes.length) * 100
    : 0;

    const pollProgress = () => {
        const updateInterval = 500; // ตรวจสอบทุกๆ ครึ่งวินาที
        const intervalId = setInterval(async () => {
            try {
                const response = await fetch('http://localhost:8001/progress/');
                const progressData = await response.json();
    
                setProgress(progressData.progress); // อัปเดตโปรเกรสใน UI
    
                if (progressData.progress >= 100) {
                    clearInterval(intervalId);
                    setShowProgress(false);
                    setApproveDisabled(false);
                    Swal.fire('การปรับแต่งเสร็จสมบูรณ์!', 'โมเดลได้รับการปรับแต่งแล้ว.', 'success');
                }
            } catch (error) {
                console.error("Error fetching progress:", error);
            }
        }, updateInterval);
    };

        useEffect(() => {
            const storedData = localStorage.getItem("detectedImageData");
            if (storedData) {
              const { detected_image, detected_objects } = JSON.parse(storedData);
              setDetectedImage(detected_image);
          
              // Map ข้อมูลกรอบให้ตรงกับรูปแบบที่ใช้ใน canvas
              const mappedBboxes = detected_objects.map(obj => ({
                x: obj.bbox.x,
                y: obj.bbox.y,
                width: obj.bbox.width,
                height: obj.bbox.height,
                label: obj.label,
                color: obj.color || getRandomColor(),
                confidence: obj.confidence || 0  
              }));
          
              setBboxes(mappedBboxes);
            }
          }, []);
          
    // Generate a random color for each bounding box
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };


const handleApprove = async () => {
    Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณต้องการยืนยันและบันทึกข้อมูลนี้หรือไม่?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const annotations = bboxes.map((bbox) => {
                    const centerX = (bbox.x + bbox.width / 2) / canvasRef.current.width;
                    const centerY = (bbox.y + bbox.height / 2) / canvasRef.current.height;
                    const width = bbox.width / canvasRef.current.width;
                    const height = bbox.height / canvasRef.current.height;

                    return `${availableClasses.indexOf(bbox.label)} ${centerX} ${centerY} ${width} ${height}`;
                }).join("\n");

                const blob = await fetch(detectedImage).then(res => res.blob());
                const imageFile = new File([blob], "image.jpg", { type: "image/jpeg" });

                const formData = new FormData();
                formData.append('image', imageFile);
                formData.append('annotations', annotations);
                formData.append('save_to_valid', 'true');

                const uploadResponse = await fetch('http://localhost:8001/upload_image_annotations/', {
                    method: 'POST',
                    body: formData,
                });

                if (uploadResponse.ok) {
                    Swal.fire({
                        title: 'ยืนยันเรียบร้อย!',
                        text: 'โมเดลกำลังถูกปรับแต่งในพื้นหลัง คุณต้องการติดตามความคืบหน้าหรือกลับไปหน้าหลัก?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ติดตาม',
                        cancelButtonText: 'กลับหน้าหลัก'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setShowProgress(true);
                            setApproveDisabled(true);
                            pollProgress();
                        } else {
                            window.location.href = '/ai_feature/ocr';
                        }
                    });

                    fetch('http://localhost:8001/finetune/', { method: 'POST' })
                        .then((response) => response.json())
                        .then((result) => {
                            if (!result.ok) {
                                console.error('Error:', result.error || 'เกิดข้อผิดพลาดในการฝึกโมเดล');
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } else {
                    Swal.fire('Error', 'เกิดข้อผิดพลาดในการบันทึก', 'error');
                }
            } catch (error) {
                console.error("Error:", error);
                Swal.fire('Error', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลหรือฝึกโมเดล', 'error');
            }
        }
    });
};

    // Function to get correct mouse position relative to the canvas
    const getMousePos = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;  // Horizontal scale factor
        const scaleY = canvas.height / rect.height;  // Vertical scale factor

        // Get mouse position relative to the canvas, accounting for scaling
        return {
            x: (e.clientX - rect.left) * scaleX,
            y: (e.clientY - rect.top) * scaleY
        };
    };

    // Function to check if the mouse is inside a bounding box
    const isMouseInsideBbox = (bbox, mousePos) => {
        return (
            mousePos.x > bbox.x &&
            mousePos.x < bbox.x + bbox.width &&
            mousePos.y > bbox.y &&
            mousePos.y < bbox.y + bbox.height
        );
    };

    const handleMouseDown = (e) => {
        if (isLabeling) return;  

        const mousePos = getMousePos(e);

        // Check if a bounding box was clicked
        const clickedBbox = bboxes.find(bbox => isMouseInsideBbox(bbox, mousePos));
        if (clickedBbox) {
            // Select the clicked bounding box for editing
            setSelectedBbox(clickedBbox);
            setLabelInput(clickedBbox.label);
            setLabelPosition({ x: clickedBbox.x + 10, y: clickedBbox.y + 10 });
            setIsLabeling(true);
            return;
        }

        // If no box is clicked, start drawing a new bounding box
        const { x, y } = mousePos;
        setIsDrawing(true);
        setCurrentBbox({ x, y, width: 0, height: 0, color: getRandomColor() });  // Start the box with random color
    };

    // Handle mouse move event to update the bounding box dimensions
    const handleMouseMove = (e) => {
        if (!isDrawing || selectedBbox) return;

        const { x, y } = getMousePos(e);
        setCurrentBbox((bbox) => ({
            ...bbox,
            width: x - bbox.x,
            height: y - bbox.y
        }));
    };

    // Handle mouse up event to stop drawing
    const handleMouseUp = () => {
        if (!isDrawing) return;

        if (currentBbox.width === 0 || currentBbox.height === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Bounding Box',
                text: 'Please draw a valid bounding box.',
            });
            setIsDrawing(false);
            return;
        }

        // Activate labeling mode after drawing the box
        setIsDrawing(false);
        setIsLabeling(true);

        // Adjust label position to appear next to the bounding box
        const padding = 10;  // Distance from the bounding box
        const newLabelPosition = {
            x: currentBbox.x + currentBbox.width + padding,  // Default: to the right of the box
            y: currentBbox.y  // Default: aligned with the top of the box
        };

        setLabelPosition(newLabelPosition);  // Set label position near the box
    };

    // Handle the label input submission
    const handleLabelSubmit = () => {
        if (!labelInput) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Label',
                text: 'Please provide a label for the bounding box.',
            });
            return;
        }

        if (selectedBbox) {
            // Update the label of the selected bounding box
            const updatedBboxes = bboxes.map((bbox) =>
                bbox === selectedBbox ? { ...bbox, label: labelInput } : bbox
            );
            setBboxes(updatedBboxes);
            setSelectedBbox(null);
        } else {
            // Add the bounding box to the list with the label
            setBboxes([...bboxes, { ...currentBbox, label: labelInput }]);
            setCurrentBbox(null);  // Clear the current box
        }
        
        setLabelInput("");  // Clear the label input
        setIsLabeling(false);  // End labeling mode
    };

    // Handle bounding box deletion
    const handleBoxDelete = () => {
        if (selectedBbox) {
            // If a box is selected, delete it and clear the label input
            const updatedBboxes = bboxes.filter(bbox => bbox !== selectedBbox);
            setBboxes(updatedBboxes);
            setSelectedBbox(null);
            setIsLabeling(false);  // Ensure the label input is closed
            setLabelInput("");  // Clear the input
        } else if (currentBbox) {
            // If the box is being drawn but hasn't been confirmed, remove it immediately
            setCurrentBbox(null);
            setIsDrawing(false);  // Ensure drawing stops
            setIsLabeling(false);  // Ensure the label mode stops
        }
    };

// Function to cancel labeling
const handleCancelLabeling = () => {
    setIsLabeling(false);  // Exit labeling mode
    setSelectedBbox(null);  // Deselect the bounding box
    setCurrentBbox(null);  // Clear the current box
    setLabelInput("");  // Clear label input
};

    // Drawing bounding boxes on the canvas
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas && detectedImage) {
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.src = detectedImage;
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear canvas
                ctx.drawImage(img, 0, 0);  // Draw the image

                // Draw existing bounding boxes
                bboxes.forEach((bbox) => {
                    ctx.strokeStyle = bbox.color;
                    ctx.lineWidth = 4;  // Make the bounding box thicker
                    ctx.strokeRect(bbox.x, bbox.y, bbox.width, bbox.height);
                    ctx.font = "40px Arial";
                    ctx.fillStyle = bbox.color;
                    ctx.fillText(bbox.label, bbox.x + 5, bbox.y );  // Display label inside box
                });

                // Draw the current bounding box while it's being drawn or when labeling
                if (currentBbox) {
                    ctx.strokeStyle = currentBbox.color;
                    ctx.lineWidth = 4;
                    ctx.strokeRect(currentBbox.x, currentBbox.y, currentBbox.width, currentBbox.height);
                }

                // Highlight selected bounding box for editing
                if (selectedBbox) {
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 4;
                    ctx.setLineDash([5, 3]);  // Dashed border for selected bounding box
                    ctx.strokeRect(selectedBbox.x, selectedBbox.y, selectedBbox.width, selectedBbox.height);
                    ctx.setLineDash([]);  // Reset dash
                }
            };
        }
    }, [detectedImage, bboxes, currentBbox, selectedBbox]);

    console.log("LabelPosition >> ", labelPosition);
    

    return (
        <div className="content-wrapper" style={{ padding: '20px' }}>
            <div className="row">
                <section className="col-12 col-md-8 mb-4" >
                    <div className="card" style={{ maxHeight: 'auto'}}>
                        <div className="card-header">Fine Tuning</div>
                        <div className="card-body" style={{ position: "relative" }}>
                            {detectedImage ? (  
                                <canvas
                                    ref={canvasRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    style={{
                                        border: "2px solid #4A90E2",
                                        cursor: isLabeling ? "default" : "crosshair",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "8px"
                                    }}
                                />
                            ) : (
                                <p>ไม่พบภาพสำหรับการปรับแต่ง</p>
                            )}
                            {isLabeling && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: `${labelPosition.x / 10}px`,
                                        top: `${labelPosition.y / 6}px`,
                                        background: "rgba(255, 255, 255, 0.9)",
                                        borderRadius: "4px",
                                        padding: "5px",
                                        zIndex: 10,
                                        boxShadow: "0px 2px 6px rgba(0,0,0,0.2)"
                                    }}
                                >
                                <div>
                                    <select
                                        value={labelInput}
                                        onChange={(e) => setLabelInput(e.target.value)}
                                        style={{ width: '200px', padding: '5px' }}
                                    >
                                        <option value="">Select Class</option>
                                        {availableClasses.map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Confirm, Delete, and Cancel buttons with distinct styles */}
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        style={{
                                            padding: '5px 10px',
                                            width: '100px',  // Same width for both buttons
                                            backgroundColor: '#1890ff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            marginRight: '10px' // Add spacing between buttons
                                        }}
                                        onClick={handleLabelSubmit}
                                    >
                                        Confirm
                                    </button>

                                    <button
                                        style={{
                                            padding: '5px 10px',
                                            width: '100px',
                                            backgroundColor: '#ff4d4f',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            marginRight: '10px'
                                        }}
                                        onClick={handleBoxDelete}
                                    >
                                        Delete
                                    </button>

                                    <button
                                        style={{
                                            padding: '5px 10px',
                                            width: '100px',
                                            backgroundColor: '#f0ad4e',  // Change the color for the Cancel button
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            textAlign: 'center'
                                        }}
                                        onClick={handleCancelLabeling}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <section className="col-12 col-md-4 mb-4">
            <div className="card">
                <div className="card-header">Details</div>
                <div className="card-body">
                {/* Display Overall Accuracy */}
                <div style={{ marginBottom: '15px', textAlign: 'center' }}>
                    <h5 style={{ fontWeight: 'bold', color: '#333' }}>Overall Accuracy</h5>
                    <span style={{ fontSize: '18px', color: '#4A90E2' }}>
                        {averageConfidence.toFixed(2)}%
                    </span>
                </div>

                {/* Object Counts Summary */}
                <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: '#333' }}>Object Counts</h5>
                    {Object.entries(classCounts).map(([label, count], index) => (
                        <div key={index} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '5px',
                            marginBottom: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <span>{label}</span>
                            <span>{count}</span>
                        </div>
                    ))}
                </div>

                    {/* List of labeled bounding boxes on current page */}
                    {bboxesToDisplay.map((bbox, index) => (
                        <div key={index} style={{
                            marginBottom: '15px',
                            padding: '15px',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            border: `2px solid ${bbox.color}`,
                            position: 'relative'
                        }}>
                            {/* Color indicator and label */}
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}>
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: bbox.color,
                                    borderRadius: '50%',
                                    marginRight: '10px'
                                }}></div>
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    color: '#333'
                                }}>
                                    {bbox.label}
                                </div>
                            </div>

                            {/* Confidence and Position Display */}
                            <div style={{
                                fontSize: '16px',
                                color: '#555'
                            }}>
                                <span style={{ fontWeight: 700 }}>Confidence:</span> 
                                {bbox.confidence ? (bbox.confidence * 100).toFixed(2) + "%" : "N/A"} {" "}
                                
                                <span style={{ fontWeight: 700 }}>Position:</span> 
                                x: {bbox.x.toFixed(2)}, 
                                y: {bbox.y.toFixed(2)}
                            </div>

                            {/* Delete icon button */}
                            <DeleteOutlined
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    fontSize: '20px',
                                    color: '#ff4d4f',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    const updatedBboxes = bboxes.filter((_, i) => i !== index);
                                    setBboxes(updatedBboxes);  // Remove the current bounding box from the list
                                }}
                            />
                        </div>
                    ))}

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-center mt-4">
                        <button 
                            className="btn btn-secondary mx-2" 
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <span style={{ fontWeight: 'bold', padding: '5px' }}>Page {currentPage} of {totalPages}</span>
                        <button 
                            className="btn btn-secondary mx-2" 
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>

                    {/* Approve Button and Progress Bar */}
                    <div className="mt-4 text-center">
                        {showProgress && (
                            <div className="mb-3">
                                <p className="mb-2">กำลังปรับแต่งโมเดล... {progress}%</p>
                                <progress value={progress} max="100" style={{ width: '100%', height: '20px', borderRadius: '10px' }}></progress>
                            </div>
                        )}
                        <button
                            className={`btn btn-block ${approveDisabled ? 'btn-secondary' : 'btn-success'}`}
                            onClick={handleApprove}
                            disabled={approveDisabled}
                        >
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </section>
            </div>
        </div>
    );
}

export default Counting_FineTuning;
